import React, { useState } from 'react';
import classNames from 'classnames/bind';
import styles from './MapVietNam.module.scss';

import { useNavigate } from 'react-router-dom';

const cx = classNames.bind(styles);

const provinces = [
  {
    id: 'VinhPhuc',
    name: 'Vĩnh Phúc',
    d: `M194,169.9c-0.5,0-0.8,0-1.1,0s-0.3-0.1-0.5-0.3c-0.1-0.1-0.2-0.2-0.3-0.3
c-0.2-0.2-0.9-1.2-1.1-1.6c-0.1-0.2-0.2-0.8-0.2-1c0-0.1,0-0.2,0-0.3c0.2-0.3,0.3-0.8,0.3-1.4c0-0.5-0.3-0.9-0.9-1.5
c-0.1-0.1-0.1-0.1-0.2-0.2c-0.3-0.4-0.8-0.6-1.4-0.8c0-0.2,0-0.6,0.1-0.7c0.4-1-0.1-2-0.7-2.6c-0.3-0.3-0.7-0.4-1.1-0.4
c-0.1,0-0.3,0-0.4,0c-0.1-0.3-0.2-0.6-0.3-0.9c0.2-0.1,0.3-0.3,0.4-0.4h0.1c0.2,0.1,0.5,0.3,0.9,0.3h0.1c0.1,0.1,0.1,0.3,0.2,0.3
c0.2,0.3,0.6,0.6,1,0.6l0,0c0.4,0,0.8-0.3,1-0.6c0,0,0-0.1,0.1-0.1c0.3-0.1,0.5-0.1,0.8-0.3c0.1-0.1,0.3-0.1,0.4-0.1c0,0,0,0,0.1,0
c0.6,0,1.3-0.4,1.7-0.6l0.4-0.2c0.3-0.1,0.5-0.4,0.6-0.7c0-0.1,0.1-0.1,0.1-0.2l0.1,0.1c0.1,0.4,0.5,0.8,1.2,0.9
c0.1,0.4,0.4,0.9,0.8,1.3c0.1,0.4,0.4,0.7,0.6,1c0.3,0.6,0.8,0.9,1.4,0.9l0,0c0.1,0,0.2,0,0.3,0c0.3,0.3,0.6,0.7,1.1,0.7h0.1
c0.1,0.9,0.9,1.2,1.1,1.2c0.1,0,0.3,0.1,0.4,0.1c0.1,0,0.1,0,0.1,0c-0.1,0.6,0.2,1.2,0.7,1.6l0,0c0,0.1-0.1,0.1-0.1,0.1
c-0.1,0.1-0.1,0.1-0.1,0.2c-0.2,0.2-0.6,0.9-0.7,1.1c-0.1,0.1-0.4,0.4-0.6,0.6c-0.5,0.5-0.8,1.2-0.7,1.8c-0.7-0.4-1.6-0.4-1.6-0.4
c-0.4,0-1.4,0.3-1.7,1.2c-0.3,0.1-0.5,0.3-0.7,0.5c-0.2,0.3-0.3,0.6-0.3,0.9h-0.1c-0.1,0-0.2,0-0.3,0c0,0-0.1,0-0.2,0
C194.7,169.9,194.3,169.9,194,169.9z`
  },
  {
    id: 'YenBai',
    name: 'Yên Bái',
    d: `M162.9,163c-0.1-0.1-0.2-0.2-0.3-0.3l-0.3-0.2c-0.1-0.4-0.3-0.8-0.6-1c0.1-0.3,0-0.7-0.3-1
c-0.2-0.3-0.5-0.4-0.9-0.4c-0.1,0-0.1,0-0.2,0l-0.6,0.1l-1.1,0.1c-0.4,0-0.8,0.3-1,0.7c-0.3-0.1-0.4-0.1-0.5-0.2
c-0.2-0.1-0.3-0.2-0.5-0.3c-0.3-0.1-0.3-0.2-0.5-0.4c-0.3-0.5-0.8-0.9-1.4-0.9c-0.5,0-0.9,0.3-1.2,0.4l0,0c-0.1,0-0.2,0-0.3,0
c-0.2,0-0.5,0-0.6-0.1c-0.1,0-0.2,0-0.3,0c-1.2,0-1.9,1.4-2.2,2c-0.1,0.1-0.3,0.4-0.5,0.6h-0.1c-0.2,0-0.4,0-0.7-0.1
c0.1-0.5-0.2-0.9-0.6-1.1c-0.3-0.2-1-0.5-1.6-0.5c-0.1,0-0.2-0.1-0.2-0.1c-0.4-0.5-1-0.8-1.6-0.8c-0.1,0-0.3,0-0.4,0.1
c-0.1-0.1-0.3-0.3-0.4-0.4c0-0.3-0.1-0.6-0.3-0.9l-0.4-0.4c0-0.3,0.1-0.5,0.1-0.6c0.5-0.7,0.3-1.5,0-2.2l0.2-0.1
c0.4-0.3,0.5-0.9,0.3-1.4l0,0c0.6-0.3,0.8-0.8,1-1.2c0-0.1,0.1-0.2,0.1-0.3s0.1-0.1,0.1-0.1c0.3-0.3,0.8-1,0.4-1.7
c-0.1-0.2-0.4-0.7-1.2-0.7h-1.5c-0.4,0-0.8-0.1-1-0.3c-0.5-0.3-1.4-0.4-2-0.4h-0.1c-0.3,0-0.7,0.2-0.9,0.4l-0.4,0.5c0,0,0,0-0.1,0.1
c-0.1,0-0.1,0-0.1,0h-0.1c-0.3-0.1-0.7-0.3-1.2-0.3c-0.1,0-0.5,0-2.2,0.5c-0.7-0.4-1.1-0.5-1.3-0.5c-0.3-0.4-0.9-0.6-1.6-0.6
c-0.1,0-0.4-0.1-0.5-0.2c-0.1-0.1-0.3-0.1-0.4-0.1c0,0-0.1,0-0.2,0c-0.5,0-0.9,0.1-1.2,0.3c0-0.3-0.2-0.6-0.4-0.8h0.1
c0.5-0.1,0.8-0.5,0.9-0.9v-0.1c0.2-0.2,0.5-0.6,0.6-1.1s-0.1-0.9-0.4-1.2c-0.1-0.1-0.2-0.3-0.3-0.5c-0.2-0.3-0.4-0.6-0.6-0.8
c-0.1-0.1-0.5-0.6-0.8-0.9l-1.1-1.2c0-0.3,0-0.5-0.1-0.7c0,0,0.1,0,0.1-0.1c0.3,0.2,0.6,0.3,0.7,0.3s0.2,0.1,0.3,0.1
c0.3,0,0.7-0.1,0.9-0.4l0.5-0.6l0.4,0.5c0.2,0.3,0.5,0.4,0.9,0.4c0.1,0,0.1,0,0.1,0l0,0c0,0,0.1,0,0.3,0c0.3,0,0.5,0.1,0.5,0.1
c0.5,0.2,1.6,0.6,1.6,0.6c0.1,0.1,0.3,0.1,0.4,0.1c0.4,0,0.7-0.2,0.9-0.5l0,0c0,0,0,0,0.1,0c0.3,0.2,0.7,0.5,0.9,0.6
c0.1,0.1,0.3,0.1,0.5,0.2c0.1,0,1.4,0.1,2.2,0.1c0.3,0,0.5,0,0.7-0.1c0.6-0.1,1.2-0.4,1.3-0.4c0.5-0.2,0.8-0.7,0.7-1.3
c0.2,0,0.4,0,0.6-0.1c0,0,0,0,0.1,0c0.2,0.1,0.4,0.3,0.5,0.3c0.1,0.1,0.3,0.1,0.5,0.2c0.2,0.4,0.4,0.7,0.5,0.8
c0.2,0.2,0.7,0.7,0.7,0.7c0.2,0.2,0.5,0.3,0.8,0.3c0.2,0,0.4-0.1,0.6-0.2l1.4-0.8c0.3-0.2,0.5-0.4,0.5-0.7c0-0.1,0-0.2,0.1-0.3
c0.3-0.1,0.7-0.3,1.2-0.8c0.3-0.2,0.4-0.5,0.4-0.9c0-0.1,0-0.9-0.1-1.3c-0.1-0.4-0.2-1.6-0.3-1.6c0-0.1,0-0.2-0.1-0.3l-0.5-1.2
c0,0,0-0.1-0.1-0.1l-0.7-1.2c-0.1-0.1-0.1-0.2-0.2-0.3l-0.1-0.1c0.9-0.6,0.9-1.5,0.9-2l-0.3-1.4v-0.1c0.1,0.1,0.2,0.1,0.3,0.1
c0.1,0.3,0.3,0.7,0.7,0.9c0.3,0.2,1,0.8,1.3,1.1c0.1,0.1,0.1,0.1,0.2,0.1c0.3,0.2,0.7,0.4,0.8,0.5c0.3,0.5,0.8,0.8,1.2,1
c0.2,0.1,0.4,0.3,0.5,0.3c0.2,0.1,0.4,0.2,0.7,0.2c0.1,0,0.1,0,0.2,0c0.3-0.1,0.6-0.2,0.7-0.5c0.6-0.9,0.7-1.3,0.7-1.4
c0.1-0.3,0.1-0.8,0.1-1.1l0.1-0.5h0.6c0.3,0,0.6-0.1,0.8-0.4c0.2-0.2,0.3-0.5,0.3-0.9l-0.1-1l0.2-0.9c0.1-0.4-0.1-0.8-0.4-1.1
l-0.1-0.1c0.4,0.1,0.7,0.2,1,0.3c0.3,0.1,0.9,0.2,1.4,0.3l0,0l-0.3,0.3c-0.2,0.2-0.3,0.5-0.3,0.8s0.2,0.6,0.4,0.8l2,1.7
c0.2,0.2,0.5,0.3,0.7,0.3h0.1c0.5,0,1.5-0.1,1.8-0.1c0.2,0,0.5,0,0.9-0.1l0.3,0.7c0.1,0.6,0.3,1.4,0.3,1.6c0,0.9,0.9,1.6,1.4,1.8
c0.2,0.1,0.9,0.5,1.5,0.6c0.1,0.4,0.3,0.7,0.7,0.9l1.1,0.5c0.1,0.1,0.3,0.1,0.5,0.1v0.1c0,1.4-0.1,1.8-0.1,1.9
c-0.1,0.6,0,1.3,0.4,1.7c0.2,0.2,0.4,0.4,0.6,0.5c0,0.8,0.5,1.5,1.4,1.8c0.1,0.1,0.1,0.2,0.1,0.3s0.1,0.2,0.2,0.3
c0.2,0.3,0.5,0.7,0.6,0.9c0.1,0.3,0.4,0.5,0.7,0.8l-0.2,0.3c-0.2,0.2-0.3,0.5-0.3,1c-0.1,0.1-0.1,0.1-0.2,0.1
c-0.3,0.2-0.5,0.5-0.5,0.9c0,0.3,0.1,0.7,0.4,0.9c0,0,0.2,0.1,0.3,0.3v0.1c-0.1-0.1-0.2-0.1-0.3-0.2c-0.2-0.1-0.4-0.2-0.6-0.2
c-0.2,0-0.5,0.1-0.7,0.2c-0.3,0.2-0.7,0.5-0.8,0.6c-0.6,0.3-1,0.6-1.2,0.9c-0.1,0-0.1,0-0.2,0c-0.1,0-0.3,0-0.4,0.1
c-0.6,0.2-0.9,0.4-1.2,0.5c-0.6,0-1.1,0.4-1.6,1c-0.4,0.5-0.5,1.4-0.5,1.7v0.1c0,0.4,0.1,0.9,0.1,1.2c-0.5,0.2-0.9,0.6-1,1.2
c-0.1,0.5,0.1,1,0.5,1.4c0,0.1,0,0.2,0,0.3c0,0.8,0.4,1.8,0.9,2.6l0.1,0.2c0,0.1,0,0.2,0,0.7v0.1c-0.1,0.1-0.2,0.4-0.3,0.5
c-0.4,0.4-0.6,0.8-0.6,1.2c-0.2,0-0.4,0.1-0.5,0.2h-0.1c-0.3,0.1-0.5,0.4-0.7,0.6c-0.1,0-0.1,0-0.1,0c-0.1,0-0.3,0-0.5,0
C163.3,162.9,162.9,163,162.9,163L162.9,163z`
  },
  {
    id: "VinhLong",
    name: "Vĩnh Long",
    d: `M210.4,643.7l-0.1-0.1l-1.2-0.9c-0.1-0.1-0.3-0.1-0.4-0.2l-1.2-0.3l-1.1-0.7
c-0.1-0.1-0.3-0.1-0.4-0.2l-0.2-0.2c0,0,0-0.1-0.1-0.1c-0.5-0.5-1.2-1.1-1.3-1.2c-0.4-0.5-2.2-1.6-2.8-1.9c-0.2-0.1-0.9-0.7-1.2-1
c-0.1-0.1-0.1-0.1-0.2-0.1l0.7-1.1l0.1,0.1c0.2,0.3,0.5,0.5,0.8,0.5h0.1c0.1,0,0.2,0,0.3,0l0.4,0.6c0.2,0.3,0.4,0.4,0.7,0.5
c0.4,0.1,1,0.2,1.4,0.2h0.7c0.2,0,0.5-0.1,0.6-0.2c0.1,0,0.1,0,0.2,0c0.3,0,0.6-0.1,0.9-0.4l0.3-0.3c0.2-0.2,0.3-0.5,0.3-0.9h0.4
c0.5,0,1-0.4,1.1-0.9l0.2-1c0.1-0.3,0-0.7-0.2-0.9l-0.4-0.5v-0.3c0-0.1,0-0.1,0-0.2c0.5-0.1,0.8-0.4,1.1-0.7
c0.1-0.1,0.4-0.4,0.7-0.6c0.3-0.3,0.5-0.4,0.6-0.5c0,0,0,0,0.1,0c0.2,0.2,0.5,0.3,0.7,0.3l0.2,1.8c0,0.4,0.3,0.7,0.7,0.9l0.8,0.4
c0.1,0.1,0.3,0.1,0.5,0.1c0.7,0,1.9,0,2.1,0.1l0,0l0.1,0.1c0.1,0.1,0.2,0.2,0.4,0.3l0.3,0.1l0.1,0.1l0.2,0.2
c0.2,0.2,0.4,0.5,0.6,0.7l0.1,0.2l0.2,1.2c0,0.1,0.1,0.3,0.2,0.4l0.4,0.6c0,0,0,0.1,0.1,0.1l0.4,0.5c0,0.1,0.1,0.1,0.1,0.1l0.6,0.6
c-0.1,0.2-0.2,0.3-0.2,0.3c-0.2,0.4-0.2,0.8-0.2,1.2c-0.1,0-0.1,0-0.2,0c-0.1,0-0.2,0-0.3,0.1l-0.9,0.3l-0.3-0.2
c-0.2-0.1-0.3-0.1-0.5-0.1s-0.3,0-0.5,0.1c-0.3,0.2-0.5,0.5-0.6,0.8l-0.3,1.7c0,0.1,0,0.2,0,0.3c-0.2-0.1-0.4-0.2-0.6-0.2
c-0.3,0-0.6,0.1-0.9,0.4l-0.1,0.1c-0.5,0.1-0.9,0.4-1.4,0.7H213c-0.1,0-0.2,0-0.2,0c-0.4,0-0.7,0.2-0.9,0.5L210.4,643.7z`
  },
  {
    id: "TuyenQuang",
    name: "Tuyên Quang",
    d: `M188.9,155.8c-0.2-0.2-0.6-0.3-1.1-0.3h-0.1c-0.1,0-0.1-0.1-0.1-0.1c-0.2-0.1-0.5-0.2-0.8-0.2
s-0.6,0.1-0.8,0.1c-0.1,0-0.1,0.1-0.2,0.1c-0.3,0-0.6,0.2-0.8,0.3c-0.1-0.1-0.2-0.1-0.3-0.1c-0.1-0.2-0.2-0.4-0.3-0.6
c-0.1-0.2-0.3-0.3-0.5-0.4c0-0.1,0-0.2-0.1-0.3c-0.1-0.2-0.3-0.8-1-1l0,0c0-0.1,0.1-0.2,0.1-0.3c0.1-0.4,0-0.8-0.2-1.2
c-0.1-0.1-0.1-0.2-0.3-0.3c0.1-0.4,0.1-1-0.5-1.7v-0.1c-0.3-0.3-0.7-0.5-1.1-0.5c-0.1,0-0.2,0-0.3,0c-0.2-0.1-0.5-0.4-0.6-0.5
c-0.3-0.4-0.8-0.6-1.3-0.7c-0.4-0.4-1-0.5-1.3-0.5c-0.1,0-0.3-0.2-0.4-0.2c-0.3-0.3-0.6-0.5-1.2-0.6l0,0c0.3-0.3,0.4-0.6,0.3-1
c0-0.1,0-0.1,0-0.2l0.6-0.9c0.2-0.3,0.2-0.6,0.2-0.9c-0.1-0.3-0.3-0.6-0.5-0.7c-0.3-0.2-0.7-0.5-0.9-0.7c-0.2-0.3-0.5-0.8-0.6-1
c-0.2-0.5-0.6-1.3-1.3-1.6c0-0.5-0.1-1.2-0.5-1.6c-0.1-0.2-0.3-0.3-0.6-0.4c0-0.4,0-0.9,0-1.6c0-0.4-0.1-1.8-0.1-1.9
c0-0.5-0.5-1-1-1.1l-0.8-0.1c-0.1,0-0.1,0-0.1,0c-0.2,0-0.4,0.1-0.5,0.1c-0.3-0.5-0.8-0.9-1.5-0.9c-0.2,0-0.6-0.2-0.9-0.3
c0,0,0,0-0.1,0s-0.1-0.1-0.2-0.1c-0.1-0.5-0.2-1.2-0.4-1.8v-0.1l-0.2-0.5c0.6,0,1.1-0.3,1.5-0.9c0.2-0.3,0.4-0.8,0.5-1.5
c0-0.1,0.1-0.3,0.1-0.3s0,0,0-0.1c0.1-0.2,0.5-0.9,0.1-1.7c0.2-0.3,0.4-0.5,0.5-0.9c0-0.1,0-0.2,0.1-0.3c0.2,0,0.4,0.1,0.7,0.1
c0.1,0,0.3,0,0.4,0.1c0,0.1,0.1,0.3,0.1,0.4c0,0.8,0.8,1.6,1.8,1.6c0.4,0,0.7-0.1,1.1-0.3c0.7-0.6,1.2-1.1,1.2-1.7
c0,0,0-0.1,0.1-0.1c0.1-0.3,0.4-0.9,0.3-1.5c0.3-0.1,0.5-0.3,0.7-0.5c0.3-0.3,0.4-0.8,0.3-1.3l-0.1-0.3c0.3-0.2,0.6-0.6,0.7-0.9
c0.1-0.3,0.1-0.7,0-1c0.5-0.3,0.9-1.1,0.5-2.4c0-0.1-0.1-0.3-0.1-0.4c0.5-0.7,0.7-1.2,0.7-1.2c0.1-0.3,0.1-0.6,0.1-0.8l-0.5-1.5
l0.4-0.8h0.1c0.3,0,0.6-0.1,0.8-0.3c0.3-0.3,0.4-0.7,0.3-1.1c-0.1-0.4-0.2-0.7-0.3-1c0.2,0.1,0.4,0.1,0.4,0.2
c0.5,0.3,1.1,0.5,1.8,0.6c0.1,0,0.2,0,0.3,0.1c0.2,0.1,0.4,0.1,0.6,0.2c0.1,0.3,0.1,0.5,0.1,0.5c0.1,0.4,0.4,0.8,0.8,0.9l1.1,0.3
c0.1,0,0.2,0,0.3,0c0.4,0,0.9-0.3,1.1-0.7c0.1-0.1,0.1-0.2,0.2-0.3h0.1c0.2,0,0.4-0.1,0.6-0.1c0.6,0.6,1.7,0.9,2,1
c0.2,0.1,1.2,0.3,1.8,0.3c0.8,0,1.7-0.5,2-0.7c0.1-0.1,0.3-0.2,0.4-0.4c0.1,0.1,0.1,0.2,0.2,0.3l0.4,0.4l0.1,0.4
c0,0.1,0.1,0.3,0.1,0.4c0.1,0.1,0.5,0.9,1.3,1.3c0.2,0.4,0.3,1.3,0.3,1.7h-0.1c-0.9,0.2-1.4,1.2-1.6,2.8c-0.9,0-1.6,0.8-1.6,2
l-0.1,0.8c-0.2,0.2-0.5,0.5-0.7,0.8c-0.4,0.5-0.5,1.2-0.4,1.8c-0.3,0.3-0.4,0.5-0.5,0.6s-0.3,0.1-0.5,0.2c-0.5,0.1-0.8,0.6-0.8,1.1
v0.9v0.1c0,0.3-0.1,0.9,0.3,1.6c0.2,0.3,0.5,0.7,0.8,1v0.3c0,0.3,0.1,0.7,0.4,0.9l-0.3,0.6c-0.2,0.3-0.2,0.7-0.1,1l0.2,0.5l-0.1,0.4
c-0.1,0.3-0.1,0.6,0.1,0.9l0.6,1.2l0.3,1.2c0,0.1,0.1,0.1,0.1,0.2c0,0,0.3,0.6,0.5,1c0.3,0.4,0.6,0.6,0.9,0.8l0.1,0.1
c0.2,0.2,0.4,0.4,0.6,0.6v1.3c-0.6,0.1-1,0.5-1.2,0.9s-0.2,0.9,0,1.3c-0.7,0.1-1.2,0.7-1.4,1.4c-0.1,0.6,0.1,1.1,0.6,1.5
c0,0.1,0,0.2,0,0.3s0,0.3,0,0.4s0,0.5,0.5,1.4l-1.1,1.3c-0.2,0.2-0.3,0.5-0.3,0.8c0,0.1,0,0.7,0.2,1.3c0.1,0.4,0.5,0.9,0.7,1.4
l-0.1,0.3c-0.1,0.5,0.1,1.1,0.6,1.3l0.3,0.2l0.3,0.5c0,0.1,0.1,0.1,0.1,0.2c-0.1,0.1-0.1,0.3-0.2,0.5l0,0c-0.1,0-0.1,0.1-0.1,0.1
c-0.2,0.1-0.4,0.2-0.5,0.3l0,0c-0.4,0-0.8,0.2-1.2,0.3c-0.1,0.1-0.3,0.1-0.4,0.1C189.1,155.7,189,155.7,188.9,155.8z`
  },
  {
    id: "TraVinh",
    name: "Trà Vinh",
    d: `M228.8,660.9l-0.2-0.1l-1.8-1.5c-0.1,0-0.1-0.1-0.1-0.1l-3.4-1.9l-7.4-7.1l-2.6-3.3
c0.3,0,0.6-0.1,0.8-0.4c0.2-0.2,0.4-0.4,0.5-0.5c0.2,0,0.3-0.1,0.5-0.2l0.3,0.3c0.2,0.2,0.5,0.3,0.7,0.3c0.1,0,0.1,0,0.1,0
c0.3,0,0.6-0.2,0.8-0.5l0.9-1.2c0.2-0.2,0.3-0.5,0.2-0.8l-0.1-0.5v-0.2c0.1,0,0.1,0,0.2-0.1l0.8-0.3l0.4,0.3
c0.2,0.1,0.4,0.2,0.7,0.2c0.1,0,0.2,0,0.3,0c0.8-0.2,1.6-0.9,1.5-2.1c0-0.3-0.1-0.5-0.1-0.7l1.1,0.8l0.2,0.2l0.1,0.2l0.3,0.6
c0,0.1,0.1,0.1,0.1,0.1l0.3,0.4c0,0.1,0.1,0.1,0.1,0.2l2.1,2c0.1,0.1,0.1,0.1,0.2,0.1h0.1l0.7,0.8c0.1,0.1,0.1,0.1,0.2,0.2l3.5,2.9
l0.9,1l0.6,1.1l0.3,1.5c0,0.2,0.1,0.4,0.3,0.5l0.4,0.5c0.2,0.2,0.4,0.3,0.7,0.4l0.7,0.1l0.1,0.2v0.7l-0.2,2l-0.4,1.2l-0.6,1
l-0.8,0.7l-0.6,0.4h-0.1c-0.1,0-0.1,0-0.1-0.1l-0.4-0.1c-0.1,0-0.2-0.1-0.3-0.1s-0.3,0-0.4,0.1l-0.4,0.2c-0.1,0-0.1,0.1-0.2,0.1
l-0.3,0.2L228.8,660.9z`
  },
  {
    id: "TienGiang",
    name: "Tiền Giang",
    d: `M243.3,629.9l-2.1-0.5c-0.1,0-0.2,0-0.3,0h-4.2l-2.8-0.6c-0.1,0-0.1,0-0.2,0h-1.4l-0.5-0.1
l-0.5-0.3c-0.1,0-0.1-0.1-0.2-0.1l-0.6-0.2c-0.1-0.1-0.3-0.1-0.4-0.1c-0.1,0-0.3,0-0.4,0.1l-0.6,0.2c-0.3-0.1-0.5-0.2-0.8-0.3
c-0.4-0.2-0.8-0.3-1.3-0.3c-0.9,0-1.9,0.3-2.5,0.6c-0.2,0.1-1,0.2-1.6,0.2c-0.1,0-0.2,0-0.2,0c-0.1,0-0.3,0-0.5,0
c-0.8,0-1.8,0.3-2.4,0.5c-0.5,0.2-1.6,0.4-2.1,0.5c0,0-0.1,0-0.2,0c-0.6,0-1.4-0.1-1.6-0.2c-0.5-0.3-2.1-0.7-2.4-0.8
c-0.2-0.1-0.5-0.1-0.7-0.1c-0.1,0-0.3,0-0.3,0c-0.2-0.2-0.6-0.4-1.2-0.4c-0.5,0-1.4,0.2-1.8,0.7c-0.1,0.1-0.3,0.3-0.5,0.5
c-0.3,0.3-0.6,0.6-0.8,0.7c-0.1,0.1-0.1,0.1-0.1,0.1H208h-0.1c-0.1,0-0.2,0-0.2,0h-0.1c-0.5-0.4-1.4-0.6-1.9-0.8l0,0l-0.1-0.7
c0.5,0,0.9-0.4,1.1-0.8l0.3-1.3l3.2-3.5c0.2-0.2,0.3-0.5,0.3-0.8v-0.5h0.2l4.9,0.4h0.1c0.3,0,0.5-0.1,0.8-0.3
c0.2-0.2,0.4-0.5,0.4-0.8v-1.1l5.8-0.5l0.5,0.5c0.2,0.2,0.5,0.4,0.8,0.4c0.1,0,0.2,0,0.3,0l0.2,0.2v0.2c0,0.4,0.3,0.8,0.6,1l0.1,0.1
l0.2,0.7c0.1,0.2,0.2,0.4,0.3,0.5l0.7,0.7c0.2,0.2,0.5,0.3,0.8,0.3h0.1c0.1,0,0.1,0,0.2,0c-0.1,0.1-0.1,0.2-0.2,0.3
c-0.1,0.3-0.1,0.6,0,0.9l0.4,0.9c0.2,0.4,0.6,0.7,1,0.7c0.1,0,0.1,0,0.2,0h0.1c0.2,0.3,0.6,0.5,1,0.5h0.7c0.3,0,0.5-0.1,0.8-0.3
l1.1-1c0.2,0,0.4,0.1,0.6,0.1l0,0c0.2,0.2,0.5,0.3,0.7,0.3l1.2,0.1h0.1c0.3,0,0.7-0.1,0.9-0.4c0.1-0.1,0.1-0.2,0.2-0.3
c0.1,0,0.1,0,0.2,0c0.1,0,0.1,0,0.2,0l0.7-0.1c0.1,0,0.3-0.1,0.4-0.1l0.5-0.3l0.1,0.2c0.2,0.3,0.6,0.5,0.9,0.5h0.8
c0.3,0,0.5-0.1,0.7-0.3l0.3-0.3c0.1-0.1,0.2-0.2,0.2-0.3l0.2-0.4l0.2,0.5c0,0.1,0.1,0.1,0.1,0.2l0.4,0.6l0.1,0.1l0.4,0.5l0.1,0.3
L243.3,629.9L243.3,629.9L243.3,629.9z`
  },
  {
    id: "ThuaThienHue",
    name: "Thừa Thiên Huế",
    d: `M280.2,388.2l-0.7-0.6c-0.1-0.1-0.2-0.2-0.4-0.2l-0.5-0.2l0.1-0.4c0-0.4-0.1-0.8-0.4-1
c-0.2-0.2-0.5-0.3-0.7-0.3c-0.1,0-0.2,0-0.3,0.1l-1.4,0.4l-1.4,0.3c-0.5,0.1-0.9,0.7-0.9,1.2c-0.1-0.1-0.1-0.1-0.3-0.3
c-0.3-0.4-0.8-0.6-1.3-0.6c0.1-0.2,0-0.5-0.1-0.7l-0.4-0.9c-0.2-0.4-0.6-0.7-1-0.7c-0.1,0-0.2,0-0.3,0l-1.6,0.4
c-0.3,0.1-0.6,0.3-0.8,0.6l-0.1,0.2h-0.3h-0.1c-0.1,0-0.1,0-0.2,0l-1.2,0.2l-0.3-0.3l-0.7-1.5c-0.2-0.4-0.5-0.7-0.9-0.7l0,0V383
c-0.1-0.3-0.3-0.6-0.7-0.7l-0.7-0.3c-0.1-0.1-0.3-0.1-0.4-0.1c-0.2,0-0.3,0-0.5,0.1s-0.6,0.2-0.9,0.3c-0.2-0.1-0.7-0.6-1.2-1
c-0.1-0.2-0.3-0.5-0.5-0.9c0-0.2,0.2-0.5,0.3-0.7c0.5-1,0.2-2.2-0.8-2.8c-0.5-0.3-2.2-1.3-2.6-1.5c-0.2-0.1-0.4-0.1-0.5-0.1
s-0.2,0-0.3,0l-0.3,0.1c0,0-0.1,0-0.2,0c-0.1,0-0.2,0-0.3,0V375v-0.1v-0.3l0.9-0.5c0.4-0.2,0.7-0.7,0.6-1.2l-0.2-1.1
c0.5,0.1,0.9,0.2,1.3,0.2c0.7,0,1.2-0.3,1.4-0.6c0.7-0.9,0.3-2.4-0.4-3.2c-0.3-0.3-0.7-0.5-1.2-0.8h0.3c0.4,0,0.8-0.2,1-0.5l0.2-0.3
l2.2-0.5c0.2,0.1,0.5,0.1,0.9,0.1c0.2,0,0.3,0,0.5-0.1c0.3,0.3,0.6,0.5,0.7,0.5c0.2,0.1,0.4,0.2,0.6,0.2s0.4-0.1,0.6-0.2l2.2-1.4
c0.5-0.3,0.7-1,0.4-1.5l-0.3-0.6l0.4-0.6l0.5-0.9l0.6-0.6c0.1-0.1,0.2-0.3,0.3-0.5c0.1-0.4,0.3-0.9,0.4-1.1c0.2-0.1,0.5-0.3,0.8-0.5
l2.9,2.1l-1,0.2c-0.5,0.1-0.9,0.5-0.9,0.9c-0.1,0.5,0.1,0.9,0.6,1.2l3.1,1.8c0.1,0.1,0.2,0.1,0.3,0.1l0.9,0.2l0,0l0.1,0.2
c0.1,0.3,0.4,0.4,0.7,0.5l0.4,0.1c0.1,0,0.2,0,0.3,0c0.1,0,0.1,0,0.1,0h0.1c-0.1,0.3-0.1,0.7,0.1,1s0.5,0.5,0.9,0.5h0.6
c0,0.2,0.2,0.5,0.3,0.6c0.1,0.1,0.1,0.1,0.2,0.2c0,0.2,0.1,0.5,0.2,0.7c0.2,0.3,0.6,0.5,0.9,0.5h0.4c0.3,0,0.5-0.1,0.7-0.3l0,0
l0.1,0.1l1.4,1l0.2,0.2l0.9,1.4c0.1,0.1,0.2,0.2,0.3,0.3l0.3,0.2c-0.1,0.1-0.1,0.2-0.1,0.3l-0.1,0.3c-0.1,0.2-0.1,0.3,0,0.5l0.5,2.4
c0,0.1,0.1,0.2,0.1,0.3l0.2,0.4c0.1,0.2,0.2,0.3,0.4,0.4l0.5,0.3c0,0,0.1,0,0.1,0.1l1,0.6c0.2,0.1,0.4,0.2,0.6,0.2h0.3h0.1
c0.1,0.2,0.3,0.4,0.6,0.5c0.1,0,0.3,0.1,0.4,0.1s0.2,0,0.3,0c0.1,0,0.1,0,0.1,0c0.1,0,0.1,0,0.1,0c0.3,0,0.5-0.1,0.7-0.3l1.5-1.2
c0.1-0.1,0.1-0.1,0.2-0.2l0.2-0.3c0.1-0.2,0.2-0.3,0.2-0.5l0.3,0.2c0.1,0.1,0.3,0.1,0.4,0.2l0.9,0.2c0.1,0,0.2,0,0.3,0
c0.2,0,0.4-0.1,0.6-0.2v0.1l0.2,0.5c-0.1,0.1-0.3,0.3-0.3,0.5l-0.2,0.6c-0.1,0.3-0.1,0.7,0.1,1l0.3,0.5c0.1,0.1,0.2,0.2,0.3,0.3
c-0.1,0-0.1,0-0.2,0c-0.2-0.1-1-0.5-1.6-1c-0.2-0.2-0.5-0.3-0.7-0.3c-0.1,0-0.1,0-0.2,0c-0.3,0.1-0.6,0.2-0.8,0.5
c-0.1,0.1-0.3,0.3-0.5,0.5c-0.3-0.1-0.7-0.4-1.1-0.7c-0.2-0.2-0.5-0.3-0.7-0.3s-0.4,0.1-0.5,0.1l-1.4,0.7c-0.4,0.2-0.7,0.7-0.6,1.1
c0,0,0.1,0.5,0.1,1c-0.3,0.1-0.6,0.5-0.7,0.8l-0.2,1c-0.3,0.2-0.7,0.6-0.9,0.9c-0.1,0.2-0.3,0.4-0.3,0.6c-0.1-0.1-0.2-0.1-0.3-0.1
c-0.2-0.1-0.4-0.2-0.6-0.2c-0.1,0-0.3,0-0.4,0.1c-0.3,0.1-0.6,0.4-0.7,0.8l-0.1,0.5c-0.1,0.1-0.2,0.1-0.3,0.1
c-0.3,0-1.4,0.2-1.6,1.6C280.6,388.1,280.5,388.1,280.2,388.2C280.3,388.2,280.3,388.2,280.2,388.2z`
  },
  {
    id: "ThanhHoa",
    name: "Thanh Hóa",
    d: `M202.7,248.6c-0.1-0.3-0.4-0.5-0.8-0.6c-0.1,0-0.2,0-0.3,0c-0.3,0-0.5,0.1-0.7,0.2
c-0.3,0.2-0.7,0.5-0.8,0.5s-0.1,0-0.1,0c-0.1,0-0.2,0-0.3,0l-0.1-0.1v-0.4c0-0.3-0.2-0.6-0.4-0.8c-0.5-0.4-1.1-0.9-1.2-1.2
c-0.3-0.5-0.8-0.7-1.3-0.7c-0.3,0-0.5,0.1-0.7,0.2c-0.1-0.2-0.2-0.3-0.3-0.5c-0.1-0.1-0.4-0.7-0.6-1c-0.4-0.7-0.7-1.1-0.8-1.4
c-0.3-0.4-0.9-0.7-1.6-0.7c-0.3,0-0.9,0-1.4,0.4h-0.1h-0.3c-0.3,0-0.6,0-0.9,0.1c-0.2-0.1-0.6-0.3-1.1-0.4c-0.1,0-0.2,0-0.3,0
s-0.2,0-0.3,0c-0.2-0.2-0.3-0.4-0.4-0.5c0.1-0.7-0.1-1.5-0.6-2.1s-1.1-0.7-1.4-0.7c-0.4,0-0.9,0.2-1.2,0.5c-0.2,0.2-0.3,0.5-0.5,0.7
c-0.1,0.1-0.1,0.3-0.2,0.3l-0.1,0.1c0-0.2,0-0.4,0-0.5c0.3-0.2,0.5-0.5,0.5-0.9v-1.6c0-0.3-0.1-0.5-0.2-0.7
c-0.1-0.2-0.3-0.4-0.3-0.5c-0.1-0.4-0.5-1.7-1.6-2.1c-0.2-0.1-0.4-0.1-0.6-0.1c-0.2,0-0.4,0-0.6,0.1c-0.1-0.1-0.3-0.2-0.4-0.3
c-0.3-0.3-0.7-0.5-1.1-0.6l-0.1-1c0-0.3-0.2-0.6-0.5-0.8c0,0-0.2-0.2-0.5-0.4c0,0,0,0,0-0.1c0-0.4,0.1-1-0.3-1.6c0-0.1,0-0.3,0-0.5
c0.4-0.1,0.7-0.5,0.7-0.9l0,0h0.1c0.8-0.1,1.2-0.5,1.3-0.9c0.1-0.3,0.2-0.8-0.2-1.4c0-0.1-0.1-0.5-0.1-0.6c0-0.2,0-0.4-0.1-0.6
c-0.2-1-1.3-1.5-2-1.5h-0.2c-0.1-0.2-0.4-0.4-0.7-0.5c-0.1,0-0.7-0.2-1.4-0.2c-0.3,0-0.7,0.1-1,0.1c-0.2-0.2-0.4-0.3-0.7-0.4
l-1.3-0.2c-0.3-0.2-0.6-0.5-0.9-0.7c-0.5-0.4-0.7-0.5-0.7-0.6c-0.1-0.1-0.1-0.1-0.2-0.1c-0.1,0-0.4-0.1-0.9-0.2
c0.3-0.2,0.7-0.5,1.1-0.8c0.2-0.1,0.3-0.3,0.4-0.4l0.9-1.8c0.2-0.3,0.2-0.7,0-1c-0.1-0.3-0.4-0.5-0.7-0.7c-0.2-0.1-0.7-0.2-1.1-0.4
c-0.3-0.2-0.6-0.5-0.7-0.7c-0.1-0.3-0.2-0.7-0.2-0.7c-0.2-0.5-0.6-0.8-1.1-0.8c-0.1,0-0.2,0-0.4,0.1c0,0-0.8,0.3-1.3,0.4
c-0.1,0-0.5-0.1-0.7-0.2c-0.1,0-0.2-0.1-0.3-0.1c-0.2-0.1-0.7-0.4-0.9-0.6c-0.3-0.3-0.6-0.5-0.9-0.6c-0.5-0.3-1.6-0.7-2.3-0.8
c-0.5-0.1-1-0.1-1.3-0.1c-0.2-0.1-0.6-0.2-1-0.3c-0.1-0.1-0.1-0.1-0.2-0.1c-0.3-0.3-0.8-0.8-1.5-0.8c-0.1,0-0.3,0-0.4,0.1
c-0.1,0-0.2-0.1-0.4-0.1c0.1-0.7,0.3-2.1,0.3-2.3s0-0.4-0.1-0.6l0,0c0,0-0.2-0.6,0-0.7c0.7-0.5,1.3-0.9,1.6-1.1l1-0.1
c0.4-0.1,0.8-0.3,0.9-0.7s0.1-0.9-0.2-1.2c-0.4-0.5-1.2-1.3-1.4-1.7c-0.1-0.1-0.1-0.2-0.2-0.3c-0.3-0.6-0.8-1.4-1.9-1.4
c-0.1,0-0.2,0-0.3,0c-0.9,0.1-1.7,0.8-1.8,0.9c-0.4,0.3-0.5,0.8-0.3,1.3l0,0c-0.1,0-0.3,0-0.5,0s-0.3,0-0.5,0s-0.3,0-0.5,0
c-0.3,0-1.1,0-1.6,0.7c-0.1,0.1-0.5,0.3-0.7,0.4c-0.4,0.2-0.8,0.5-1.2,0.8c0,0-0.1,0.1-0.2,0.1c-0.3-0.3-0.7-0.5-1.3-0.5l0,0
c0-0.2,0.1-0.3,0.1-0.3c0.1-0.4-0.1-0.9-0.4-1.2l-0.7-0.5c0.8-0.2,1.3-0.7,1.6-1.1c0.5,0,1.1-0.2,1.5-0.5s0.6-0.5,0.8-0.7l0.1-0.1
c1-0.1,2.1-1,2.1-2c0-0.6,0.1-0.9,0.1-1c0.6,0.1,1.2,0.1,1.6,0.1c0.3,0,0.5,0,0.6-0.1c0.3-0.1,0.6-0.3,0.8-0.6l0.1-0.3
c0.1,0.1,0.1,0.1,0.1,0.1c0.2,0.2,0.5,0.6,0.6,0.8c0.2,0.4,0.6,0.6,1,0.6c0.1,0,0.1,0,0.1,0l0.5-0.1c0.3,0.6,0.9,1.1,1.6,1.1
c0.7,0,2.3-0.3,2.6-0.4c0.5-0.1,0.9-0.6,0.9-1.1v-0.8l1.7-1.4c0.3-0.1,0.9-0.2,1.1-0.3c0,0,0.1,0,0.2,0l0,0c0.3,0.3,0.8,0.7,1.2,1
v1.6c0,0.6,0.5,1.2,1.2,1.2c0.3,0,0.6,0,0.8,0.1c0.1,0,0.2,0.1,0.3,0.1h0.1c0.4,0.4,1,0.7,1.4,0.8c0.1,0,0.2,0.1,0.3,0.1h0.6
c0.4,0,0.7-0.2,0.9-0.5c0-0.1,0.1-0.1,0.2-0.1c0.3,0.1,0.6,0.1,0.9,0.1c0.1,0,0.1,0,0.2,0c0.3,0.3,0.8,0.7,0.8,0.7
c0.1,0.1,0.3,0.2,0.5,0.2h0.1c0.3,0.7,1,1.2,1.9,1.2c0.5,0,0.8,0.1,0.9,0.2c0.3,0.4,0.5,0.8,0.5,0.8c0.1,0.2,0.2,0.3,0.4,0.4
l0.3,0.3l-0.1,0.3c-0.1,0.5,0.2,1,0.6,1.2l1.7,0.9c0.8,0.4,1.8,0.9,2,1c0.5,0.3,1.6,0.7,2.3,0.9c0.1,0,0.2,0.1,0.3,0.1
c0.3,0,0.5-0.1,0.7-0.3l0.3-0.3c1,0.3,2,0.5,2.5,0.5c0.3,0,0.9,0.1,1.1,0.1h0.1c0.1,0.3,0.1,0.5,0.1,0.7c0.2,0.9,1,1.6,2.1,1.6
c0.1,0,0.3,0,0.4,0.1c0.2,0.7,0.7,1.1,1.6,1.3c0.3,0.1,0.3,0.1,0.4,0.2c0,0.1,0.1,0.1,0.1,0.1c0.1,0.1,0.3,0.3,0.5,0.5
c0.9,0.9,1.8,1.9,2.2,2.3c0.7,0.7,2.4,1.4,2.7,1.5l0,0c0,0,1.4,0.7,2,1c0.7,0.4,2.4,1.2,2.4,1.3c0,0,0.7,0.3,1.1,0.7
c0.6,0.5,1.2,0.9,1.2,0.9c0.2,0.2,0.5,0.3,0.7,0.3h0.1c0,0,0.3,0,0.5,0s0.3,0,0.3,0c0.6,0.3,1.5,0.5,2.1,0.5
c-0.1,0.1-0.1,0.3-0.2,0.4c-0.2,0.1-0.4,0.3-0.5,0.6l-0.2,0.4l-0.3,0.3l-1.7,1.6l-0.1,0.1l-0.3,0.4c-0.1,0.1-0.1,0.2-0.2,0.3
l-0.3,0.7c-0.1,0.1-0.1,0.3-0.1,0.4v0.4c0,0.1,0,0.3,0.1,0.5l0.1,0.3l-0.5,0.7c-0.1,0.1-0.2,0.3-0.2,0.4l-0.1,0.6c0,0.1,0,0.2,0,0.3
l0,0c-0.2,0.1-0.4,0.3-0.5,0.5l-0.1,0.2l-0.3,0.2l-1.1,0.6c-0.3,0.2-0.5,0.5-0.6,0.8s0,0.7,0.2,0.9l0.3,0.3l0.1,0.1
c-0.2,0.1-0.4,0.3-0.5,0.5l-0.3,0.6c0,0.1,0,0.1-0.1,0.1l-0.1,0.5l-0.8,1.3c0,0.1-0.1,0.1-0.1,0.2l-0.3,0.7c0,0.1-0.1,0.2-0.1,0.3
v0.3c-0.2,0-0.3,0.1-0.5,0.2l-0.5,0.4c-0.3,0.2-0.5,0.6-0.4,0.9v0.7c0,0.5,0.4,0.9,0.9,1.1c0.1,0,0.2,0,0.2,0l0,0v0.9l-0.4,3.1
c0,0.1,0,0.2,0,0.3v0.2c-0.1,0.1-0.3,0.3-0.3,0.4l-0.1,0.2c-0.2,0-0.4,0.1-0.6,0.2c-0.3,0.2-0.5,0.6-0.5,1v0.3
c0,0.3,0.2,0.7,0.5,0.9l0.2,0.2v0.1l0.1,1.1c0,0.1,0.1,0.3,0.1,0.4l0.1,0.2c-0.1,0.1-0.1,0.2-0.2,0.3L202.7,248.6z`
  },
  {
    id: "ThaiNguyen",
    name: "Thái Nguyên",
    d: `M207.2,162.1c-0.2-0.4-0.5-0.6-0.7-0.7c-0.2-0.1-0.4-0.2-0.7-0.2c-0.1,0-0.1,0-0.2,0l-0.7,0.1
c-0.2-0.1-0.5-0.2-0.6-0.2c-0.2-0.1-0.3-0.1-0.5-0.1s-0.4,0-0.5,0.1c-0.1-0.3-0.3-0.6-0.6-0.9c-0.3-0.3-0.6-0.4-1-0.4h-0.1
c-0.1-0.1-0.1-0.3-0.3-0.4c-0.3-0.3-0.9-0.5-1.4-0.7c-0.1-0.3-0.5-0.9-1.5-0.9c-0.1,0-0.1,0-0.1,0l-0.1-0.1l-0.1-0.1
c-0.1-0.6-0.4-0.9-0.8-1.2c-0.1-0.1-0.1-0.3-0.1-0.3c-0.1-0.7-0.9-1.2-1.5-1.4c0-0.1-0.1-0.1-0.1-0.1c-0.1-0.1-0.2-0.2-0.2-0.3
c-0.1-0.3-0.3-0.9-1.1-1.1c-0.2-0.3-0.4-0.5-0.8-0.5l-0.2-0.3c-0.1-0.1-0.2-0.3-0.3-0.4c0.1-0.3,0-0.6-0.1-0.8
c-0.3-0.5-0.7-1.2-0.7-1.4c0-0.1,0-0.1-0.1-0.2l1.3-1.5c0.3-0.4,0.4-0.9,0.1-1.4c-0.2-0.4-0.5-0.9-0.5-1.1v-0.1c0-0.2,0.1-0.7,0-1.2
c0.1,0,0.1,0,0.2,0c0.5-0.1,0.8-0.5,0.8-1s0-1-0.1-1.3c0.6-0.3,1-1,1.1-1.4c0-0.1,0.1-0.2,0.1-0.4V139c0.8-0.3,1.3-1.2,1.3-1.9
c0-0.1,0-0.3,0.1-0.5c0.1-0.1,0.2-0.1,0.3-0.1c0.1-0.1,0.3-0.1,0.3-0.1c0.5,0,1.4-0.2,1.8-0.3c0.1,0,0.3-0.1,0.4-0.2
c0.6,0.5,1.3,0.7,1.9,0.9c0.1,0.6,0.3,1.4,0.6,1.9c0,0.1,0,0.1-0.1,0.2c-0.1,0.1-0.1,0.2-0.1,0.3c-0.1,0.3-0.2,1.2,0.1,1.9
c-0.1,0.1-0.3,0.4-0.5,1.8c-0.1,0.5,0,1.6,0.9,2.2c0.4,0.3,0.9,0.4,1.4,0.4c1,0,1.8-0.7,1.8-1.6c0.1,0,0.1,0,0.2,0
c0.4,0,0.7-0.1,0.9-0.2c0.1,0,0.1-0.1,0.2-0.1c0.6-0.2,1.4-0.5,1.9-1.2l0.1-0.1c0.2-0.3,0.4-0.6,0.5-0.7c1.1,0,2.2-0.8,2.4-1.6v-0.1
c0.2,0,0.4,0.1,0.6,0.1c0.7,0,1.1-0.3,1.4-0.5c0.1-0.1,0.1-0.1,0.2-0.2c0.2,0.2,0.5,0.3,0.8,0.3h0.1c0,0,0.1,0,0.3,0s0.3,0,0.4,0
c0.1,0.1,0.3,0.2,0.4,0.3c0.1,0.1,0.1,0.1,0.2,0.1c-0.3,0.5-0.3,1-0.3,1.3v1l-0.2,0.3c-0.3,0.2-0.6,0.7-0.7,1.2c0,0.4,0.1,0.7,0.3,1
c0.1,0.1,0.2,0.5,0.3,0.8c0.1,0.5,0.6,0.9,1.1,0.9c0.1,0,0.1,0,0.2,0l0.4-0.1c0.2,0.3,0.4,0.5,0.7,0.8c0.4,0.3,0.9,0.5,1.3,0.7
c0.1,0.1,0.3,0.1,0.4,0.2c0.2,0.2,0.5,0.4,0.8,0.5c-0.1,0.2-0.1,0.4-0.1,0.5c-0.2,0.2-0.4,0.4-0.5,0.5c-0.4,0.3-0.7,0.7-0.9,1.2
c-0.3,0-0.6,0.1-0.7,0.1h-0.1c-0.1,0-0.3,0.1-0.6,0.1c-0.2,0-0.3,0-0.4-0.1c-0.3-0.2-0.7-0.3-1-0.3c-0.9,0-1.4,0.8-1.5,1.1
c0,0,0,0,0,0.1c-0.5,0.3-0.7,0.7-0.9,0.9l0,0c-0.6,0.4-0.9,1.1-1,1.3c-0.2,0.4-0.1,0.9,0.3,1.2l0.2,0.2l-0.2,0.6
c0,0.1-0.1,0.2-0.1,0.4v1.4c0,0.5,0.2,0.9,0.4,1.3c0,0.1-0.1,0.3-0.1,0.3c-0.1,0.1-0.2,0.3-0.3,0.5c-0.2-0.1-0.3-0.2-0.4-0.2
c-0.1-0.1-0.5-0.4-0.7-0.7s-0.5-0.4-0.9-0.4c-0.1,0-0.2,0-0.3,0c-0.4,0.1-0.8,0.5-0.8,0.9c-0.1,0.3-0.1,0.7-0.2,0.8
c-0.2,0.2-0.3,0.5-0.4,0.9h-0.1h-0.1C207.8,161.4,207.4,161.6,207.2,162.1z`
  },
  {
    id: "ThaiBinh",
    name: "Thái Bình",
    d: `M231,206.9c-0.4-0.3-1.2-0.9-1.4-1.2c-0.5-0.6-1.4-1-1.8-1.2h-0.1c0,0-0.1-0.1-0.2-0.2
c-0.1-0.1-0.3-0.3-0.6-0.5s-0.7-0.3-1-0.3s-0.6,0.1-0.9,0.2c0-0.1,0-0.1,0-0.1c-0.2-1-1-1.4-1.3-1.5h-0.1c-0.3-0.2-0.5-0.2-0.9-0.2
c-0.1,0-0.1,0-0.2,0c0-0.1,0.1-0.2,0.1-0.3c0.2-0.6,0.1-1.2-0.3-1.8c-0.4-0.5-0.9-0.9-1.5-1c0-0.1,0-0.2,0-0.3
c0-0.7-0.4-1.7-0.7-2.1c-0.4-0.5-0.9-0.7-1.3-0.7v-0.1c0-0.3-0.1-0.5-0.2-0.8c0.1,0,0.1,0,0.2,0c0.3,0,0.5-0.1,0.7-0.2
c0.3,0.2,0.7,0.3,1.2,0.3c1.1,0,1.4-0.7,1.5-0.9c0-0.1,0-0.1,0.1-0.3c0.1-0.1,0.3-0.2,0.4-0.3c0.1-0.1,0.3-0.2,0.4-0.3
c0.4-0.2,0.6-0.4,0.8-0.7c0.2,0.1,0.4,0.1,0.6,0.1c0.4,0,0.8-0.2,1.2-0.4c0.1-0.1,0.1-0.1,0.2-0.1c0.2,0.1,0.4,0.1,0.6,0.1
c0.3,0,0.6-0.1,0.7-0.2c0,0,0,0,0.1,0h0.1c0.1,0.3,0.3,0.5,0.5,0.7c0.1,0.1,0.5,0.3,0.5,0.5c0.2,0.3,0.6,0.7,1,1
c-0.1,0.3,0,0.7,0.1,1s0.4,0.5,0.6,0.6c0.1,0.2,0.1,0.4,0.3,0.6c0.2,0.2,0.6,0.4,0.9,0.4h0.1c0.4,0,0.8-0.2,1.1-0.5
c0.3,0.2,0.5,0.2,0.8,0.2c0.1,0,0.1,0,0.2,0c0.9-0.1,1.4-0.4,1.6-0.8h0.1c0.2,0,0.4,0,0.6-0.1l-0.4,0.4l-2.4,0.9
c-0.4,0.2-0.7,0.5-0.7,1s0.2,0.9,0.6,1.1l0.7,0.4c0.2,0.1,0.3,0.1,0.5,0.1c0,0,0,0,0.1,0h0.4l0.1,0.3l-0.5,0.2
c-0.2,0.1-0.3,0.1-0.4,0.3l-0.1,0.1l-0.1,0.1c-0.1,0-0.1,0.1-0.1,0.1l-0.3,0.3c-0.1,0.1-0.1,0.1-0.1,0.2l-0.3,0.4l-0.1,0.1l-0.2,0.3
c-0.2,0.3-0.2,0.8,0.1,1.1c0.2,0.3,0.6,0.5,1,0.5h0.2c0.2,0,0.5-0.1,0.7-0.2l0.4-0.3l0.5-0.2l-0.1,0.7h-0.1
c-0.3,0.1-0.5,0.2-0.7,0.5c-0.1,0.1-0.3,0.3-0.4,0.4c-0.2,0.5-0.1,1,0.2,1.4l0.6,0.6l-0.1,0.1c-0.3-0.2-0.6-0.3-1.1-0.3
s-0.9,0.1-1.2,0.2h-0.1C232,206.3,231.6,206.5,231,206.9z`
  },
  {
    id: "TayNinh",
    name: "Tây Ninh",
    d: `M220.7,600.2c-0.1-0.2-0.1-0.4-0.1-0.6c-0.1-0.3-0.3-1.4-0.4-1.8c0-0.1-0.1-0.3-0.1-0.4
c-1.1-1.7-1.2-1.8-1.3-1.9c-0.3-0.3-0.7-0.4-1.1-0.4c-0.4,0-0.8,0.1-1.2,0.3c-0.1,0-0.2,0.1-0.3,0.2l-0.1-0.1
c-0.2-0.3-0.4-0.4-0.7-0.5c-0.1,0-0.1,0-0.2,0h-0.1l-0.4-0.6c-0.1-0.2-0.3-0.3-0.5-0.4c-0.2-0.1-0.5-0.2-0.7-0.3
c0-0.6-0.1-1.2-0.1-1.2c-0.1-0.6-0.5-1.1-1.2-1.1l0,0c0,0-0.1,0-0.2,0c-0.9,0-1.6-0.1-1.8-0.3c-0.6-0.5-0.9-1-1-1.2
c0-0.7-0.1-1.4-0.1-1.4c-0.1-0.5-0.4-0.9-0.9-1l-0.7-0.1l0,0c0.1-0.4,0.5-2,0.5-2.1c0.1-0.5-0.1-0.9-0.4-1.2V584
c0.8-0.6,1.2-1.2,1.2-1.8c0-0.7-0.3-1.3-1-1.6c0.1-0.6,0.1-1.2,0.1-1.9c0-0.1,0-0.2,0-0.3c0-0.4-0.3-2.3-1.6-2.6
c-0.3-0.1-0.6-0.1-1.1-0.1c0-0.1-0.1-0.2-0.1-0.3c0.1-0.1,0.3-0.2,0.3-0.3l0.6-0.9c0.1-0.2,0.2-0.4,0.2-0.6c0.1-0.1,0.3-0.1,0.4-0.2
c1.6,1.1,2,1.2,2.2,1.2s0.4,0.1,0.5,0.1c0.3,0,0.5-0.1,0.7-0.1c0.1-0.1,0.3-0.1,0.4-0.3c1.1-1.1,1.2-1.6,1.2-1.9
c0-0.1,0.1-0.3,0.2-0.4c0.9-0.5,1.3-1.7,1.2-2.4v-0.1c0-0.1,0-0.3,0-0.4c0.1,0,0.1,0,0.2,0.1c0.3,0.1,0.6,0.2,0.9,0.2
c0.1,0.1,0.4,0.3,0.7,0.5c0.4,0.4,1.1,0.7,1.9,0.7c0.4,0,0.8-0.1,1.2-0.2c0.3-0.1,0.4-0.1,0.5-0.1c0.9,1,2.6,1.1,3,1.1
c0,0,0,0,0.1,0c0.2,0.5,0.4,0.9,1.1,1.4c0.4,0.3,0.9,0.5,1.4,0.5c0.3,0,0.6-0.1,0.7-0.1c0.1,0,0.3-0.1,0.5-0.1
c0.3-0.1,0.7-0.2,1.1-0.3c0.1,0,0.1,0,0.2,0c0.2,0,0.4,0.1,0.6,0.2c0.3,0.2,0.9,0.4,1.6,0.6l0.1,0.3c0,0.1,0,0.1,0.1,0.1l0.4,1
c0.1,0.2,0.3,0.7,0.9,1.1c0,0.1,0,0.3,0.1,0.3c0,0.1,0,0.1,0,0.2c0,0.1,0,0.1-0.1,0.2c-0.2-0.1-0.4-0.1-0.6-0.1
c-0.5,0-0.9,0.3-1.1,0.5l-0.1,0.1c-0.6,0.4-0.7,1.3-0.7,1.8c0,0.1-0.1,0.2-0.2,0.6c-0.1,0.2-0.1,0.3-0.1,0.4c0,0.1-0.1,0.2-0.1,0.3
c-0.2,0.1-0.5,0.2-0.7,0.3c-0.1,0.1-0.3,0.1-0.3,0.1c-0.9,0.4-1.1,2-1.1,2.4c0,0.1-0.1,0.5-0.2,0.6c-0.4,0.4-0.5,0.9-0.5,1.2v0.1
c-0.4,0.4-0.6,1-0.6,1.6c0,0.4,0.2,0.7,0.4,1c0,0,0,0.1-0.1,0.1c-0.3,0.5-0.2,1.2,0.1,1.7c0.1,0.2,0.3,0.4,0.6,0.6
c0,0.1,0,0.1,0,0.2c0,0.1,0,0.3,0,0.4c-0.1,0.5,0.2,0.9,0.3,1.1c0.2,0.3,0.5,0.4,0.9,0.4c0,0.1,0,0.2,0,0.3
c-0.1,0.3-0.2,0.9,0.2,1.4c0.1,0.2,0.5,0.5,1,0.5c0.1,0.2,0.4,0.3,0.7,0.4c0,0.3,0.1,0.5,0.2,0.8c0,0.1,0.1,0.1,0.1,0.2l-0.4,1
c0,0.1-0.1,0.2-0.1,0.3c0,0.2-0.1,1.2-0.1,1.7c0,0.1,0,0.1,0,0.2c-0.3,0.3-0.5,0.7-0.6,1H227c-0.3,0-0.5,0-0.8,0.1c0,0,0,0-0.1,0
c-1.8-1.1-2-1.2-2.2-1.2c-0.1,0-0.1,0-0.2,0c-0.3,0-0.9,0.1-1.6,0.5c-0.3,0.1-0.5,0.4-0.6,0.7c-0.1,0.2-0.1,0.4,0,0.6
c-0.1,0-0.1,0-0.1,0c-0.1,0-0.3,0-0.4,0.1L220.7,600.2z`
  },
  {
    id: "SonLa",
    name: "Sơn La",
    d: `M161,195c0-0.1-0.1-0.2-0.1-0.2c-0.1-0.6-0.6-1-1.1-1c-0.1,0-0.1,0-0.1,0l-0.7,0.1
c-0.1-0.2-0.3-0.4-0.5-0.6c-0.2-0.2-0.4-0.4-0.7-0.6c0.2-0.3,0.2-0.7,0-1.1s-0.5-0.6-0.9-0.6c-0.1,0-0.5-0.1-0.9-0.1
c-0.1,0-0.3,0-0.4,0c-0.1-0.6-0.4-1.6-1.2-1.8c0,0-0.1,0-0.1-0.1c-0.3-0.1-0.6-0.3-1.1-0.3c-0.4,0-0.7,0.1-1,0.3c0,0,0,0-0.1,0
l-0.5-0.5c-0.1-0.4-0.4-1.2-0.9-1.7c-0.4-0.4-0.5-0.7-0.5-0.7c-0.2-0.4-0.6-0.7-1.1-0.7h-0.6l-0.8-0.7c-0.1-0.1-0.1-0.1-0.2-0.2
c-0.2-0.1-1.6-0.9-2.1-1.2c-0.1-0.1-0.2-0.3-0.2-0.4c0-0.9-0.7-1.5-1.4-2l-0.1-0.1c-0.5-0.4-1-0.6-1.5-0.6c-0.2,0-0.4,0-0.6,0.1
c0-0.1-0.1-0.2-0.1-0.3c-0.2-0.3-0.5-0.4-0.7-0.5c-0.7-0.1-1.5-0.3-1.7-0.4c-0.3-0.1-0.7-0.2-1-0.2s-0.7,0.1-1.1,0.1
c-0.2-0.7-0.9-1.3-1.7-1.3c-0.4,0-0.8,0.1-1.2,0.4c-0.3,0.2-1.2,0.7-1.8,1c-0.3,0.2-0.5,0.5-0.6,0.9c0,0.3-0.1,0.7-0.1,0.8v0.1
c-0.1,0-0.3,0-0.3-0.1c-0.3-0.3-0.7-0.5-1.2-0.5c-0.7,0-1.3,0.4-1.6,0.7c-0.2,0-0.5-0.1-0.9-0.2c-0.2-0.1-0.5-0.1-0.7-0.1
c-1.2,0-2.1,0.9-2.5,1.4c0,0.1-0.1,0.1-0.1,0.1l0,0c-0.2-0.1-0.4-0.1-0.6-0.1c-1.3,0-1.9,1.3-2,2.3c-0.3,0.1-0.5,0.4-0.7,0.7
c-0.3,0.6-0.3,1.4,0.1,2.4c0,0.1,0.1,0.1,0.1,0.2l-0.1,0.1c-0.1,0.1-0.7,0.1-1,0.1s-0.7,0.1-0.9,0.4c-0.2,0.3-0.3,0.6-0.3,0.9
c0.1,0.5,0.1,1.1,0.2,1.4c-0.1,0-0.1,0-0.3,0c-0.1,0-0.1,0-0.2,0c0-0.1,0-0.1,0-0.1c0-0.5-0.2-0.9-0.7-1.2l-0.5-0.2
c-0.3-0.2-0.7-0.7-1.2-0.9c0,0-0.1-0.1-0.2-0.1c-0.3-0.3-0.8-0.7-1.8-0.7c-0.1,0-0.1,0-0.2,0s-0.2,0-0.3,0c-0.4,0-0.7,0-1.1-0.1
c-0.2,0-0.4-0.1-0.7-0.1c-0.1-0.3-0.3-0.6-0.6-0.9c0.1-0.3,0.1-0.6,0-0.9c-0.2-0.7-0.9-1-1.2-1.2c-0.3-0.1-0.6-0.2-0.9-0.2
c-1,0-1.4,0.9-1.6,1.3l-0.1,0.1c-0.1,0-0.1,0-0.2,0c-0.4,0-0.8,0.1-1.1,0.3l-0.1-0.1c-0.5-0.6-1.4-0.8-2-1c-0.2-0.1-0.3-0.1-0.5-0.1
l0,0c-0.3-0.3-0.9-0.9-1.4-0.9c-0.1,0-0.1,0-0.2,0s-0.5,0.2-0.8,0.4c-0.1-0.1-0.2-0.1-0.3-0.1c0-0.1,0.1-0.3,0.1-0.4
c0.1,0,0.2-0.1,0.3-0.2c0.2,0,0.4,0.1,0.6,0.1c0.1,0,0.1,0,0.2,0c0.6-0.1,1.1-0.5,1.2-1c0.1-0.4,0-0.8-0.1-1.1
c0.1,0,0.1-0.1,0.2-0.1c0.3-0.2,0.4-0.6,0.4-0.9c0-0.5-0.1-1.3-0.1-1.6c0-0.1-0.1-0.4-0.4-0.8c0.1-0.2,0.1-0.3,0.1-0.3
c0.1-0.3,0.1-0.7,0-1.1c0.1-0.1,0.2-0.2,0.3-0.3c0.1-0.2,0.2-0.4,0.2-0.7c0.1,0.1,0.1,0.1,0.2,0.2l0.1,0.1l0.7,0.5
c0.2,0.1,0.4,0.2,0.7,0.2c0,0,0.4,0,0.9,0c0.9,0,1.2-0.1,1.4-0.1c0.6-0.2,1-0.6,1.1-1.2c0-0.3,0-1-0.8-1.5c0-0.1-0.1-0.1-0.1-0.2
c0-0.3-0.1-0.8-0.2-1.4c0.1,0,0.1,0,0.1,0c0.4,0,0.7-0.1,1-0.4c0.5-0.5,0.4-1.2,0.4-1.6c0-0.1,0-0.1,0-0.1c0-0.3-0.2-1.2-0.3-2
c0-0.2,0-0.4,0.1-0.5c0.4-0.6,0.4-1.3,0.3-1.9c0-0.1,0-0.3,0-0.5c0-1.1-1-1.8-2.4-1.9l0.3-0.6c0.2-0.3,0.1-0.7-0.1-1.1
c-0.2-0.3-0.5-0.5-0.9-0.6h-0.3c0-0.1,0-0.1-0.1-0.2c0,0,0,0,0.1,0l0.3-0.1c0.3,0.1,0.8,0.1,1.4,0.1c0.1,0,0.3,0,0.4,0
c0.6,0,1.2-0.1,1.6-0.6c0.1-0.1,0.2-0.4,0.2-0.8c0.1-0.2,0.1-0.4,0.2-0.6c0.1-0.2,0.1-0.4,0-0.6l-0.2-0.9c0.1-0.1,0.1-0.3,0.2-0.4
c0.3-0.1,0.7-0.3,1-0.6c0.1,0,0.1,0,0.1,0c0.1,0,0.2,0.1,0.3,0.1c0.3,0,0.5-0.1,0.7-0.2c0.3-0.2,0.5-0.6,0.4-0.9v-0.3
c0.1-0.1,0.2-0.1,0.2-0.2c0.2-0.2,0.3-0.5,0.3-0.9l-0.1-0.5c0.2-0.2,0.4-0.4,0.5-0.6c0.5-0.6,0.4-1.4,0.1-2.1
c0.1-0.1,0.2-0.3,0.3-0.4c0.2,0.1,0.4,0.1,0.5,0.1c0.3,0,0.5-0.1,0.7-0.3c0.4-0.3,0.5-0.9,0.3-1.3l-0.1-0.1
c-0.4-0.9-0.5-1.1-0.5-1.2c-0.1-0.3-0.2-0.7-0.7-1.6c-0.1-0.2-0.2-0.3-0.2-0.4c-0.1-0.4-0.4-0.6-0.6-0.8c-0.1-0.1-0.1-0.1-0.2-0.2
c0.1-0.6,0-1.1-0.1-1.4c0-0.1,0-0.1,0-0.2c-0.1-0.4-0.3-0.8-0.5-1l-0.2-0.9c-0.1-0.4-0.4-0.7-0.8-0.8c-0.2-0.1-0.5-0.2-0.6-0.3
s-0.1-0.1-0.1-0.1c0-0.2,0-0.4-0.1-0.7l0.1-0.4l0.2-0.1c0.4-0.3,0.7-0.8,0.5-1.3c0-0.1,0-0.1-0.1-0.1c0.3-0.3,0.5-0.7,0.6-1.2
c0,0,0,0,0.1,0c0.3,0.1,0.6,0.2,1,0.2c0.2,0,0.5,0,0.7,0c0.3,0.3,0.8,0.8,1.4,0.9c0.1,0,0.3,0.1,0.3,0.1c0.1,0.7,0.3,1.6,1.1,1.9
c0,0.3,0.1,0.6,0.3,0.8c0.1,0.2,0.3,0.4,0.5,0.5c0,0.3,0.1,0.6,0.3,0.8l0.1,0.1c0,0.1-0.1,0.2-0.1,0.2c-0.3,0.3-0.8,0.9-0.7,1.6
c0.1,0.5,0.4,0.9,0.9,1.1c0.1,0,0.1,0,0.1,0c0.2,0.3,0.5,0.6,1.3,0.7c0,0,0,0,0,0.1l0.4,0.6c0.2,0.3,0.5,0.5,0.8,0.5h0.2l0.8,1.2
c0.2,0.3,0.5,0.5,0.8,0.5c0,0,0.2,0,0.4,0c0.2,0.4,0.5,0.9,0.8,1.2s1,0.8,1,0.8c0.2,0.2,0.5,0.3,0.7,0.3s0.4-0.1,0.6-0.1
c0.1-0.1,0.3-0.2,0.4-0.3h0.1c0.1,0,0.2,0,0.3,0c0.1,0,0.1,0,0.1,0c0.1,0.3,0.2,0.5,0.4,0.7c0.2,0.2,0.5,0.2,0.7,0.2
c0.1,0,0.1,0,0.2,0c0,0,0.1,0,0.2,0c0.2,0.1,0.5,0.2,0.8,0.2c0.4,0,0.7-0.1,1.1-0.4c0.4,0.2,0.8,0.3,1.1,0.3h0.1
c0.4,0.3,0.9,0.6,1.4,0.6c0.1,0.1,0.4,0.2,0.6,0.4c0.2,0.1,0.4,0.2,0.7,0.2c0.1,0,0.3,0,0.4-0.1c0.7-0.3,1.7-0.5,2-0.6
c0.1,0,0.2,0.1,0.3,0.1l0.1,0.1c0.3,0.1,0.9,0.2,1.3,0.2h0.1c0.3,0,0.5-0.1,0.7-0.2s0.5-0.4,0.7-0.7c0.3,0,0.5,0.1,0.5,0.1
c0.2,0.1,0.7,0.3,1.3,0.4h-0.1c-0.3,0.1-0.6,0.3-0.7,0.6c-0.1,0.3-0.1,0.7,0,0.9l0.2,0.4l-0.3,0.2c-0.5,0.4-0.6,1.1-0.2,1.6
c0.2,0.2,0.3,0.5,0.4,0.7c-0.4,0.8-0.4,1.9-0.3,2.2c0,0.3,0.1,0.5,0.3,0.7l0.3,0.3c-0.1,0.5,0.1,1.1,0.7,1.3
c0.1,0.1,0.3,0.1,0.3,0.2c0.2,0.7,0.8,1.2,1.5,1.2c0.2,0,0.5-0.1,0.7-0.2c0.5,0.5,1.1,0.7,1.7,0.8c0,0.3,0.1,0.7,0.4,1
c0.3,0.4,0.8,0.6,1.3,0.6c0.4,0,0.8,0,1.2,0.1c0.2,0,0.4,0,0.6,0c1,0,2.2-1.5,2.4-2.1c0.1-0.2,0.2-0.4,0.3-0.5c0.3,0,0.5,0,0.7,0
c0.4,0,0.8-0.1,1.1-0.1c0.1,0,0.2-0.1,0.2-0.1c0.3,0.4,0.7,0.6,1,0.7c0.1,0.1,0.2,0.1,0.2,0.1c0.6,0.5,1.2,0.6,1.8,0.7
c0.1,0,0.2,0,0.3,0c0.7,0,1.3-0.4,1.6-0.7c0,0.1,0.1,0.1,0.1,0.1c0.1,0.2,0.3,0.4,0.5,0.5c0,0.4,0.2,0.8,0.6,1l0.3,0.2
c0.1,0.2,0.1,0.4,0.3,0.6c0.3,0.3,0.7,0.5,1.1,0.5c0.1,0,0.3,0,0.4-0.1c0.3-0.1,0.5-0.1,0.7-0.2c0.2,0.2,0.4,0.3,0.6,0.4v0.9
c0,0.3,0.1,0.6,0.2,0.9v0.1c-0.1,0.3-0.3,0.9-0.1,1.5c0.1,0.2,0.2,0.4,0.5,0.6c-0.1,0.1-0.1,0.2-0.2,0.3c-0.2,0.4-0.2,0.7,0,1.1
c-0.1,0.5-0.1,1.1,0.7,1.8c0.1,0.1,0.3,0.2,0.5,0.3c-0.1,0.1-0.2,0.3-0.2,0.4c-0.4,0.1-0.8,0.3-1,0.6c-0.4,0.7-0.1,1.4,0,1.6
l0.7,1.6c0.1,0.1,0.1,0.2,0.2,0.3c0.1,0.2,0.6,0.7,0.8,0.9c0.1,0.2,0.5,0.8,0.6,0.9c0.1,0.2,0.4,1,0.6,1.2l0,0
c0.1,0.2,2.1,2.3,2.1,2.3c0.1,0.1,0.2,0.2,0.3,0.4c0.3,0.4,0.6,0.6,0.8,0.9c0,0.1,0,0.1,0,0.2c-0.1,0.1-0.3,0.5-0.5,0.6
c-0.3,0.3-0.3,0.7-0.3,1.1c0.1,0.4,0.4,0.7,0.7,0.8h0.1l0,0c-0.3,0.5-0.6,1.1-0.6,1.2c-0.3-0.1-0.5-0.2-0.8-0.3
c-0.7-0.3-2.6-0.9-2.8-0.9c-0.1,0-0.2-0.1-0.3-0.1s-0.3,0-0.4,0.1c-0.1,0-0.7,0.2-1.1,0.5c-0.9,0.5-1.1,1.5-0.9,2.3
c0,0.1,0.1,0.5,0.1,0.8c0,0.2-0.1,0.5-0.2,0.7c-0.3,0.4-0.3,0.9-0.1,1.3l-1.1,0.9c-0.3,0.2-0.4,0.5-0.4,0.9v0.4
C161.6,195,161.3,195,161,195z`
  },
  {
    id: "SocTrang",
    name: "Sóc Trăng",
    d: `M205.8,673.4c0.1-0.2,0.1-0.4,0-0.6l-0.4-1.7l1.2-0.5c0.5-0.2,0.8-0.9,0.6-1.4
c-0.7-2-1.1-2.2-1.4-2.3c-0.2-0.1-0.5-0.2-0.7-0.2c-0.4,0-0.8,0.1-1.1,0.4c-0.2-0.2-0.4-0.4-0.7-0.5l-0.9-0.2c-0.1,0-0.2,0-0.3,0
c-0.2,0-0.4,0.1-0.6,0.2c0,0-0.3,0.2-0.8,0.5c-0.3,0.2-0.7,0.3-0.8,0.3c-0.2,0.1-0.4,0.2-0.6,0.3c-0.1,0-0.2-0.1-0.3-0.1
c-0.1-0.1-0.3-0.1-0.5-0.1c0-0.2-0.1-0.3-0.2-0.5c-0.2-0.3-0.5-0.5-0.9-0.5l-0.8-0.1l-0.8-0.6c-0.2-0.1-0.4-0.2-0.7-0.2
c-0.1,0-0.1,0-0.2,0c-0.1,0-0.1,0-0.2,0.1l-0.3-0.3l0.6-4.3c0-0.3,0-0.5-0.2-0.8c0,0,0.1,0,0.1-0.1c0.1,0,0.1,0,0.2,0
c0.4,0,0.7-0.1,1-0.2c0.1,0,0.1,0,0.2-0.1h0.1c0.6,0,1.1-0.4,1.4-0.7c0.1-0.1,0.2-0.1,0.2-0.2c0.4-0.2,1.3-0.7,1.6-0.8h0.1l6.1-4
c0.2-0.2,0.4-0.4,0.5-0.7l1.6-6.2l6.5,6.6l0.1,0.4l0.1,0.7c0,0.1,0.1,0.2,0.1,0.3l0.4,0.8c0,0.1,0.1,0.1,0.1,0.1l4.2,6.3l0.2,0.4
l0.1,0.5l0.1,1.4v0.1l-1.4,1.5c-0.4,0.4-0.4,1.1,0,1.6l0.4,0.4c0.1,0.1,0.1,0.1,0.2,0.2l-0.5,0.1h-0.1l-5.6,1.8h-0.1L205.8,673.4z`
  },
  {
    id: "QuangTri",
    name: "Quảng Trị",
    d: `M250.7,373L250.7,373c-0.1-0.1-0.5-0.3-1.6-1.1c-0.1-0.1-0.1-0.3-0.1-0.3c0-0.3-0.1-0.7-0.3-0.9
c0.1-0.1,0.1-0.3,0.1-0.5c0.1-0.5-0.1-0.9-0.4-1.2c0-0.1,0.1-0.2,0.1-0.3c0.1-0.2,0.2-0.4,0.3-0.6c0.3-1.2-0.6-1.8-0.9-2
c-0.1-0.1-0.2-0.1-0.2-0.2c-0.5-0.5-1.6-0.9-1.9-1c-0.1-0.1-0.3-0.1-0.4-0.1c-0.2,0-0.3,0-0.5,0.1c-0.3,0.1-0.5,0.4-0.6,0.7
c-0.2,0.8-0.5,1.7-0.7,2.3h-0.1c-0.5,0-0.9,0.3-1.1,0.5l-0.1,0.1c-0.7,0.5-0.6,1.2-0.6,1.6c-0.5-0.5-1-0.5-1.4-0.5h-0.1
c-0.1-0.1-0.3-0.4-0.5-1.1c-0.1-0.1-0.1-0.2-0.2-0.4c-0.2-0.4-0.5-1.1-0.6-1.5c0.1-0.2,0.3-0.5,0.5-0.7c0.4-0.4,0.4-1.1,0-1.6
l-1-1.1c-0.1-0.1-0.2-0.2-0.3-0.2l-0.6-0.3c-0.2-0.1-0.7-0.4-1.4-0.5c-0.1-0.6-0.4-1.4-0.7-1.9l-0.5-1.1l0.1-6.8
c0-0.3-0.1-0.6-0.3-0.9l-0.7-0.7c0.5-0.1,0.9-0.5,0.9-1.1c0.4,0.7,1,0.7,1.3,0.7c0.2,0,0.4-0.1,0.6-0.1h0.1c0.3,0,0.9,0.1,1.3,0.2
c0.1,0,0.2,0,0.3,0c0.4,0,0.8-0.2,1-0.5c0.1,0.1,0.3,0.2,0.5,0.2c0.1,0.3,0.4,0.5,0.7,0.6l0.9,0.2c0.1,0,0.2,0,0.3,0s0.3,0,0.5-0.1
l0.2-0.1l0.5,0.1c0.1,0,0.1,0,0.2,0c0.3,0,0.5-0.1,0.7-0.3c0.3-0.2,0.4-0.5,0.4-0.9v-1.5c0.1-0.1,0.3-0.1,0.4-0.2
c0.3-0.2,0.9-0.6,0.8-1.8l0,0c0.1,0,0.1,0,0.2,0c0.3,0,0.5-0.1,0.7-0.2l0.9-0.7c0.3-0.2,0.5-0.6,0.5-1l-0.1-0.4l1.6-0.6
c0.1,0,0.1,0,0.1-0.1l1.3-0.7c0.1-0.1,0.2-0.1,0.3-0.2l1.5-1.6l4,3l0.3,0.6l-0.2,1.4c-0.1,0.4,0.1,0.8,0.4,1.1l0.3,0.3l2,3
c0,0.1-0.1,0.1-0.1,0.2L258,351c-0.4,0.2-0.6,0.6-0.6,1v0.3c0,0.6,0.5,1.2,1.2,1.2h1c0.1,0,0.1,0,0.2,0l0.5-0.1c0.1,0,0.2,0,0.3-0.1
l0.5-0.2c0.3-0.1,0.5-0.4,0.6-0.7c0.1,0,0.1,0.1,0.2,0.1l0.5,0.3l4.5,3.9c-0.1,0.1-0.2,0.1-0.2,0.1c-0.5,0.3-0.9,1-1.2,2.1l-0.5,0.5
c-0.1,0.1-0.1,0.1-0.1,0.2l-0.6,1l-0.7,1.1c-0.2,0.3-0.2,0.8,0,1.2l0.1,0.3l-0.7,0.4c-0.3-0.2-0.6-0.5-1.2-0.5
c-0.3,0-0.5,0.1-0.7,0.1c-0.1,0-0.3-0.1-0.3-0.1c-0.1-0.1-0.3-0.1-0.5-0.1c-0.1,0-0.2,0-0.3,0l-3,0.7c-0.3,0.1-0.5,0.3-0.7,0.5
l-0.1,0.2h-0.4c-0.1,0-0.2,0-0.3,0.1l-0.3,0.1l-0.2-0.1c-0.1-0.1-0.3-0.1-0.4-0.1c-0.2,0-0.4,0.1-0.6,0.2c-0.3,0.2-0.5,0.5-0.5,0.9
l-0.1,1.3c-0.1,0.5,0.2,0.9,0.5,1.1l0.7,0.4c0.1,0,0.1,0.1,0.1,0.1c0.3,0.1,0.7,0.3,0.9,0.5h-0.1c-0.1,0-0.3-0.1-0.5-0.1
c-1.2,0-1.8,1.1-1.9,2.2c0,0.1,0,0.2,0,0.3l0.1,0.7l-0.8,0.5C252.2,373.1,251.9,373.2,250.7,373z`
  },
  {
    id: "QuangNinh",
    name: "Quảng Ninh",
    d: `M259.5,179.9c-0.1-0.1-0.3-0.1-0.4-0.1c-0.2-0.1-0.4-0.3-0.6-0.3l-1.1-0.2l0.1-0.2l2-1.3
c0.3-0.2,0.5-0.6,0.5-1v-0.3c0-0.3-0.1-0.6-0.3-0.8c-0.2-0.2-0.5-0.3-0.8-0.3c0,0,0,0-0.1,0h-0.5c-0.1,0-0.1,0-0.2,0l-0.5,0.1
c-0.1,0-0.1,0-0.1,0l-0.5,0.2c-0.1,0-0.1,0.1-0.2,0.1l0,0c-0.2-0.1-0.4-0.2-0.6-0.2l0,0c-0.1,0-0.3,0-0.4,0.1
c-0.1-0.1-0.3-0.1-0.4-0.1h-0.3c-0.1,0-0.3,0-0.4,0.1h-0.1l-0.5-0.1H254c-0.3,0-0.5,0.1-0.8,0.3c-0.1,0.1-0.2,0.2-0.2,0.3h-0.1
c-0.1,0-0.3,0-0.4,0.1c-0.4,0.1-0.7,0.5-0.7,0.9l-0.1,0.6c-0.1,0.4,0.1,0.8,0.4,1.1l0.4,0.3h-0.2l-0.6-0.1l-0.5-0.1l-0.4-0.2
l-0.4-0.2l-0.3-0.2l-1-0.8c-0.1-0.1-0.2-0.1-0.3-0.2l-0.5-0.2c-0.1-0.1-0.3-0.1-0.4-0.1c-0.2,0-0.4,0.1-0.6,0.2H247
c-0.4,0.1-0.8,0.3-0.9,0.7c-0.1,0.4-0.1,0.9,0.2,1.2l0.4,0.5c-0.1,0-0.2-0.1-0.4-0.1H246c-0.1,0-0.2,0-0.3,0l-0.4,0.1l-0.3-0.1
l-0.2-0.3c-0.1-0.2-0.1-0.4-0.2-0.5c-0.1-1-1.3-1.6-1.3-1.7c-0.3-0.2-0.9-0.3-1.4-0.3c-0.1,0-0.2,0-0.3,0s-0.2,0-0.5,0.1
c-0.2,0-0.3,0-0.5,0c-0.3-0.1-0.9-0.4-1.2-0.5s-0.6-0.2-0.9-0.2c-0.3,0-0.5,0.1-0.8,0.2l0,0c-0.2-0.4-0.5-0.7-0.7-0.8
c-0.1-0.1-0.3-0.2-0.4-0.3c-0.4-0.6-1-0.7-1.4-0.7s-1,0-1.4,0.2c-0.2-0.2-0.5-0.3-0.9-0.3c-0.1,0-0.1,0-0.2,0
c-0.2-0.1-0.4-0.2-0.6-0.3c0.5-0.3,0.7-0.7,0.7-0.9c0.1-0.4,0.2-0.8,0.1-1.4c0-0.1,0-0.2,0-0.3c0.1-0.2,0.4-0.5,0.4-1
c0.3,0.3,0.6,0.5,1.1,0.5h0.1c0.1,0,0.2,0,0.4,0c0.1,0,0.1,0,0.2,0c0.3,0.5,0.8,0.6,1.1,0.6l0,0l0,0c0.3,0.2,0.7,0.3,1.2,0.3
s0.9-0.1,1.2-0.3c0.3,0.1,0.7,0.2,1.2,0.3l1.6,0.3l1.5,0.5c0.1,0,0.2,0.1,0.3,0.1s0.1,0,0.2,0l2.3-0.4c0.5-0.1,0.9-0.5,0.9-1.1v-0.1
l1,0.1c0.1,0,0.1,0,0.1,0c0.1,0,0.2,0,0.3,0c0.3-0.1,0.7-0.1,1.1-0.2l0.1,0.1c0.2,0.3,0.5,0.5,0.9,0.6h0.1c0.3,0,0.7-0.2,0.9-0.5
c0.3-0.4,1.5-1.9,1.8-2.6c0.2-0.5,0.6-1.5,0.1-2.3c0.3,0,0.6-0.1,0.9-0.1c0.5-0.1,0.9-0.4,1.1-0.8c0.1-0.1,0.1-0.2,0.1-0.3l0.3-0.1
c0.4-0.1,0.7-0.4,0.8-0.8l0,0l0.7-0.1c0.5-0.1,0.9-0.4,1-0.9l1.8,0.3c0.1,0,0.1,0,0.2,0c0.2,0,0.4-0.1,0.6-0.2l0.9-0.5l0.2,0.3
c0.2,0.3,0.5,0.4,0.9,0.4c0.1,0,0.2,0,0.3,0l0.8-0.2c0.5-0.1,0.9-0.6,0.9-1.1v-2c0-0.1,0-0.3-0.1-0.4c-0.1-0.3-0.3-0.7-0.3-0.9
c0.1-0.2,0.1-0.7,0-1.5v-0.1l-0.3-1.4V156v-0.1c0.1-0.1,0.3-0.2,0.3-0.4c0.8-0.1,1.4-0.9,1.5-1.1l0.1-0.1l0.8,0.2
c0.1,0,0.2,0.1,0.3,0.1c0.3,0,0.6-0.1,0.9-0.4l0.4-0.4l0.6,0.1h0.1c0.3,0,0.6-0.1,0.8-0.4c0.3-0.3,1.2-1.3,1.6-1.6
c0.2-0.1,0.3-0.3,0.5-0.4c0.3,0.7,0.5,1.5,0.5,1.5c0.1,0.4,0.5,0.7,0.9,0.7l1.8,0.3c0.1,0,0.1,0,0.2,0c0.4,0,0.7-0.2,1-0.5
c0.1-0.1,0.1-0.2,0.2-0.2c0.3,0.1,0.8,0.1,1.9,0.1s2.6-0.1,3-0.2c0.5-0.1,1.7-0.7,2.6-1.2l1.7-0.7c0.2,0.1,0.4,0.3,0.6,0.4
c0.3,0.1,0.5,0.2,0.8,0.2s0.5-0.1,0.7-0.1c0.1,0,0.2-0.1,0.2-0.1s0,0,0.1,0s0.1,0,0.1,0c0.1,0.7,0.3,2,1.8,2.3
c0.3,0.1,0.5,0.1,0.6,0.1c0.1,0.2,0.2,0.5,0.3,0.7l-0.5-0.2c-0.1,0-0.2-0.1-0.3-0.1l-0.6-0.1c-0.1,0-0.1,0-0.2,0
c-0.2,0-0.4,0.1-0.6,0.2c-0.1,0-0.1,0-0.2,0c-0.3,0-0.6,0.1-0.8,0.3c-0.2-0.1-0.4-0.2-0.7-0.2h-0.3c-0.3,0-0.6,0.1-0.8,0.3l0,0
l-0.3-0.1c-0.1-0.1-0.3-0.1-0.4-0.1s-0.2,0-0.3,0L284,155c-0.1,0-0.1,0.1-0.2,0.1l-0.6,0.3c-0.4,0.2-0.6,0.6-0.6,1v0.3
c0,0.3,0.1,0.6,0.3,0.8c0.2,0.2,0.5,0.3,0.7,0.3c0,0,0,0,0,0.1l-0.1,0.1l-0.2,0.2c-0.1,0.1-0.2,0.2-0.3,0.3l-0.1,0.3
c-0.1,0.3-0.2,0.5-0.1,0.8h-0.1h-0.4c-0.2,0-0.5,0.1-0.7,0.3l-0.2,0.1l0,0c-0.2-0.3-0.5-0.4-0.9-0.4h-0.3c-0.4,0-0.7,0.2-1,0.5
l-0.1,0.1l-0.1,0.1c-0.1,0.1-0.3,0.3-0.3,0.5l0,0c-0.3,0-0.6,0.1-0.8,0.3c-0.3,0.3-0.4,0.8-0.3,1.2v0.1l0,0l-0.1,0.1
c-0.1,0.1-0.1,0.2-0.1,0.3c-0.2,0-0.3,0.1-0.5,0.2c-0.3,0.2-0.5,0.5-0.5,0.8l-0.1,0.7h-0.1l0,0l-0.1-0.2L276,164l-0.1-0.4
c0-0.1-0.1-0.2-0.1-0.3l-0.2-0.3c-0.1-0.2-0.4-0.4-0.7-0.5l-0.5-0.1c-0.1,0-0.2,0-0.3,0c-0.2,0-0.4,0.1-0.6,0.2
c-0.3,0.2-0.5,0.5-0.5,0.8v0.2c0,0.2,0,0.4,0.1,0.6l0.1,0.3l-0.4-0.1c-0.1,0-0.1,0-0.1,0h-0.1v-0.1c-0.1-0.3-0.3-0.5-0.6-0.5
l-0.2-0.1c-0.1,0-0.1,0-0.1,0l-1.4-0.3c-0.1,0-0.2,0-0.3,0c-0.4,0-0.9,0.3-1,0.7l-0.3,0.6c-0.1,0.3-0.1,0.6,0,0.8l-0.9,0.3
c-0.5,0.1-0.8,0.6-0.8,1.1v0.3c0,0.1,0,0.2,0,0.3l-0.2,0.3c-0.1,0.1-0.1,0.3-0.1,0.5l-0.1,1.4l-0.1,0.3c-0.2,0.5,0,1,0.4,1.3
l0.3,0.2c0.1,0,0.1,0.1,0.2,0.1l0.3,0.1l-0.1,0.5l-0.2,0.6l-0.4,0.4c-0.3,0.3-0.4,0.6-0.3,0.9c-0.2,0.3-0.3,0.6-0.2,1l0.1,0.6
l0.1,0.6c0,0.1,0,0.1,0.1,0.2l0.1,0.3l0,0l-0.3,0.1h-0.8h-0.7c-0.1,0-0.3,0-0.4,0.1l-0.1,0.1l-0.1-0.1c-0.1-0.1-0.3-0.1-0.4-0.1H264
l-0.9,0.1c-0.1,0-0.1,0-0.2,0l-0.8,0.2c-0.1,0-0.1,0.1-0.2,0.1l-0.5,0.3c-0.4,0.2-0.7,0.6-0.7,1.1c0,0.1,0,0.2,0.1,0.3l-0.5,0.5
c-0.1,0.1-0.2,0.3-0.3,0.4l0,0L259.5,179.9z`
  },
  {
    id: "QuangNgai",
    name: "Quảng Ngãi",
    d: `M318.4,449.4c-0.2-0.3-0.6-0.6-1-0.7v-0.1c-0.1-0.4-0.5-0.7-1-0.7h-0.3l-0.5-1
c-0.2-0.3-0.5-0.5-0.8-0.5c0.3-0.3,0.4-0.7,0.4-1.1c0-0.3-0.1-0.7-0.2-0.9l0.1-0.1c0.3-0.2,1-0.6,1-3.1c0-0.6-0.4-1.1-1-1.2
l-0.4-0.1l-0.2-0.4c-0.2-0.4-0.6-0.7-1-0.7c-0.1,0-0.2,0-0.3,0.1l-0.6,0.2c-0.1-0.3-0.4-0.5-0.6-0.5c-0.4-0.1-1.6-0.5-2-0.7
c-0.1-0.1-0.4-0.6-0.4-0.8c0-0.6-0.4-1.3-1.2-1.5c-0.3-0.4-0.6-0.9-1.1-1.2v-0.1c0-0.2-0.1-0.3-0.1-0.5c-0.2-0.7-0.7-1.1-1.2-1.4
c-0.2-0.2-0.5-0.3-0.6-0.5c-0.3-0.3-0.7-1.1-1.1-1.8c0.1-0.2,0.1-0.4,0.1-0.6s0-0.4,0-0.6c0.4-0.3,0.7-0.8,0.7-1.4
c0.1-0.2,0.3-0.7,0.4-0.9c0.1-0.2,0.2-0.3,0.2-0.4c0.1-0.2,0.5-0.8,0.8-1.2c0.2-0.3,0.3-0.7,0.2-1.1l-0.1-0.3
c0.3-0.3,0.5-0.8,0.4-1.4c0-0.3-0.2-0.6-0.3-0.8c0.2-0.3,0.3-0.6,0.3-1c0-0.1,0-0.1,0-0.2c0.5,0.1,0.9,0.1,0.9,0.1
c1.1,0,1.8-0.1,2.2-0.4c0.2-0.1,0.5-0.4,0.8-0.6c0.3,0.1,0.7,0.3,1,0.3l0,0c0.2,0,0.5,0.1,0.7,0.1c0.7,0,1.2-0.3,1.5-0.6
c0.1,0,0.1,0,0.2,0c0.5,0,1.3-0.3,2.4-0.7c0.3-0.1,0.5-0.2,0.7-0.2c0.2-0.1,0.5-0.2,1-0.4c0.5-0.2,1.4-0.5,1.7-0.6
c0.5,0,1.9-0.1,2.5-0.3c0.4-0.1,0.9-0.5,1.3-0.9l0,0v0.4v0.1v0.5c0,0.2,0,0.3,0.1,0.5l-0.1,0.2c-0.1,0.3-0.1,0.7,0.1,1.1
c0.2,0.3,0.6,0.5,0.9,0.5h0.3c0.5,0,0.9-0.3,1.1-0.8l0.7-0.1c0.4-0.1,0.7-0.3,0.9-0.6l0.3,0.3v1.2c0,0.2,0,0.3,0.1,0.5l0.2,0.5
c0.1,0.1,0.2,0.3,0.3,0.4l0.9,0.8c-0.1,0.1-0.2,0.1-0.3,0.2c-0.2,0.2-0.3,0.5-0.3,0.8v0.3c0,0.2,0,0.3,0.1,0.5c0,0.1,0,0.1,0,0.1
l-0.1,0.5l-0.4,0.1c-0.3,0-0.5,0.2-0.7,0.4l-0.3,0.3c-0.3,0.4-0.4,0.9-0.1,1.4l0.3,0.5c0.2,0.3,0.6,0.5,1,0.5l0,0
c0.1,0.3,0.3,0.7,0.7,0.8l0.2,1.7c0,0.1,0,0.1,0,0.2l0.9,3.3c0,0.1,0,0.1,0.1,0.1l2,4.7c-0.1,0.1-0.2,0.3-0.3,0.4
c-0.2,0.4-0.1,0.8,0.1,1.2l0.2,0.3c0.1,0.1,0.3,0.3,0.5,0.3l0.5,0.2c0,0.1,0.1,0.1,0.1,0.1l0.8,1.2c0,0.1,0.1,0.1,0.1,0.2l0.2,0.2
l1.4,1.8l0.1,0.3v0.1c-0.3,0.2-0.4,0.6-0.4,0.9v0.5l-0.2-0.2l-0.1-0.1c-0.3-0.3-1.2-0.9-2-0.9h-0.1c-0.1,0-0.2-0.1-0.3-0.1
c-0.3-0.2-0.7-0.4-1.1-0.5c-0.1,0-0.2-0.2-0.2-0.2c-0.2-0.3-0.6-0.5-0.9-0.5c-0.2,0-0.4,0.1-0.6,0.2c-0.4,0.2-0.8,0.5-1.1,0.5
c-0.2-0.1-0.5-0.1-0.9-0.1c-0.7,0-1.6,0.1-1.6,0.1c-0.3,0-0.5,0.1-0.7,0.3c-0.1,0.1-0.3,0.3-0.6,0.3h-0.1c-0.2,0-0.3,0-0.5,0
c-0.8,0-1.5,0.3-1.8,0.9c-0.3,0.5-0.3,1.2,0,1.8c0,0.1,0,0.3,0,0.6l0,0l-0.5-0.5c-0.2-0.2-0.5-0.4-0.8-0.4h-0.1
c-0.3,0-0.7,0.2-0.9,0.5l-0.5,0.8l-1.5,0.7C318.7,448.7,318.4,449,318.4,449.4z`
  },
  {
    id: "QuangNam",
    name: "Quảng Nam",
    d: `M297.5,432.3c-0.4-0.3-0.8-0.4-1.1-0.5l-0.5-0.6c-0.2-0.2-0.4-0.4-0.7-0.5
c-0.7-0.1-2-0.4-2.4-0.5c0,0-0.2-0.2-0.3-0.3c-0.1-0.1-0.1-0.2-0.2-0.3c0.8-0.4,1.1-1.2,1.3-1.8c0.1-0.2,0.1-0.4,0.2-0.6
c0.7-1.5-0.4-3.2-1.5-3.7c-0.5-0.3-0.8-0.5-0.9-0.8c-0.3-0.7-0.9-1.1-1.6-1.1c-0.5,0-1.1,0.3-1.4,0.5l0,0c-0.1,0-0.1-0.1-0.2-0.1
c-0.4-0.3-1-0.4-2-0.4c-0.2,0-0.3,0-0.4,0l-1.7-0.3l-0.9-0.3l-0.3-2.7c-0.1-0.4-0.3-0.7-0.7-0.9c-0.1-0.1-0.3-0.1-0.5-0.1
s-0.4,0.1-0.6,0.2l-0.9-1.2l-0.2-1.4c-0.1-0.3-0.2-0.5-0.4-0.7l-1.2-1.1c-0.1-0.1-0.2-0.1-0.3-0.2l-1.1-0.4
c-0.1-0.1-0.3-0.1-0.4-0.1c-0.3,0-0.5,0.1-0.7,0.3l-1,0.8l-0.2-0.1v-0.1c0.1-0.9-0.4-2-0.6-2.4c-0.2-0.3-0.5-0.6-0.9-0.6l-0.8-0.1
l-0.9-0.8c-0.1-0.1-0.2-0.1-0.3-0.2c-0.2-0.1-0.5-0.1-0.7-0.1c-0.5,0-0.9,0.2-1.2,0.4l-0.5-0.1l-0.5-2.2c-0.1-0.2-0.2-0.4-0.3-0.6
c0-0.3-0.1-0.5-0.2-0.7l-0.9-1.4c-0.2-0.3-0.5-0.4-0.7-0.5c0,0-0.7-0.1-1.4-0.1h-0.1c-0.1-0.2-0.1-0.4-0.2-0.6
c0.1-0.3,0.3-0.7,0.3-1.2c0.1-0.9-0.4-1.5-1-1.9V400c0-0.4-0.2-0.7-0.5-0.9c-0.2-0.1-0.4-0.2-0.7-0.2c0,0,0,0-0.1,0
c0-0.1,0.1-0.2,0.1-0.3c0.1-0.5,0.1-1-0.1-1.5c0.5-0.2,1.2-0.5,1.4-0.6l0.1-0.1c0.2-0.2,0.6-0.3,0.8-0.4c0.7-0.1,1.2-0.5,1.4-1.1
l0.7-0.1l0.4,0.2c0.2,0.1,0.4,0.2,0.6,0.2s0.5-0.1,0.7-0.2l0.7-0.5c0.1-0.1,0.2-0.2,0.3-0.3l1-1.4c0.1-0.1,0.1-0.1,0.1-0.2
c0.3-0.7,1.1-2.8,1.3-3.3c0,0,0-0.1,0.1-0.1c0.4,0.4,0.8,0.7,1.3,0.7l1,0.1c0.1,0,0.1,0,0.1,0c0.3,0,0.5-0.1,0.7-0.3l0.5-0.4
c0.3-0.3,0.5-0.7,0.4-1h0.1c0.1,0.3,0.3,0.6,0.7,0.8l1.1,0.4l0.8,0.8c0.2,0.2,0.4,0.3,0.7,0.3c0,0,0.3,0,0.7,0
c0.7,0,1.1-0.1,1.3-0.1c0.8-0.3,1.3-0.9,1.4-1.6c0.7-0.2,1.3-0.7,1.4-0.9c0.3,0.1,0.6,0.2,0.9,0.3c0.2,0,0.4,0.1,0.5,0.1
s0.3,0,0.4,0c0.3-0.1,0.6-0.1,0.8-0.1l1,0.7c0.2,0.1,0.4,0.2,0.6,0.2s0.3,0,0.5-0.1l0.9-0.5c0.1,0.1,0.1,0.2,0.3,0.3l0.6,0.5
c-0.7,0.6-1.1,1.5-1.2,1.7c-0.1,0.4-0.1,0.9,0.2,1.2l1.2,1.3l0.1,0.1c0.1,0.1,0.6,0.5,1.3,0.5c0.4,0,0.8-0.1,1.2-0.3
c0.2-0.1,0.5-0.2,0.8-0.2c0.1,0,0.1,0,0.1,0c0.5,0.3,1.2,0.3,1.6,0.3c0.3,0,0.6-0.1,0.8-0.4l0.3-0.3l0.7,0.3
c0.2,0.1,0.4,0.1,0.5,0.1s0.3,0,0.4-0.1c0.3-0.1,0.5-0.3,0.7-0.6l0,0c0.1,0,0.3,0.1,0.4,0.1c0.3,0,0.5-0.1,0.7-0.3h0.1
c0.2,0.3,0.5,0.4,0.9,0.4h0.1c0.4,0,0.7-0.2,0.9-0.5l0.4-0.6c0.5,0.1,1.2,0.1,1.7,0.1l0,0l0.1,0.1c0.1,0.1,0.1,0.1,0.2,0.2l2.1,1.7
c0.1,0.1,0.1,0.1,0.2,0.1l0.3,0.1c-0.1,0.1-0.1,0.2-0.1,0.3l-0.1,0.2c-0.2,0.6,0.2,1.2,0.8,1.4l0.3,0.1c0.1,0,0.2,0.1,0.3,0.1
c0.1,0,0.2,0,0.3-0.1l0.2,1.6c0,0.1,0.1,0.3,0.1,0.4l1.3,2.5c0,0.1,0.1,0.1,0.1,0.1l6.6,9.2c0.1,0.1,0.2,0.2,0.3,0.3l0.1,0.1
c0,0,0,0.1-0.1,0.1l-0.3,0.5c-0.2,0.3-0.3,0.7-0.1,1.1l0.3,0.8c0.1,0.2,0.2,0.4,0.4,0.5l0.9,0.7c0.2,0.1,0.5,0.2,0.7,0.2
s0.4-0.1,0.6-0.1l0.7-0.4c0,0,0.1,0,0.1-0.1l0.1,0.2c0,0.1,0.1,0.1,0.1,0.1l0.4,0.4c-0.1,0.1-0.2,0.1-0.3,0.2
c-0.3,0.1-1.4,0.2-1.8,0.2s-1,0.2-2.5,0.7c-0.3,0.1-0.7,0.3-0.9,0.3c-0.2,0.1-0.5,0.2-0.8,0.3c-0.4,0.1-1.3,0.5-1.6,0.5
c-0.1,0-0.2,0-0.3,0c-0.7,0-1.2,0.3-1.4,0.7c-0.1,0-0.2,0-0.3,0l0,0c-0.2,0-0.7-0.2-1.1-0.4c-0.1-0.1-0.3-0.1-0.5-0.1
c-0.3,0-0.6,0.1-0.8,0.3c-0.3,0.3-0.7,0.7-0.9,0.8c-0.2,0.1-0.7,0.1-1.1,0.1c-0.2,0-0.7,0-0.9-0.1c-0.2-0.1-0.4-0.1-0.5-0.1
c-0.4,0-1.5,0.2-1.7,2.1l0,0c-0.3,0-0.7,0.1-0.9,0.4c-0.2,0.3-0.3,0.7-0.2,1l0.2,0.8c0.1,0.2,0.2,0.4,0.3,0.5
c-0.1,0.3-0.1,0.6,0,0.9l0.2,0.5c-0.2,0.3-0.5,0.7-0.7,1.1c-0.1,0.1-0.1,0.2-0.2,0.3c-0.3,0.5-0.6,1.1-0.7,1.7
c-0.1,0-0.2,0.1-0.2,0.1c-0.3,0.1-0.5,0.3-0.6,0.6c-0.1,0.3-0.1,0.6,0,0.9c-0.1-0.1-0.2-0.2-0.3-0.3c-0.4-0.5-0.9-0.7-1.4-0.7
c-1.1,0-1.8,1.1-2,1.4c-0.1,0.3-0.2,0.6-0.1,0.9l0.3,1.1c0.1,0.5,0.2,0.9,0.3,1.2C298.2,432.3,297.8,432.3,297.5,432.3L297.5,432.3z`
  },
  {
    id: "QuangBinh",
    name: "Quảng Bình",
    d: `M241.9,349c-0.2-0.3-0.6-0.6-1.1-0.6l0,0c-0.2-0.3-0.6-0.5-0.9-0.5c-0.1,0-0.1,0-0.2,0l-0.9,0.1
c-0.3,0.1-0.5,0.2-0.7,0.4c-0.3-0.1-0.7-0.1-0.9-0.1c-0.1,0-0.1,0-0.2,0c0-0.1-0.1-0.1-0.1-0.2c0.1-0.4,0.1-0.8-0.1-1.2
c-0.2-0.4-0.7-0.7-1.2-0.7c-0.2,0-0.8-0.1-1.3-0.3c-0.1,0-0.2,0-0.3,0c-0.4,0-0.7,0.2-0.9,0.4l-0.4,0.5c-0.1,0-0.3,0.1-0.4,0.1
c-0.4-0.1-0.6-0.1-0.7-0.3c-0.3-0.3-0.8-0.5-1.4-0.6l-0.2-0.7c-0.1-0.2-0.2-0.4-0.4-0.6l-0.1-0.1l0.2-0.5c0.1-0.3,0.1-0.7,0-0.9
c-0.3-0.5-0.8-1.5-1.7-1.7c-0.1,0-0.1-0.1-0.2-0.2c-0.1-0.1-0.2-0.3-0.4-0.4v-0.1c0-0.4-0.1-1.4-1.1-1.8c-0.1-0.1-0.2-0.3-0.2-0.4
c-0.1-0.1-0.1-0.2-0.1-0.3c0.1-0.1,0.1-0.1,0.2-0.3c0.5-0.7,0.3-1.3,0.2-1.5c-0.1-0.3-0.4-0.6-0.9-0.7l0,0v-1.2
c0-0.3-0.1-0.6-0.4-0.8c-0.2-0.2-0.5-0.3-0.8-0.3c0,0,0,0-0.1,0l-1.3,0.1c-0.3,0-0.6,0.2-0.8,0.4c-0.2,0.3-0.3,0.6-0.3,0.9l0.1,0.4
h-0.1l-3-2.2V333c0-0.3-0.2-0.6-0.5-0.8l-2.1-1.4l-2.4-3l-3.9-4.6l-2.5-3.1c-0.1-0.1-0.3-0.3-0.5-0.3c-0.5-0.2-1.1-0.5-1.2-0.6
c-0.3-0.3-1.4-1.2-1.6-1.3c-0.2-0.2-0.5-0.3-0.7-0.3c-0.1,0-0.1,0-0.2,0l-0.2-0.8c0-0.1-0.1-0.1-0.1-0.2l-0.4-0.9
c-0.1-0.2-0.3-0.4-0.5-0.5l-1.3-0.7l-0.3-0.3c0.7-1.9,0.1-2.5-0.1-2.7c-0.3-0.3-0.7-0.6-1.3-0.9c-0.1-0.1-0.3-0.2-0.4-0.3l0,0
c-0.4-0.3-0.6-0.5-0.7-0.6c0.3-0.5,0.3-1.1,0.4-1.4c0-0.1,0-0.2,0-0.2c0.3-0.3,0.5-0.7,0.7-1.1h1.6c0.5,0,1-0.3,1.1-0.9
c0.1-0.2,0.2-0.5,0.3-0.7c0.6,0.3,1,0.4,1.3,0.4c0.2,0,0.5-0.1,0.6-0.2s0.4-0.2,0.7-0.2c1.3,0,2.2-0.6,2.4-1.5
c0.6-0.2,1.1-0.5,1.4-1c0,0,0,0,0.1,0l0.5,0.1l0.2,0.3c0.1,0.2,0.3,0.3,0.4,0.4l0.9,0.5c0.2,0.1,0.3,0.1,0.5,0.1
c0.1,0,0.3,0,0.4-0.1c0.3,0.3,0.5,0.6,0.6,0.7c0.3,0.4,0.8,0.9,1.4,1c-0.1,0.1-0.1,0.1-0.1,0.1c-0.2,0.2-0.3,0.5-0.3,0.8v0.8
c0,0.5,0.3,0.9,0.7,1.1c0.5,0.2,2.9,1.1,3.7,1.3c0.5,0.1,1,0.1,1.4,0.1c0.3,0,1.1,0,1.5-0.1c0.1,0,0.6-0.1,1.1-0.2l0.5,0.3
c0.2,0.1,0.4,0.2,0.6,0.2c0.1,0,0.2,0,0.2,0l1-0.2c0.4-0.1,0.7-0.3,0.8-0.7c0.1-0.2,0.3-0.8,0.4-1.1c0,0,0,0,0-0.1h0.1
c0.5,0,0.9-0.3,1.1-0.7c0.1-0.1,0.1-0.3,0.2-0.4c0.4,0.1,1.1,0.3,2.6,0.6l0.3,0.5c0.2,0.3,0.6,0.5,0.9,0.5h0.1
c0.3,0,0.5-0.1,0.7-0.1l-0.3,0.4l-0.1,0.1l-0.2,0.3H230c-0.6,0-1.2,0.5-1.2,1.2v0.3c0,0.3,0.1,0.6,0.3,0.8c-0.1,0.3-0.1,0.6,0,0.8
l0.3,0.7l0.3,1.1l0.2,1c-0.1,0-0.2-0.1-0.3-0.1l-0.4-0.1l-1.3-0.7c-0.1-0.1-0.3-0.1-0.4-0.1l-2.6-0.3l-0.4-0.2
c-0.1-0.1-0.3-0.1-0.5-0.1c-0.3,0-0.5,0.1-0.8,0.3l-0.3,0.3c-0.3,0.2-0.4,0.6-0.4,0.9s0.2,0.7,0.5,0.9l0.6,0.4
c0.1,0.1,0.1,0.1,0.2,0.1l0.9,0.4h0.1l1,0.3c0.1,0,0.1,0,0.2,0l0.4,0.1l-0.1,0.7c-0.1,0.5,0.1,0.9,0.6,1.2l1.2,0.7
c0.2,0.1,0.4,0.1,0.6,0.1c0.3,0,0.5-0.1,0.8-0.3c0.3-0.2,0.4-0.5,0.4-0.9h0.3l1.1,0.4l0.6,0.4l0.3,0.3l0.9,1.7
c0,0.1,0.1,0.1,0.1,0.2l1,1.2l1.9,3.1l0.4,1.2c0,0.1,0,0.1,0.1,0.1l0.3,0.7c0.1,0.3,0.4,0.5,0.7,0.6l0.3,0.3
c0.1,0.2,0.2,0.3,0.4,0.5l0.4,0.6l0.1,0.1l3.6,4c0.1,0.1,0.1,0.1,0.2,0.1l8,6l-1.2,1.2l-1.1,0.6l-2.3,0.9c-0.5,0.2-0.8,0.7-0.7,1.2
l0.1,0.6l-0.5-0.1c-0.1,0-0.1,0-0.2,0c-0.3,0-0.6,0.1-0.8,0.3l-0.6,0.6c-0.3,0.3-0.4,0.8-0.3,1.3c0,0.1,0,0.1,0.1,0.2
c-0.1,0-0.2,0.1-0.3,0.1c-0.5,0.1-0.9,0.6-0.9,1.1v1.1C242.1,349.1,242,349.1,241.9,349L241.9,349z`
  },
  {
    id: "PhuYen",
    name: "Phú Yên",
    d: `M334.8,526.1c0-0.1,0-0.2,0-0.3l-0.2-0.9c-0.1-0.3-0.3-0.6-0.5-0.8c-0.1-0.7-0.6-1.4-1.9-1.4
c-0.3,0-0.6,0-0.8,0.1c-0.4-0.1-1.4-0.2-2.2-0.2c-0.1-0.2-0.2-0.5-0.3-0.8c-0.1-0.4-0.3-0.7-0.6-0.9l-0.9-0.5h-0.1
c-0.1-0.1-0.6-0.3-1.1-0.3c0,0,0,0-0.1,0c-0.3-0.6-0.9-1.9-1.3-2.5c-0.1-0.2-0.3-0.4-0.5-0.5c-0.1-0.1-0.9-0.4-1.6-0.4h-0.1
c-0.1-0.2-0.2-0.3-0.3-0.5c0.1,0,0.3-0.1,0.4-0.1l1.6-0.9c0.4-0.2,0.6-0.6,0.6-1.1l-0.1-2.1c0.1-0.4,0.1-0.7,0.2-1
c0.1-0.1,0.3-0.3,0.5-0.4l2.2-0.7c0.3-0.1,0.6-0.4,0.7-0.7l0.3-0.8c0-0.1,0.1-0.2,0.1-0.3v-0.7c0.3-0.4,0.7-1.1,0.7-1.7
c0.1-0.6,0.1-1,0.1-1v-1.2c0-0.4-0.2-0.7-0.5-0.9c-0.1-0.1-0.3-0.1-0.5-0.2l-0.7-1.1c-0.1-0.1-0.1-0.2-0.2-0.2l-0.8-0.8v-1.1
l-0.1-1.9c0-0.1,0-0.2-0.1-0.3l-0.4-1.4c-0.1-0.3-0.3-0.5-0.6-0.7c-0.2-0.1-0.3-0.1-0.5-0.1c-0.1,0-0.3,0-0.4,0.1
c-0.6,0.2-1.4,0.5-1.7,0.5c-0.1,0-0.1,0-0.2-0.1l0,0c0.1-0.1,0.1-0.3,0.1-0.4c0.3-0.2,0.8-0.6,0.9-1.3c0-0.1,0.1-0.2,0.2-0.4h0.2
h0.1c0.3,0,0.6-0.1,0.8-0.4l0.2-0.3h0.1c0.3,0.3,0.7,0.6,1.2,0.7c0.1,0,0.2,0,0.3,0c0.1,0,0.1,0,0.2,0c0.3,0.7,0.9,1.1,1.7,1.1
c0.3,0,0.5-0.1,0.7-0.1c0.1,0,0.3-0.1,0.5-0.1c0.3,0.1,0.5,0.3,0.7,0.4c0.7,0.6,2.1,0.6,2.3,0.6s0.3,0,0.4,0
c0.5-0.1,1.3-0.4,1.7-0.5c0.2-0.1,0.4-0.3,0.6-0.5l0.8-1.3h0.7c0.4,0,0.9-0.1,1.7-1.1c0.1-0.1,0.1-0.1,0.1-0.2
c0.3-0.6,0.5-1,0.5-1.2c0,0,0-0.1,0.1-0.1s0.1,0,0.2,0c0.4,0,0.8-0.2,1-0.5c0.2-0.2,0.4-0.7,0.4-0.7c0,0.3,0.1,0.5,0.2,0.8l0.2,0.3
c0.1,0.1,0.3,0.3,0.4,0.3l0.2,0.1l0.1,0.1l0.1,0.1l0.1,0.7c0,0.1,0,0.1,0.1,0.2l0.2,0.4c0.1,0.2,0.2,0.3,0.3,0.4l0.2,0.2
c0,0.3,0,0.5,0.2,0.7l0.3,0.5c0,0.1,0.1,0.1,0.1,0.1l0.1,0.1c-0.1,0-0.1,0-0.1,0.1l-0.3,0.1c-0.2,0.1-0.3,0.2-0.4,0.3l-0.3,0.4
c-0.1,0.1-0.1,0.2-0.2,0.3l-0.2,0.6c-0.1,0.3-0.1,0.6,0.1,0.9v0.1v0.3c0,0.2,0,0.4,0.1,0.6l-0.1,0.3c0,0.1-0.1,0.2-0.1,0.3l-0.1,0.7
c0,0.5,0.2,0.9,0.6,1.1l0.5,0.3l0.5,0.7c0.1,0.1,0.1,0.2,0.3,0.3l0.4,0.3c-0.2,0.1-0.3,0.3-0.5,0.5l0,0l-0.1,0.1
c-0.5,0.3-0.7,0.9-0.5,1.4l0.6,1.4c0,0.1,0,0.1,0.1,0.1l0.3,0.6c0,0.1,0.1,0.1,0.1,0.2l0.5,0.6c0.1,0.1,0.2,0.2,0.3,0.2l0.2,0.7v0.3
l-0.2,0.5c0,0.1,0,0.1,0,0.2l-0.1,0.7c0,0.1,0,0.3,0,0.4l0.1,0.7c0,0.1,0.1,0.2,0.1,0.3l0.3,0.6c0,0.1,0.1,0.1,0.1,0.1l0.3,0.5l0,0
c-0.1,0.5,0.1,1,0.5,1.3l0.4,0.3l0.1,0.1l0.7,1.4c0,0.1,0.1,0.1,0.1,0.2l1,1.2l0.7,1.2c0,0.1,0.1,0.1,0.1,0.1l1.6,1.9l0.1,0.1
c-0.2,0-0.4,0.1-0.6,0.2l-0.9,0.6c-0.1,0.1-0.1,0.1-0.2,0.2c-0.2-0.3-0.4-0.4-0.7-0.5c-0.1,0-0.1,0-0.2,0c-0.2,0-0.5,0.1-0.7,0.2
l-0.9,0.6c-0.3,0.2-0.9,0.4-1.2,0.4h-2.1c-0.4,0-0.8,0.1-1.1,0.3c-0.1,0-0.2-0.1-0.3-0.1h-0.1l-2.2,0.2c-0.4,0-0.8,0.3-0.9,0.7
l-0.3,0.6c-0.1,0-0.2-0.1-0.3-0.1h-0.1c-0.3,0-0.6,0.2-0.8,0.5l-0.6,0.8c0,0-0.4,0.6-0.7,1c-0.1,0.1-0.5,0.2-1,0.2
C335.5,525.8,335.2,525.9,334.8,526.1z`
  },
  {
    id: "PhuTho",
    name: "Phú Thọ",
    d: `M183.4,180.2c-0.1,0-0.3-0.1-0.3-0.1c-0.1,0-0.1,0-0.1,0c-0.2-0.2-0.5-0.4-0.9-0.5
c-0.3-0.1-0.6-0.1-0.8-0.1c-0.3,0-0.5,0.1-0.6,0.1c-0.1-0.1-0.1-0.1-0.2-0.2l-0.7-0.4c-0.2-0.1-0.4-0.2-0.6-0.2
c-0.3,0-0.6,0.1-0.8,0.4c-0.1,0.1-0.2,0.2-0.5,0.3c-0.1,0-0.2,0.1-0.5,0.1c-0.2,0-0.3,0-0.4,0c0,0,0,0-0.1-0.1
c-0.2-0.2-0.5-0.4-0.9-0.6c-0.1-0.1-0.3-0.5-0.4-0.6c-0.1-0.1-0.2-0.4-0.8-1.2c-0.2-0.2-0.4-0.4-0.7-0.4c0,0-0.5-0.1-0.9-0.2
c-0.3-1.1-1.6-1.5-2.1-1.6H171c-0.3-0.1-1-0.3-1.5-0.3c-0.1-0.3-0.2-0.6-0.4-0.8v-0.1c0-0.3-0.1-0.6-0.3-0.9
c-0.3-0.5-0.7-0.8-0.8-0.9c-0.1-0.1-0.2-0.1-0.3-0.2c-0.3-0.1-0.6-0.3-0.8-0.3c0-0.3,0-0.5-0.1-0.8c0.1-0.2,0.1-0.3,0.2-0.4
c0.2-0.3,0.3-0.7,0.2-1.1c-0.1-0.3-0.2-0.6-0.6-0.9v-0.1c0.1-0.5-0.1-0.9-0.2-1.3c0-0.1-0.1-0.1-0.1-0.2v-1c0-0.2,0-0.5-0.1-0.8
c0.3,0.1,0.5,0.2,0.8,0.2c0.2,0,0.5,0,0.7-0.1c0.6-0.2,0.8-0.6,0.9-0.9c0.1-0.5,0.1-0.9-0.3-1.4l-0.1-0.2c0.4-0.5,0.7-1.2,0.8-1.7
v-0.1c0-0.4,0.1-1.2-0.2-1.9l-0.1-0.2c-0.1-0.3-0.6-1.2-0.6-1.6c0-0.5,0-0.9-0.1-1.2c0.4-0.3,0.7-0.8,0.7-1.3c0-0.2-0.1-1.2-0.1-1.8
c0-0.1,0-0.2,0-0.3l0.1-0.1c0.5,0,0.8-0.2,1-0.4l0,0h0.1c0.2,0.2,0.5,0.3,0.7,0.3s0.5-0.1,0.6-0.2c0.2-0.1,0.7-0.6,1-1.2
c0,0,0.1,0,0.1-0.1l0,0c0.1,0,0.2-0.1,0.7-0.4c0.6,0.3,0.9,0.3,1,0.3h0.1c0.1,0,0.3,0,0.3,0.1c0.1,0.1,0.5,0.2,1.3,0.3l0,0
c0.3,0,0.5-0.1,0.7-0.3c0.1-0.1,0.3-0.3,0.4-0.5c0,0.1,0.1,0.1,0.1,0.1c0.2,0.3,0.6,0.4,0.9,0.4c0.1,0,0.3,0,0.4-0.1
c0.3,0.3,0.9,0.8,1.5,1.1c-0.1,0.3-0.1,0.6,0,0.9c0.1,0.3,0.4,0.6,0.7,0.7c0,0.1,0,0.3,0,0.4c0.1,0.5,0.3,1.1,0.8,1.5
c0,0.2,0,0.4,0.1,0.6c0.2,0.4,0.5,0.7,1,0.9c0,0.4,0.2,0.7,0.4,1s0.5,0.4,0.8,0.4c0.1,0.3,0.1,0.5,0.3,0.7c0.1,0.3,0.3,0.9,0.3,1.2
c-0.1,0.4,0,0.9,0.3,1.3c0.3,0.3,0.6,0.5,1,0.5c0.2,0,0.5-0.1,0.7-0.2c-0.1,0.7-0.1,1.6,0.2,2.2c0,0,0,0.1,0.1,0.1
c-0.9,0.3-1.5,0.9-2,1.7c-0.5,0.7-0.4,1.6-0.3,2.1c-0.2,0.1-0.6,0.3-0.7,0.4l-0.1,0.1c-0.8,0.5-1.4,1.6-1.6,2.3
c-0.2,0.9,0.4,2,0.8,2.4c0,0.1,0.1,0.2,0.1,0.3c-0.1,0.2-0.2,0.6-0.3,0.8c-0.6,0.8-0.5,2.4-0.2,3c0.2,0.4,0.7,1.2,0.9,1.6
c0.3,0.5,0.4,0.7,0.5,0.8s0.1,0.3,0.2,0.4v0.1c0,0,0,0,0,0.1C183.7,180,183.5,180.1,183.4,180.2z`
  },
  {
    id: "NinhThuan",
    name: "Ninh Thuận",
    d: `M331.7,586.2l-1.4-0.6c0-0.3-0.1-0.5-0.2-0.7c-0.2-0.3-0.5-0.4-0.8-0.5c-0.2,0-0.5-0.1-0.6-0.2
c-0.2-0.1-0.4-0.4-0.4-0.4c-0.1-0.2-0.4-0.3-0.6-0.4l-1.9-0.5l-0.5-0.3l0.1-1.8c0-0.3-0.1-0.6-0.3-0.8s-0.5-0.3-0.8-0.3h-0.9
c-0.1,0-0.1,0-0.1,0s-0.7,0.1-1.2,0.1c-0.3,0-0.6-0.1-0.8-0.2c-0.2-0.3-0.6-1.6-0.8-2.5c-0.1-0.5-0.6-0.8-1.1-0.9l-2.1-0.1l-0.6-0.9
v-0.6l1.6-0.2c0.1,0,0.3-0.1,0.4-0.1c0.5-0.3,1.2-0.6,1.4-0.6c0.9,0,1.5-0.6,1.6-0.8l0.8-0.9c0.2-0.3,0.3-0.7,0.2-1l-0.5-1.7
c-0.1-0.4-0.4-0.7-0.7-0.8l0.1-0.3c0.2-0.3,0.2-0.7,0-1.1s-0.5-0.6-0.9-0.6l-0.9-0.1c0.4-0.3,0.9-0.9,0.9-1.7c0-0.2,0.2-1,0.3-1.4
c0.1-0.7,0.2-0.9,0.2-1.2c0-0.1,0.1-0.7,0.1-1.2c0-0.1,0-0.2,0-0.3s0-0.1,0-0.2c0.5-0.5,0.8-1.3,0.8-1.9c0-0.1,0.1-0.5,0.1-0.7
c0.5-0.2,1.2-0.8,0.9-2.3c-0.1-0.5-0.2-1-0.2-1c-0.1-0.3-0.2-0.6-0.5-0.7l-0.4-0.3l0.1-0.2l0.5-0.3c0.2,0.1,0.4,0.1,0.6,0.1
c0.1,0,0.2,0,0.3,0c0.2,0,0.3,0.1,0.5,0.1c0,0.4,0,0.8,0.2,1.2l0.6,1.5l-0.1,0.5c-0.2,0.5,0.1,1.2,0.6,1.4l1.5,0.7l0,0v0.1
c-0.2,0.5-0.5,1.3,0,2c0.2,0.3,0.6,0.7,1.4,0.7c0.5,0,1.2,0.1,1.6,0.2c0.4,0.3,1.1,0.7,1.5,0.9s1.6,0.7,1.7,0.8s0.3,0.1,0.5,0.1
c0.1,0,0.2,0,0.3,0l0.6-0.1c0.4,0.1,0.7,0.3,0.9,0.4c0.1,1,0.7,2.4,1.6,3c0.4,0.3,1.1,0.9,1.3,1.1c0.2,0.2,0.5,0.3,0.8,0.3h0.1
l2.3-0.2c0,0.2,0.1,0.4,0.2,0.5l0.9,1.2l-0.3,0.3c-0.1,0.1-0.2,0.2-0.2,0.3l-0.3,0.7l-0.6,0.7c-0.1,0.1-0.2,0.3-0.2,0.4l-0.1,0.4
c0,0.1-0.1,0.2-0.1,0.3v0.3l-0.1,0.3l0,0l-0.7,0.5c-0.1,0.1-0.3,0.3-0.3,0.4l-0.1,0.3c0,0.1,0,0.1-0.1,0.2l-0.1,0.3l0,0
c-0.1,0-0.2-0.1-0.3-0.1h-0.6c0,0,0,0-0.1,0s-0.1,0-0.1,0h-0.5l-0.2-0.1l0,0l-0.2-1.2c0-0.1-0.1-0.3-0.2-0.4l-0.2-0.3
c-0.2-0.3-0.6-0.5-0.9-0.5c-0.1,0-0.2,0-0.3,0l-0.9,0.2c-0.6,0.1-0.9,0.7-0.9,1.2l0.1,0.5c0,0.2,0.1,0.5,0.3,0.6l0.4,0.5l-0.1,0.5
c0,0.1,0,0.1,0,0.2v0.3c-0.3,0.1-0.6,0.2-0.7,0.5c-0.2,0.4-0.2,0.8,0,1.2l0.5,0.8v1.4L334,583c0,0.1,0,0.2,0,0.3l0.1,1.4v0.1
l-0.3,0.3l-0.9,0.7l-0.4,0.3l-0.3,0.1L331.7,586.2L331.7,586.2z`
  },
  {
    id: "NinhBinh",
    name: "Ninh Bình",
    d: `M216,217.8c0-0.3-0.1-0.6-0.4-0.8c-0.2-0.2-0.5-0.3-0.8-0.3h-0.1c-0.3,0-1.1,0.1-1.5,0.1
s-0.9-0.1-1.1-0.2c-0.3-0.2-0.8-0.3-1.4-0.3c-0.1,0-0.1,0-0.2,0c-0.2-0.2-0.5-0.4-0.8-0.7c-0.7-0.5-1.5-0.9-1.6-1
c0,0-1.7-0.9-2.3-1.2c-0.7-0.4-2.1-1.1-2.2-1.1c-0.7-0.3-1.8-0.9-2.1-1.2c-0.4-0.4-1.5-1.5-2.1-2.1c-0.1-0.1-0.2-0.2-0.3-0.3
c0.2,0,0.3-0.1,0.5-0.2l1.2-0.8l0.7-0.2c0.4-0.1,0.7-0.5,0.8-0.9c0-0.2,0-0.4-0.1-0.5c0.7-0.2,1.1-0.8,1.1-1.5
c0-0.5-0.1-1.2-0.1-1.4c0,0,0,0,0.1,0c0.3,0.4,0.5,0.6,0.6,0.7c0.2,0.2,0.5,0.3,0.8,0.3c0.1,0,0.1,0,0.1,0l1.1-0.1
c0.2,0.2,0.3,0.4,0.3,0.4c0,0.1,0.1,0.1,0.1,0.1l1.2,1.1c0.1,0.1,0.3,0.2,0.5,0.3c0,0,0.1,0,0.4,0.1c-0.1,0.2-0.1,0.4-0.1,0.6
c0.1,0.7,0.6,1.3,1.3,1.5c0,0,0,0,0.1,0.1s0.2,0.2,0.4,0.3c0,0.1,0,0.2,0,0.3c0,0.7,0.4,1.6,1.1,2.1c0.3,0.2,0.5,0.3,0.9,0.4
c0.1,0.6,0.5,1,1,1.3c0.3,0.1,0.6,0.2,0.9,0.2c0.4,0,0.8-0.1,0.9-0.2c0.1,0,0.1-0.1,0.1-0.1s0.1-0.1,0.3-0.2
c0.3,0.3,0.7,0.5,1.1,0.5c0.4,0,0.7-0.2,1-0.3c0.1,0.8,0.2,1.8,0.3,2c0,0.1,0,0.1,0,0.2c0,0.1,0,0.2,0,0.2c-0.4,0.4-0.6,1-0.8,1.5
c-0.1,0.3-0.1,0.5-0.2,0.7c0,0,0,0,0,0.1C216.4,217.2,216.1,217.6,216,217.8z`
  },
  {
    id: "NgheAn",
    name: "Nghệ An",
    d: `M195,280.2c-0.1,0-0.5-0.1-0.9-0.2c-0.3-0.3-0.7-0.7-0.8-0.8c-0.1-0.3-0.5-1.2-1.9-1.2
c-0.2,0-0.4,0-0.5,0s-0.1,0-0.1,0s-1,0.3-1.5,0.5c-0.4,0.1-1.3,0.4-1.9,0.5c-0.3,0.1-0.6,0.1-0.8,0.1c-0.1,0-0.1,0-0.1,0
c-0.2-0.1-0.9-0.5-1.5-1c-0.5-0.4-1-0.6-1.6-0.6s-1.1,0.2-1.4,0.3h-0.1c-0.3,0.1-0.8,0.4-1.2,0.7v-1.1c0-0.3-0.2-0.6-0.4-0.9
l-1.5-1.2c-0.1-0.1-0.1-0.1-0.2-0.1c0-0.3,0-0.7-0.2-1c-0.3-0.5-0.7-0.7-1.2-0.7c-0.2,0-0.4,0-0.6,0.1c-0.4,0.1-0.9,0.1-1.3,0.1H175
c-0.2-0.3-0.5-0.6-0.9-0.8c-0.1-0.1-0.2-0.1-0.3-0.2c-0.5-0.3-1-0.6-1.7-0.6h-1.9c-0.1,0-0.1,0-0.1,0H170c-0.1-0.3-0.3-0.6-0.7-0.9
c-0.1-0.1-0.3-0.2-0.4-0.3c-0.4-0.4-0.9-0.9-1.6-0.9c-0.3,0-0.7,0.1-1,0.3c-0.1,0.1-0.4,0.2-0.7,0.3c-0.2-0.1-0.5-0.2-0.7-0.2
c-0.1,0-0.4-0.1-0.5-0.2c-0.6-0.2-1.1-0.4-1.4-0.4c-0.2,0-1.1-0.1-2-0.2c-0.3-0.2-0.5-0.5-0.7-0.6c-0.3-0.7-1-1.1-2.5-1.4
c0-0.1-0.1-0.1-0.1-0.2c-0.3-0.6-1-1.3-1.5-1.6c-0.2-0.1-0.8-0.4-1.2-0.5c-0.3-0.5-0.7-0.9-0.8-1.2c-0.1-0.9-0.7-2-1.9-2
c-0.1-0.1-0.1-0.1-0.1-0.2l0,0c-0.4-0.5-1-0.7-1.6-0.7c-0.4,0-0.8,0.1-1.2,0.3c0,0-0.1-0.1-0.2-0.1l-0.3-0.3c-0.3-0.3-0.9-0.9-1-1.2
c-0.3-0.9-1.6-1.7-2.5-1.8c-0.2,0-0.8-0.3-1.4-0.6l-0.3-0.6c-0.2-0.4-0.5-0.6-0.9-0.7l-1.3-0.1h-0.1c-0.1,0-0.2,0-0.3,0l-0.3,0.1
c0.2-0.6-0.1-1.2-0.6-1.5l-0.7-0.3c-0.3-0.7-0.7-1.5-1.6-1.6c-0.7-0.1-1.9-0.4-2.2-0.6c-0.5-0.6-1.3-1.6-2.4-1.6
c-0.2,0-0.4,0-0.6,0.1c-0.2,0.1-1.2,0.2-2,0.3c-0.7-0.2-1.3-0.5-1.6-0.7c-0.3-0.5-0.8-1.4-0.8-1.4c-0.1-0.1-0.2-0.3-0.3-0.4
c1-0.3,1.7-1.2,1.9-1.8c0-0.1,0-0.1,0.1-0.3c0.5-0.1,1.2-0.3,1.7-0.6c0.3-0.1,0.5-0.3,0.6-0.3c0.5,0.2,0.9,0.2,0.9,0.3
c0.1,0,0.1,0,0.2,0c0.5,0,1-0.3,1.1-0.9l0.5-1.9l0.3-0.5l0.6-0.2c0.4-0.1,0.7-0.4,0.8-0.7s0.1-0.7-0.2-1.1c-0.2-0.2-0.4-0.6-0.4-0.7
c0.2-0.9,0-2-0.8-2.7c-0.1-0.1-0.6-0.7-1.1-1.4l0.1-0.6c1.2,0.4,1.6,0.4,1.7,0.4c0.6,0,1.1-0.2,1.2-0.2c0.3-0.1,0.6-0.4,0.7-0.8
l0.2-0.6c0.8-0.1,1.6-0.3,2.1-0.4h0.1c0.1-0.1,0.3-0.1,0.4-0.1c0.6,0.5,1.6,0.7,2.1,0.7c0.1,0.1,0.1,0.1,0.1,0.2
c0.2,0.4,0.6,0.6,1,0.6c0.1,0,0.2,0,0.3,0c0.3-0.1,1.4-0.4,1.9-0.7c0.1-0.1,0.4-0.1,0.7-0.2c0.4,0.7,0.7,1,0.7,1.1
c0.3,0.5,0.8,1.1,2.1,1.1c0.2,0,0.3,0,0.4,0l0,0c0,0.1,0.1,0.1,0.1,0.1s1.1,1.3,2.2,1.5c0.2,0,0.5,0.1,0.7,0.1c0.5,0,0.9,0,0.9,0
c0.4,0,0.8-0.3,1-0.7c0,0,0-0.1,0.1-0.1c0.5,0.2,1,0.3,1.5,0.3c1.5,0,1.9-0.9,1.9-1.5c0-0.3-0.1-0.5-0.1-0.8l0.5-0.5
c0.3-0.3,0.4-0.8,0.3-1.2l-0.1-0.3l1.4-1.2l1,0.1h0.1c0.6,0,1.1-0.4,1.2-1c0-0.1,0.1-0.3,0.1-0.5c0.3,0.1,0.5,0.1,0.7,0.1
c0.6,0,1.2-0.2,1.6-0.6c0.4-0.4,0.6-0.9,0.6-1.4c0-0.1,0.2-0.5,0.4-0.8c0.3-0.4,0.3-1-0.1-1.4l-0.1-0.1c0.2-0.4,0.4-0.9,0.2-1.4
c0-0.1-0.1-0.5-0.1-0.8c0.1-0.2,0.3-0.4,0.4-0.5c1-0.3,1.6-1,1.9-1.6c0.2,0.1,0.5,0.1,0.7,0.1c0.1,0,0.3,0,0.3,0
c0.1,0.1,0.2,0.2,0.4,0.3c0.4,0.4,1.2,0.9,1.2,0.9c0.2,0.1,0.3,0.2,0.6,0.2l0.7,0.1c0.1,0.2,0.2,0.4,0.4,0.5s0.4,0.2,0.7,0.2
c0.1,0,0.2,0,0.3,0c0.4-0.1,1.2-0.3,1.5-0.3c0.1,0,0.2,0,0.3,0c0.2,0.4,0.6,0.6,1,0.6h0.8c0,0.2,0,0.3,0.1,0.5c-0.5,0.1-0.9,0.4-1,1
v0.2c-0.4,0.1-0.7,0.5-0.7,0.8c-0.1,0.5-0.3,1.8,0.2,2.7c0,0.1,0,0.2,0,0.3c0,0.4-0.1,1.3,0.6,1.8c0.2,0.1,0.4,0.3,0.5,0.4l0.1,1.4
c0.1,0.6,0.5,1,1.2,1c0.1,0,0.4,0.1,0.6,0.2c0.6,0.5,1.2,1.1,1.2,1.1c0.2,0.2,0.5,0.3,0.8,0.3c0.1,0,0.3,0,0.4-0.1
c0.1,0,0.1,0,0.1-0.1v0.1c0.1,0.3,0.3,0.7,0.5,0.9v0.7c-0.3,0.3-0.5,0.7-0.5,1.2c0,0.4,0.1,1.3,0.1,1.5s0.1,0.9,0.4,1.4
c0.3,0.3,0.6,0.7,0.7,0.8c0.2,0.2,0.5,0.3,0.8,0.3h0.1c0.3,0,0.6-0.2,0.8-0.5c0.3-0.4,0.7-0.8,0.8-0.9c0.1-0.1,0.2-0.1,0.3-0.2
c0.1,0.4,0.2,0.8,0.5,1.2c0.1,0.1,0.5,0.7,0.9,1.2c0.2,0.3,0.5,0.5,0.8,0.5c0.1,0,0.1,0,0.2,0c0.3,0,0.5-0.1,0.7-0.3l0,0
c0.3,0.2,0.6,0.4,1,0.4c0.3,0,0.6-0.1,0.9-0.2c0,0,0.1,0,0.2,0h0.3c0.3,0,0.9,0,1.4-0.4h0.1c0.1,0.2,0.4,0.6,0.5,0.9
c0.5,0.8,0.7,1.2,0.9,1.4c0,0.1,0.1,0.3,0.1,0.4c0.1,0.5,0.5,0.9,1,0.9l1.1,0.1h0.1c0.1,0,0.2,0,0.3,0c0.3,0.3,0.6,0.6,0.9,0.8v0.5
c0,0.3,0.2,0.6,0.4,0.8c0.7,0.7,1.1,0.7,1.2,0.8c0.3,0.1,0.7,0.2,1.1,0.2c0.2,0,0.4,0,0.6-0.1c0.3,0,0.5-0.1,0.8-0.3l0.6,0.6
c0.1,0.1,0.2,0.1,0.3,0.2v0.1h-0.1c-0.1,0-0.1,0-0.2,0c-0.5,0-0.9,0.3-1.1,0.7l-0.1,0.2c0,0.1,0,0.1-0.1,0.2l-0.5,2.6
c0,0.1,0,0.1,0,0.2l-0.1,1.6c0,0.1,0,0.2,0,0.3v0.1l-0.1,0.1c0,0-0.1,0-0.1,0.1l-0.4,0.3c0,0,0,0-0.1,0l-0.4-0.1
c-0.1,0-0.2-0.1-0.3-0.1c-0.3,0-0.6,0.1-0.8,0.3l-1,1c-0.1,0.1-0.1,0.2-0.2,0.3L196,261c0,0.1,0,0.1-0.1,0.1l-0.4,1.3
c0,0.1-0.1,0.2-0.1,0.3v0.5c0,0.2,0,0.4,0.1,0.6l0.1,0.3v0.1l0.1,0.5c0,0.1,0,0.1,0,0.1l0.3,1.4c0,0.1,0,0.1,0,0.1l0.2,0.5
c0,0.1,0.1,0.2,0.2,0.3l0.5,0.7c0.1,0.1,0.1,0.1,0.2,0.2l0.7,0.6c0,0.2,0,0.4,0.1,0.6l0.4,1c0,0,0,0.1,0.1,0.1l0.1,0.2
c0.1,0.3,0.4,0.4,0.6,0.5l0.3,0.1c0.1,0,0.2,0.1,0.4,0.1c0.1,0,0.1,0,0.1,0h0.3l0.5,0.5l0.3,0.5v0.1l-0.5,1.3
c-0.1,0.3-0.1,0.7,0.1,1l0.2,0.3c0.1,0.1,0.1,0.2,0.2,0.3l0,0v0.2l-0.1,0.7l-0.1,0.3c-0.6,0.1-1,0.6-1.3,1c-0.1,0.1-0.1,0.1-0.1,0.2
c-0.2,0.1-0.4,0.3-0.5,0.5c-0.3,0.5-0.3,0.9-0.2,1.2c0,0.1,0,0.3,0,0.4l0,0c0,0,0,0.1-0.1,0.1s-0.2,0.1-0.3,0.1
c-0.2-0.1-0.6-0.2-1.2-0.2H197h-0.9c-0.2,0-0.3,0.1-0.5,0.1C195.6,280,195.2,280.2,195,280.2z`
  },
  {
    id: "NamDinh",
    name: "Nam Định",
    d: `M218,220.2L218,220.2l0.2-0.9c0-0.1,0-0.3,0-0.4c0.7-0.4,0.8-1.1,0.9-1.5c0-0.1,0-0.2,0.1-0.3
c0.1-0.3,0.3-0.7,0.4-0.9c0.6-0.6,0.5-1.4,0.5-1.8c0-0.1,0-0.1,0-0.1c0-0.3-0.2-1.5-0.3-2.4c-0.2-2-1.3-2.1-1.6-2.1
c-0.2,0-0.3,0-0.5,0.1c-0.1,0.1-0.2,0.1-0.3,0.1h-0.1c-0.2-0.7-0.7-1.2-1.4-1.2c-0.2,0-0.6,0.1-1,0.5c-0.3-0.3-0.7-0.5-1.3-0.5
c-0.3,0-0.5,0-0.7,0c-0.1,0-0.2,0-0.3,0c-0.1-0.1-0.1-0.1-0.1-0.2c0-0.5,0-1.6-1-2.1l-0.1-0.1c-0.1-0.1-0.1-0.1-0.2-0.2
c0.1-0.3,0.2-0.7,0.2-1c-0.1-0.4-0.3-0.7-0.5-1l0.1-0.1c0.2,0.3,0.5,0.4,0.8,0.5c0,0,0.3,0,0.5,0c0.5,0,0.9-0.1,1.2-0.3
c0.6-0.4,0.7-1.1,0.8-1.5c0-0.1,0-0.1,0-0.1c0.1-0.1,0.2-0.3,0.4-0.5c0.1,0.1,0.3,0.1,0.4,0.1c0.2,0,0.4,0.1,0.6,0.1
c0.9,0,1.5-0.4,1.7-0.9c0.2,0.1,0.4,0.2,0.6,0.3c0.2,0.1,0.3,0.1,0.5,0.1c0.7,0,1.5-0.4,2-0.8l0,0l0,0c-0.1,0.2-0.1,0.4-0.1,0.6
c0,0.1,0,0.2-0.1,0.3c-0.3,0.4-0.4,0.9-0.2,1.4c0.1,0.3,0.5,0.9,1.5,0.9c0.1,0,0.1,0,0.1,0c0.3,0,0.5,0,0.7-0.1c0.1,0,0.2,0,0.3,0
l0.1,0.1c0,0.1,0,0.1,0,0.2c-0.5,0.3-1.1,0.7-1.3,1.4c-0.1,0.4-0.1,0.8,0.1,1.2c0.5,0.8,1.1,0.9,1.4,0.9c0.6,0,1.1-0.4,1.4-0.7
c0.4-0.3,0.9-0.8,1.2-1c0.1-0.1,0.1-0.1,0.1-0.1l0.1,0.1c0.2,0.2,0.4,0.4,0.7,0.5c0,0,0.1,0,0.1,0.1c0.2,0.1,0.8,0.4,1,0.6
c0.5,0.7,1.8,1.6,2,1.8l0.1,0.1c0.2,0.1,0.5,0.4,1.1,0.4c0.3,0,0.7-0.1,1-0.3c0.1,0,0.1-0.1,0.2-0.1c0.1,0.1,0.1,0.1,0.2,0.1
l0.3,0.1c0.1,0.1,0.3,0.1,0.4,0.1l-0.1,0.1l-0.2,0.2l-0.6,0.2c-0.1,0-0.1,0.1-0.2,0.1l-0.9,0.5l-0.3,0.1l-1.2,0.1
c0-0.1-0.1-0.1-0.1-0.1c-0.2-0.2-0.5-0.3-0.8-0.3h-0.1h-0.3h-0.2c-0.1,0-0.3,0.1-0.4,0.1l-0.4,0.2c-0.4,0.2-0.6,0.6-0.6,1v0.1
l-0.1,0.1c-0.1,0.1-0.3,0.1-0.4,0.3l-0.3,0.3c-0.1,0.1-0.2,0.2-0.2,0.3l-0.5,1l-1.2,1.5l-0.7,0.5c-0.1,0.1-0.2,0.2-0.3,0.3l-0.9,1.2
l-0.7,0.5c0,0-0.1,0-0.1,0.1c-0.2-0.1-0.4-0.2-0.7-0.2c-0.1,0-0.1,0-0.2,0c-0.4,0.1-0.7,0.3-0.8,0.6l-0.3,0.6v0.1l-0.4,1.2l-0.1,0.1
l-0.3,0.1L218,220.2z`
  },
  {
    id: "LongAn",
    name: "Long An",
    d: `M230.7,624.5C230.7,624.5,230.7,624.4,230.7,624.5c0.1-0.4,0.2-0.7,0.2-1.1c0-1-0.9-1.6-1.8-2.1
l-0.1-0.1c-0.3-0.2-0.6-0.3-0.9-0.3c-0.2,0-0.4,0-0.6,0.1l-0.2-0.7c-0.1-0.3-0.3-0.5-0.5-0.7l0,0c0-0.3-0.1-0.6-0.3-0.8l-1.1-1.1
c-0.2-0.2-0.5-0.3-0.8-0.3c-0.1,0-0.2,0-0.3,0L224,617c-0.2-0.2-0.5-0.4-0.8-0.4h-0.1l-7.3,0.6c-0.6,0.1-1,0.5-1.1,1.1v0.9l-3.7-0.3
l-2.2-0.1l-3-4.2c-0.1-0.2-0.3-0.3-0.5-0.4l-1.8-0.6l-6-5.7c0-0.2,0-0.5-0.1-0.7l-1.2-2.6c-0.1-0.2-0.3-0.4-0.5-0.5l-1.6-0.7
c1.6-0.3,3.9-0.7,4.5-1c0.1,0,1.8-0.9,2.5-1.5c0.2-0.1,1-0.4,1.2-0.4c0,0,0,0,0.1,0c0.6,1.1,1.6,2.9,2.1,3.4c0.3,0.3,0.5,0.7,0.7,1
l-0.3,0.7c-0.1,0.3-0.1,0.6,0,0.9s0.3,0.5,0.6,0.6l3.2,1.3c0.1,0.1,0.3,0.1,0.4,0.1c0.2,0,0.5-0.1,0.7-0.2c0.3-0.2,0.5-0.6,0.5-1
v-0.4l0.3-0.3c0.5,0.6,1.1,1.2,1.5,1.4c0.1,0.1,0.3,0.2,0.5,0.3c0.8,0.6,1.9,1.4,3.1,1.4c0.1,0,0.2,0,0.3,0c0.7-0.1,1.5-0.1,2-0.1
l0.7,0.7c0.2,0.2,0.5,0.3,0.8,0.3c0.1,0,0.3,0,0.4-0.1c0.4-0.2,0.7-0.6,0.7-1.1v-1.9c0-0.2-0.1-0.4-0.1-0.5l-1.6-3l0.2-0.4
c0-0.1,0.1-0.2,0.1-0.3h0.5c0.2,0,0.4-0.1,0.6-0.2l0.6-0.4l0.9-0.3c0.1,0,0.3,0,0.4,0c0.2,0,0.4,0.1,0.6,0.1c0.3,0,0.6,0,0.8-0.1
c0.5-0.2,0.9-0.6,1-1.2l0,0c0.1,0.1,0.3,0.2,0.4,0.3l1.1,1.2c0.2,0.2,0.5,0.3,0.8,0.3h1.1l1.4,1.1c0.1,0.1,0.1,0.1,0.2,0.1l2.3,1.1
l-0.8,3.5l-1.4,1.1c-0.3,0.2-0.4,0.5-0.4,0.9s0.1,0.7,0.4,0.9l2,1.8c0.1,0.1,0.3,0.2,0.5,0.3c0.1,0.3,0.2,0.6,0.4,0.8l0.1,0.1
c0.1,0.1,0.3,0.3,0.3,0.3c0.2,0.9,0.8,1.5,1.7,1.5h0.1c0.1,0,0.3,0,0.5,0c0.3,0,0.5,0,0.8-0.1c0.1,0.1,0.2,0.2,0.3,0.3
c0.2,0.5,0.7,0.7,1.2,0.7s1-0.3,1.3-0.6h0.1c0.1,0,0.1,0,0.2,0c0.4,0,0.6-0.2,0.8-0.3c0,0,0.2,0,0.3,0s0.1,0.1,0.2,0.1
c0,0.4,0.1,0.7,0.1,0.9c0,0.7,0.5,1.4,1.1,1.7l-0.1,0.3l-0.1,0.3l-0.1,0.1l-0.8,0.3l-0.1-0.1c-0.2-0.4-0.6-0.6-1-0.6h-0.3
c-0.3,0-0.7,0.2-0.9,0.4c-0.2,0.3-0.3,0.6-0.2,1l0.1,0.3l-0.1,0.1l-0.3,0.3c-0.1,0.1-0.2,0.2-0.3,0.3c0-0.1-0.1-0.1-0.1-0.2
l-0.3-0.4c-0.2-0.3-0.6-0.5-0.9-0.5c-0.1,0-0.3,0-0.4,0.1c-0.5,0.2-0.8,0.6-0.7,1.2l0.1,1.3c-0.2,0.1-0.3,0.2-0.5,0.3
c-0.2-0.1-0.5-0.2-0.9-0.2c-0.1,0-0.6-0.1-0.9-0.1c-0.1,0-0.1,0-0.2,0c-0.3,0-0.5,0.1-0.8,0.3L230.7,624.5z`
  },
  {
    id: "LangSon",
    name: "Lạng Sơn",
    d: `M254.2,163.1c-0.2-0.2-0.5-0.4-0.8-0.6v-0.4c0.2-0.4,0.5-1,0.5-1.6c0-0.9-0.7-1.6-1.8-1.6
c-0.4,0-0.8,0.1-1.2,0.3c-0.1,0-0.2-0.1-0.3-0.1c-0.3-0.2-0.7-0.3-1.1-0.3h-0.1l-0.1-0.8c0-0.4-0.2-0.7-0.5-0.9c0,0-0.5-0.3-1-0.6
c0.2-0.4,0.4-0.9,0.3-1.4c-0.1-0.3-0.3-0.5-0.5-0.7c0-0.1-0.1-0.1-0.1-0.1c-0.1-0.4-0.5-0.7-0.9-0.8c0,0-0.7-0.1-1.4-0.1
c-0.2,0-0.4-0.1-0.4-0.1c-0.2-0.2-0.5-0.3-0.7-0.3c-0.1,0-0.1,0-0.2,0c-0.2,0-0.4,0.1-0.5,0.1c-0.2,0-0.9,0.1-1.3,0.8l-0.2-0.1l0,0
c0.1-0.6-0.2-1.2-0.7-1.4l-1.8-0.7c-0.1-0.1-0.3-0.1-0.4-0.1c-0.2,0-0.4,0.1-0.6,0.2l-0.2,0.1c-0.1,0-0.3-0.1-0.5-0.1
c-0.3-0.1-0.7-0.1-1-0.1c-0.1,0-0.2,0-0.3,0c-0.2,0-0.5,0-0.8,0s-0.7,0-1,0.1c-1.2,0.2-2.1,2.3-2.2,2.5c-0.1,0.1-0.4,0.7-0.7,1.2
c-0.3,0.4-0.2,0.9,0,1.3l0.1,0.1l-0.3,0.3c-0.1,0.1-0.2,0.2-0.3,0.3c-0.1,0.2-0.6,0.9-0.7,1.1c-0.1,0.1-0.1,0.1-0.2,0.2
c0,0,0,0.1-0.1,0.1c-0.3-0.1-0.6-0.2-0.9-0.2c-0.7,0-1.3,0.4-1.6,1c-0.1,0.2-0.2,0.5-0.2,0.7h-0.9c0,0-0.1,0-0.1-0.1
c0-0.2,0-0.5,0-0.8c-0.1-0.5-0.4-0.9-0.8-1c-0.1,0-0.4-0.1-0.9-0.2v-0.7c0-0.3-0.1-0.6-0.4-0.9c-0.2-0.2-0.5-0.3-0.8-0.3h-0.1
l-1.3,0.1c-0.3-0.1-0.9-0.3-1.4-0.3c-0.1,0-0.4-0.1-0.8-0.3c0-0.8-0.1-1.8-0.1-2.1l0.1-0.1c0.6-0.3,0.8-0.8,0.9-1.1
c0.1-0.4,0.1-0.8-0.1-1.3c0.3-0.3,0.8-0.7,0.9-0.9c0.1-0.1,0.3-0.3,0.3-0.5c0.1-0.5,0.3-1.1,0.4-1.3c0.5-0.9-0.1-1.8-0.8-2.3
c-0.2-0.1-0.4-0.2-0.6-0.2c-0.1,0-0.2,0-0.3,0.1c-0.3-0.2-0.6-0.3-0.9-0.5c-0.3-0.1-0.6-0.3-0.8-0.4c-0.3-0.3-0.5-0.6-0.5-0.6
c-0.1-0.5-0.6-0.8-1.1-0.8c-0.1,0-0.1,0-0.2,0H217c0-0.1,0-0.1-0.1-0.1l0.5-0.7c0.1-0.2,0.2-0.4,0.2-0.7V141c0-0.2,0-0.3,0.1-0.3
c0.4-0.4,0.6-1,0.5-1.5s-0.5-0.9-0.9-1.2c0,0,0.1-0.4,0.1-0.5c0.1-0.9,0.2-2-0.1-2.7c-0.1-0.3-0.3-0.6-0.4-0.8v-0.7
c1.8,0,2.7-0.9,3-1.6c0.1-0.3,0.6-1.3,0.9-1.5c0.1-0.1,0.2-0.1,0.3-0.2c0.4-0.3,1.1-0.8,1.1-1.9c0-0.3,0-0.5-0.1-0.7
c0-0.1,0-0.3,0-0.4c0.5-0.6,0.8-1.6,0.8-2.4c0-0.7-0.3-1.1-0.5-1.3c-0.2-0.2-0.5-0.5-0.7-0.6s-0.3-0.3-0.4-0.4v-0.1
c0.2-0.5,0.4-1.2,0.4-1.2c0.1-0.4,0-0.9-0.3-1.2l-0.7-0.6c0-0.1,0-0.3,0.1-0.4c0-0.3,0.1-0.4,0.1-0.4l0.1-0.1
c0.1-0.2,0.4-0.5,0.6-0.7c0.1,0,0.2,0,0.2,0.1c0.2,0.2,0.6,0.5,0.9,0.7c0.3,0.3,1.1,0.9,1.3,1c0.1,0.1,0.3,0.1,0.4,0.2
c0.1,0,0.2,0.1,0.3,0.1c0,0,0,0.1-0.1,0.1c-0.2,0.4-0.1,0.8,0.1,1.2c0.3,0.5,1,1,1.9,1c0.1,0,0.2,0,0.3,0c0.3-0.1,0.6-0.1,0.9-0.3
c0.3-0.1,0.6-0.2,0.8-0.2l0,0c0.6,0.3,1.1,0.5,1.7,0.5c0.3,0,0.6-0.1,0.9-0.1c0.8-0.3,1.4-1,1.6-1.7h0.3c0.1,0,0.2,0,0.3,0
c0,0.1,0,0.1,0,0.2v0.9c0,0.5,0.4,1,0.9,1.1c0.1,0,1.5,0.3,2.4,0.3c0.3,0,0.6,0,0.8,0c0.1,0.3,0.1,0.9,0.1,1.4s0.4,0.9,0.9,1.1
c0.2,0.7,0.3,1,0.4,1.2h-0.2c-0.3,0.1-0.6,0.2-0.8,0.5s-0.2,0.6-0.1,0.9c0.1,0.3,0.4,1.2,0.9,1.7c-0.5,1.6,0.2,2.6,0.7,3
c0,0.1-0.1,0.1-0.1,0.1c-0.2,0.3-0.3,0.6-0.3,0.9l0.1,0.8l-0.6,0.5c-0.4,0.3-0.5,0.9-0.3,1.4l0.7,1.4c0.1,0.3,0.5,0.5,0.8,0.6
c0.1,0,0.2,0,0.2,0c0.3,0,0.5-0.1,0.7-0.3c0.1-0.1,1-0.8,1.5-1.3c0.1,0,0.3-0.1,0.5-0.1c0.3,0,0.5,0.1,0.6,0.1
c0.2,0.1,0.3,0.3,0.5,0.4c0.4,0.3,0.9,0.7,1.6,0.7c0.1,0,0.2,0,0.3,0s0.3-0.1,0.4-0.1c0.2,0,0.4-0.1,0.5-0.1
c0.3,0.1,0.9,0.2,1.6,0.3l0.5,0.9c0.1,0.3,0.3,0.5,0.6,0.6l1.5,0.6c0.1,0.1,0.3,0.1,0.4,0.1c0.4,0,0.8-0.2,1-0.6l0.1-0.3
c0.5,0.2,0.9,0.4,1.1,0.6l-0.7,0.6c-0.1,0.1-0.1,0.1-0.1,0.1l-0.9,1.2c-0.4,0.5-0.3,1.1,0.1,1.6l0.9,0.9c0.2,0.2,0.4,0.3,0.6,0.3
c1.1,0.2,2.4,0.5,2.8,0.6c0.3,0.2,1.6,1.1,2.7,2l0.9,1.4c0.2,0.2,0.4,0.4,0.7,0.5l1.6,0.4c0.1,0,0.2,0,0.3,0c0.4,0,0.8-0.2,1-0.6
l0.3-0.5c0.3,0,0.6-0.1,0.8-0.1c0.2,0.3,1.2,1.5,1.7,2.1l-0.2,1c0,0.1,0,0.1,0,0.2v0.5l-0.9-0.3c-0.1,0-0.2-0.1-0.3-0.1
c-0.4,0-0.7,0.2-0.9,0.5l-0.6,0.9c0,0,0,0,0,0.1c-0.5,0-0.9,0.2-1.2,0.5c-0.1,0-0.3,0-0.5-0.1c-0.1,0-0.2,0-0.2,0
c-0.8,0-1.4,0.6-1.4,1.4c0,0.7,0.6,1.5,1.3,1.8l0.2,1c0,0.3,0,0.6,0,0.8c-0.1,0.2-0.1,0.7,0.3,1.8c-0.1-0.1-0.3-0.1-0.4-0.1
c-0.2,0-0.4,0.1-0.6,0.2l-1.4,0.8l-2.4-0.4c-0.1,0-0.1,0-0.2,0c-0.5,0-1,0.4-1.1,0.9v0.2l-0.6,0.1
C254.6,162.5,254.3,162.8,254.2,163.1z`
  },
  {
    id: "LamDong",
    name: "Lâm Đồng",
    d: `M295.3,588.6c-0.1-0.4-0.4-1.5-0.6-2.4c-0.1-0.4-0.4-0.7-0.7-0.8c-0.1,0-2.8-1-3.5-1.2
c-0.9-0.2-3.1-0.4-4.5-0.4s-4.3-0.1-4.4-0.1l0,0c-0.5,0-0.9,0.3-1.1,0.8c-0.1,0.3-0.3,0.7-0.4,0.9c-0.1,0.1-0.5,0.1-0.9,0.2
c-0.2-0.4-0.6-0.7-1-0.7s-0.9-0.1-1.1-0.1c-0.3-0.3-0.6-0.6-0.7-0.7c-0.2-0.4-0.7-0.7-1.3-0.7c-0.2,0-0.5,0.1-0.7,0.1
c-0.3,0.1-0.5,0.3-0.7,0.5c-0.3-0.3-0.6-0.6-0.9-0.8c0-0.1,0-0.1,0-0.2c0-0.2,0-0.4-0.1-0.6c-0.1-0.6-0.4-1.1-0.9-1.3
c0.1-0.1,0.1-0.3,0.1-0.3c0.1-0.2,0.1-0.5,0.1-0.8c-0.1-0.2-0.3-1-0.9-1.6c-0.4-0.4-1-0.9-1.7-0.9c-0.1,0-0.1,0-0.2,0
c-0.3-0.3-0.7-0.5-1.1-0.5c-0.1-0.3-0.2-0.5-0.3-0.7c-0.3-0.4-0.7-0.6-1.2-0.6c-0.3,0-0.5,0.1-0.7,0.2c-0.2-0.2-0.5-0.3-0.7-0.3l0,0
c0-0.1,0-0.3,0-0.4c0-0.7,0-1.6-0.9-2.1c-0.1-0.1-0.2-0.1-0.3-0.1c-0.1,0-0.1-0.1-0.2-0.1c0-0.1,0-0.1,0-0.1c0.5-0.6,0.7-1.6,0.7-2
c0-0.1,0.1-0.3,0.1-0.4c0.9-0.1,1.6-0.4,1.9-0.6c0.1-0.1,0.6-0.3,1-0.4l0.5,0.3c0.2,0.1,0.5,0.2,0.7,0.2c0.3,0,0.6-0.1,0.8-0.4
c0.1,0,0.3,0.1,0.5,0.1c1,0,1.8-0.7,2.1-1.4c0.1-0.1,0.2-0.3,0.2-0.3c0.7,0.1,2,0.3,2,0.3c0.1,0,0.1,0,0.1,0c0.4,0,0.8-0.2,1-0.6
c0.2-0.5,0.6-1,0.8-1.2c1.1-0.4,1.8-1,2-1.7c0.1-0.1,0.3-0.1,0.5-0.2c0.6-0.1,1-0.7,1.2-1.2c0,1,0.5,1.4,1,1.5
c0.2,0.1,0.9,0.3,1.4,0.6c0.1,0.1,0.3,0.1,0.5,0.1s0.4-0.1,0.5-0.1c0.3-0.1,0.7-0.3,0.8-0.3c0.5,0,0.9-0.2,1.2-0.3l0,0
c0.1,0,0.3,0,0.5,0s0.4,0,0.6,0c0,0,0,0,0.1,0c-0.3,0.6-0.3,1.2,0,1.8s1.1,1.1,1.7,1.4c0.2,0.1,0.3,0.1,0.5,0.1s0.3,0,0.5-0.1
c0.3-0.1,0.5-0.4,0.6-0.8l0,0c0.1,0,0.2,0,0.3-0.1l0.4,0.4c0.2,0.2,0.5,0.3,0.8,0.3c0.1,0,0.1,0,0.2,0l1.4-0.2
c0.1,0,0.2-0.1,0.3-0.1c0.3-0.2,1.1-0.6,1.5-1.1c0.1,0,0.4-0.1,0.6-0.1c0.4,0,0.7-0.2,0.9-0.5c0-0.1,0.9-1.2,1.3-1.8
c0.3-0.4,0.7-1.5,0.3-2.2c-0.2-0.3-0.5-0.6-0.9-0.6v-0.1l0,0c0-0.3,0.1-0.9-0.4-1.5c-0.2-0.2-0.5-0.5-1.1-0.5
c-0.1-0.4-0.3-0.9-0.8-1.3c-0.2-0.2-0.3-0.6-0.4-0.9c0-0.1-0.1-0.3-0.1-0.5c-0.2-0.5-0.7-1.1-1.4-1.4c0.1-0.3,0.2-0.6,0.3-1
c0.1-0.1,0.3-0.3,0.5-0.5c0.2,0,0.5,0.1,0.6,0.1c0.3,0.1,0.5,0.2,0.8,0.2c0.5,0,0.9-0.3,1.1-0.4l0.1-0.1c0.1,0,0.1,0,0.1,0
c0.9,0,1.9-0.5,2.1-1.8l0,0c0.2-0.1,0.4-0.2,0.5-0.3c0.1,0.1,0.1,0.1,0.1,0.1c0.3,0.3,0.7,0.7,1.4,0.7c0.2,0,0.4,0,0.6-0.1
c0,0,0,0,0.1,0l0,0c0.3,0.3,0.8,0.5,1.3,0.5c0.9,0,1.7-0.7,1.8-1.6l0,0c0,0,0.1-0.4,0.3-0.4c0.8-0.1,1.4-0.3,1.9-0.7
c0.2-0.1,0.7-0.5,1.1-1.1c0.3,0.2,0.7,0.4,1.2,0.4c0.3,0,0.6-0.1,0.9-0.1h0.2c1.2-0.3,1.7-0.8,2-1.5c0.1-0.1,0.4-0.4,0.6-0.6
c0.3,0.3,0.8,0.6,1.6,0.6c0.1,0,0.1,0,0.2,0s0.1,0,0.1,0c0.6,0,1-0.3,1.2-0.5c0.6,0.2,1.1,0.3,1.1,0.3c0.1,0,0.2,0,0.3,0
c0.1,0,0.2,0,0.3,0c0,0,1.3-0.3,1.6-0.4c0.3-0.1,0.8-0.3,1.6-0.7c0,0.3,0,0.5,0,0.7c0,0.1,0,0.3,0,0.3l0,0c-0.5,1.4,0.1,2,0.6,2.2
v0.1c-0.1,0.3-0.2,0.7-0.1,1.2l-0.2,0.1c-0.2,0.1-0.3,0.2-0.4,0.3l-0.9,1.4c-0.2,0.3-0.3,0.5-0.2,0.9c0.1,0.3,0.2,0.6,0.5,0.7
l0.9,0.7c0,0.2,0.1,0.3,0.1,0.5c-0.9,0.7-1.1,2.4-1.1,2.6c0,0.1-0.1,0.2-0.1,0.3c-0.8,0.6-0.7,1.6-0.7,2.1c0,0.3-0.1,0.9-0.1,1.2
c0,0.1-0.1,0.5-0.1,0.7c-0.2,0.8-0.3,1.4-0.3,1.8c-0.1,0.1-0.2,0.2-0.2,0.2c-0.1,0.1-0.1,0.1-0.2,0.2c-0.4,0.4-0.8,0.9-0.8,1.6
c0,0.5,0.3,1,0.7,1.6c0.2,0.2,0.4,0.3,0.7,0.4l0.4,0.1l0.1,1c0.1,0.5,0.5,1,1,1l0,0l0.1,0.4l-0.4,0.5c-0.5,0-1.2,0.3-2.2,0.8l-2,0.3
c-0.4,0.1-0.7,0.3-0.9,0.6l-0.4,0.8c-0.1,0.2-0.1,0.3-0.1,0.5v1.2c0,0.2,0.1,0.4,0.2,0.6l0.7,1.2l-0.3,0.6c-0.2,0-0.3-0.1-0.3-0.1
c-0.4-0.3-0.9-0.5-1.4-0.5c-0.6,0-1.1,0.2-1.4,0.4c-0.1,0-0.3,0-0.5,0c-0.1,0-0.2,0-0.3,0c-0.5-0.3-1-0.3-1.4-0.3
c-0.1,0-0.2,0-0.3,0c-0.1,0-0.2,0-0.3,0c-0.4,0-0.8,0.1-1.1,0.3c-0.1-0.1-0.3-0.2-0.4-0.3s-1-0.4-1.7-0.4c-0.4,0-0.8,0.1-1.1,0.3
c-0.1,0.1-0.2,0.1-0.3,0.2c-0.7,0.5-1.5,1-1.7,1.8c-0.1,0.1-0.2,0.2-0.3,0.3c-0.3,0.2-0.5,0.5-0.5,0.9s0.1,1.1,0.4,1.6
c0.2,0.4,0.7,0.8,1.2,0.9c-0.3,0.3-0.5,0.6-0.6,0.7c-0.1,0.1-0.4,0.5-1.1,0.9c-0.7,0.5-2.1,1.5-2.4,1.7c-0.3,0.1-0.7,0.3-0.8,0.3
c-0.8,0-1.7,0.5-2,0.7c-0.1,0-0.1,0.1-0.2,0.1C296.3,587.8,295.7,588.3,295.3,588.6z`
  },
  {
    id: "LaoCai",
    name: "Lào Cai",
    d: `M145.3,139.7c-0.1-0.1-0.3-0.5-0.4-0.7c-0.1-0.3-0.5-0.6-0.8-0.6c-0.1,0-0.4-0.1-0.6-0.2
c-0.1-0.1-0.3-0.2-0.4-0.4c-0.2-0.3-0.5-0.4-0.9-0.4c-0.2,0-0.5,0.1-0.7,0.2c-0.1,0-0.1,0.1-0.2,0.1h-0.1c-0.5-0.4-1.4-0.6-1.6-0.7
c-0.1,0-0.2,0-0.3,0c-0.4,0-0.9,0.3-1.1,0.7l-0.2,0.4c-0.2,0.5-0.1,1,0.3,1.3c-0.5,0-1.1-0.1-1.4-0.1c-0.3-0.1-0.5-0.3-0.6-0.4
c-0.1-0.1-0.4-0.3-1.6-0.6c-0.1,0-0.2,0-0.3,0c-0.3,0-0.7,0.2-0.9,0.5c-0.3-0.1-0.6-0.2-0.8-0.3c-0.3-0.1-0.7-0.2-1.1-0.2
c0.1-0.3,0-0.6-0.2-0.9l-0.4-0.5c-0.2-0.5-0.4-0.9-0.7-1.1c0.1-0.3,0-0.7-0.3-1l-0.1-0.1c0-0.2,0-0.4,0-0.6c0-0.4,0-1-0.5-1.6
c0.3-0.8,0-1.6-0.4-2.1v-0.1c0.1-0.3-0.1-0.7-0.3-0.9s-0.5-0.4-0.9-0.4h-0.1c-0.1-0.1-0.2-0.2-0.3-0.3c-0.3-0.3-0.6-0.5-0.9-0.7
c0-0.2,0-0.4-0.1-0.6c-0.1-0.4-0.4-0.7-0.8-0.8c-0.1-0.1-0.1-0.2-0.3-0.4c0-0.9-0.5-1.3-0.8-1.6c-0.1-0.1-0.2-0.4-0.3-0.5
c-0.3-0.5-0.5-1-1-1.2c-0.2-0.1-0.4-0.2-0.7-0.3c0.1-0.1,0.2-0.3,0.2-0.4c0.1-0.5,0.1-1.2-0.4-1.7c0-0.1,0-0.2,0-0.3
c0-0.7-0.5-1.2-1-1.7c-0.1-0.1-0.3-0.3-0.3-0.3c-0.3-0.4-0.8-0.6-1.3-0.6c-0.4,0-0.8,0.2-1,0.3c-0.3,0.1-1.4,0.2-1.8,0.2
s-0.8,0.1-1.1,0.2l-0.1-0.1c0,0,0,0,0-0.1c-0.1-0.5-0.5-0.7-0.7-1c-0.1-0.1-0.1-0.1-0.2-0.2s-0.3-0.3-0.4-0.5
c0.2-0.1,0.4-0.3,0.5-0.5l0.6-1.5c0.1-0.3,0.1-0.6,0-0.9s-0.4-0.5-0.7-0.6l0,0c-0.1-0.3-0.1-0.6-0.2-0.7c0-0.7-0.5-1.2-1.1-1.5
c0.1-0.1,0.1-0.1,0.1-0.2h0.1c0.4-0.1,0.7-0.4,0.8-0.8l0.3-1.1c0.1-0.4,0-0.8-0.3-1.1c-0.1-0.1-0.3-0.3-0.3-0.4s0.1-0.3,0.2-0.4
c0.3-0.3,0.7-0.7,1.2-1.5c0.1-0.1,0.1-0.1,0.1-0.2c0.1-0.1,0.1-0.2,0.3-0.5c0-0.1,0.1-0.2,0.2-0.3h0.1c0.3,0.4,0.7,0.8,0.8,1
c0.2,0.2,0.6,0.5,0.9,0.6l1,1c0.2,0.5,0.7,1.4,1.7,1.5l0,0l0.1,0.1c0.3,0.3,1.2,1.5,1.4,1.8c0.1,0.2,0.3,0.5,1.2,1.5
c0.4,0.4,0.8,0.9,0.9,1c0.3,0.6,0.8,0.9,1.4,0.9c0.1,0,0.1,0,0.2,0c0.3,0.4,0.7,0.8,1.3,1.2c0,0,0.1,0.1,0.2,0.1c0.7,0.6,1.4,1,2,1
c0.2,0,0.3,0,0.5-0.1c0.3-0.1,1.2-0.4,1.5-0.5s0.6-0.3,0.7-0.7c0.1-0.3,0.1-0.7,0-0.9l0,0c0.2-0.5,0.2-0.9,0.2-1.2v-0.1
c0-0.1,0-0.3-0.1-0.5c0-0.1,0-0.2,0-0.3c0.3-0.7,0.4-1.7,0.4-1.7v0.1c0-0.2,0.2-1,0.4-1.7c0-0.1,0-0.1,0-0.1l0.1-0.8
c0-0.3,0-0.7-0.2-0.9c0-0.1,0-0.1,0.1-0.2c0.1-0.2,0.2-0.5,0.3-0.7c0.5-0.3,1.2-1,1.6-1.4c0.1-0.1,0.2-0.2,0.2-0.3
c0.1-0.4,0.3-0.8,0.4-0.9v-0.1c0.1,0,0.2,0,0.4,0c0.7,0,1.5-0.3,1.9-0.6l0,0c0.1,0,0.2,0,0.3,0c0.6-0.1,1.2-0.1,1.6-0.3
c-0.1,0.3-0.1,0.5-0.2,0.7c-0.1,0.3-0.1,0.5,0.1,0.7c0,0,0.2,0.6,0.3,0.8c0.1,0.1,0.1,0.5,0.2,0.6c0.1,0.3,0.3,0.6,0.6,0.7
c0.1,0.1,1,0.5,2,0.5c0.1,0,0.2,0,0.2,0v0.1c0,1,0.5,2.2,1.8,2.5c0.3,0.1,0.7,0.1,1,0.1c0.1,0,0.2,0,0.3,0l0,0
c0.1,0.3,0.4,0.9,0.4,1c0.1,0.3,0.4,0.5,0.7,0.6l0.6,0.2c-0.3,0.1-0.7,0.3-0.8,0.7l-0.6,1.5c-0.1,0.3-0.1,0.7,0.1,1
c0.1,0.2,0.4,0.4,0.6,0.5c0.2,0.5,0.6,0.9,1,1.2c-0.2,0.3-0.3,0.5-0.3,0.6c-0.1,0.3-0.1,0.6,0.1,0.9l0.8,1.4
c0.1,0.2,0.3,0.4,0.6,0.5c0.4,0.1,0.8,0.3,0.9,0.4c0.2,0.2,0.8,0.5,1.6,1c-0.1,0.7,0.1,1.2,0.3,1.4c0.2,0.2,0.4,0.4,0.7,0.5
c0,0.1,0,0.1,0.1,0.2l0,0l-0.8,0.6c-0.4,0.3-0.5,0.7-0.5,1.1l0.2,1.4c0.1,0.4,0.3,0.7,0.6,0.9l0.8,0.4c0.1,0.1,0.2,0.1,0.3,0.1
c0.1,0.2,0.2,0.4,0.4,0.6c-0.4,0.1-0.7,0.5-0.7,0.9l-0.2,1.6v0.2c-0.2-0.2-0.5-0.6-1.4-1.1c-0.2-0.2-0.9-0.7-1.2-1
c0-0.1-0.1-0.3-0.1-0.3c0-0.6-0.5-1.1-1.2-1.1h-0.3l-0.1-0.1c-0.3-0.3-0.6-0.4-1-0.4c-0.5,0-0.9,0.3-1.1,0.4c0,0,0,0-0.1,0.1l-1,0.9
c-0.4,0.3-0.5,0.9-0.3,1.4l0.4,0.7l0.2,0.9l-0.1,0.1c-1,0.4-1.2,1.1-1.2,1.4c-0.1,0.7,0.3,1.2,0.8,1.5l0.4,0.4l0.6,1l0.4,1
c0.1,0.4,0.2,1.1,0.2,1.4c0,0.1,0,0.3,0,0.4c-0.1,0.1-0.2,0.1-0.3,0.2c-0.7,0.1-1.1,0.7-1.3,1.4L145.3,139.7z`
  },
  {
    id: "LaiChau",
    name: "Lai Châu",
    d: `M124,147.7C124,147.6,123.9,147.6,124,147.7c-0.2-0.9-0.9-1.6-1.8-1.6l0,0l-0.7-1.1
c-0.1-0.2-0.3-0.3-0.5-0.4c0.1-0.3,0.1-0.7-0.1-1s-0.6-0.5-1-0.5h-0.1c0,0-0.5,0.1-0.9,0.1c0.1-0.1,0.1-0.3,0.1-0.3v-0.1
c0.3-0.5,0.5-1.1,0.3-1.7c-0.1-0.3-0.2-0.5-0.4-0.6c-0.1-0.4-0.3-0.7-0.3-0.9c-0.1-0.2-0.3-0.3-0.4-0.4c0-0.3-0.1-0.6-0.2-0.9
c-0.2-0.3-0.5-0.5-0.9-0.7c0-0.2-0.1-0.4-0.1-0.5c0-1.1-1.2-1.5-2-1.7c-0.1-0.1-0.4-0.3-0.6-0.5s-0.5-0.4-0.7-0.4
c0,0-0.8-0.1-1.4-0.1c-0.1,0-0.2-0.1-0.3-0.1c-0.2-0.2-0.5-0.3-0.7-0.3c-0.3,0-0.5,0.1-0.7,0.3c-0.1,0.1-0.4,0.3-0.5,0.3
c-0.2,0.1-0.4,0.2-0.6,0.4c0-0.3-0.1-0.6-0.4-1.1c-0.1-0.1-0.1-0.2-0.1-0.2c-0.1-0.2-0.4-0.7-1.1-0.9c-0.1-0.1-0.2-0.3-0.3-0.4
c-0.1,0-0.1-0.1-0.2-0.1c-0.1-0.4-0.1-0.5-0.1-0.5c0-0.4-0.2-1.4-0.7-1.9c0-0.5-0.4-1.3-1.6-1.3c-0.1,0-0.2,0-0.2,0
c-0.2,0-0.4,0-0.7,0.1c-0.2,0-0.5,0.1-0.7,0.1c-0.8,0-1.2,0.4-1.4,0.5c-0.4,0.3-0.8,0.8-1,1.4c-0.3,0.1-0.5,0.2-0.7,0.4
c-0.4,0.4-0.4,1-0.4,1.2l0,0c-0.3,0.2-0.4,0.5-0.5,0.7c-0.4-0.3-0.9-0.4-1.3-0.5c-0.1,0-0.3-0.1-0.3-0.1c-0.5-0.2-0.9-0.3-1.4-0.3
c-0.3,0-0.5,0-0.7,0.1c-0.3-0.7-1.2-1.1-1.6-1.2c-0.1,0-0.2-0.1-0.3-0.1c-0.5,0-0.9,0.3-1.1,0.7c0,0-0.2,0.5-0.3,0.8v0.1h-0.8
c-0.2,0-0.3,0.1-0.5,0.1c-0.1,0-0.2,0.1-0.4,0.2c-0.2-0.1-0.4-0.1-0.6-0.1c-0.3,0-0.6,0.1-0.7,0.2l0,0c-0.4,0.1-0.7,0.2-0.9,0.4
c-0.2-0.2-0.4-0.4-0.6-0.6c0.1-0.2,0.1-0.4,0.1-0.5c0-0.3-0.1-0.6-0.4-0.9c-0.2-0.2-0.5-0.3-0.7-0.3c-0.1,0-0.1,0-0.1,0l-1.2,0.1
c-0.3,0-0.5,0.2-0.7,0.3c-0.1,0.1-0.1,0.1-0.2,0.2c-0.1,0-0.2,0-0.3,0c-0.5,0-0.9,0.3-1.2,0.6c-0.1,0-0.2,0-0.3,0
c-0.1,0-0.3,0-0.3,0c-0.3-0.2-0.6-0.4-0.8-0.5c0-0.1-0.1-0.3-0.2-0.4l-0.5-0.7c-0.1-0.1-0.2-0.3-0.3-0.3l-0.3-0.2
c-0.1-0.1-0.2-0.2-0.3-0.3c0-0.1,0-0.2,0-0.3c0.2-0.2,0.4-0.5,0.4-0.8l0.1-1.5c0-0.2,0-0.4-0.1-0.6c-0.2-0.4-0.6-1-0.9-1.3
c-0.4-0.3-0.9-0.5-1.6-0.5c-0.2-0.1-0.5-0.4-0.8-0.7c-0.2-0.2-0.5-0.3-0.8-0.3c-0.1,0-0.1,0-0.1,0c-0.3,0-0.7,0.3-0.9,0.6l-0.3,0.6
c-0.1,0.2-0.1,0.3-0.1,0.5l-0.2-0.2c-0.1-0.2-0.3-0.3-0.6-0.4l0,0c0,0-0.3-0.1-0.6-0.4c-0.1-0.1-0.6-0.6-1.3-0.6
c-0.5,0-0.9,0.3-1.1,0.5c-0.1-0.1-0.3-0.2-0.5-0.3l-0.7-1.2c0,0,0,0,0-0.1c-0.2-0.3-0.6-0.9-0.7-1.2c-0.1-0.1-0.2-0.2-0.3-0.3
c-0.1-0.1-0.3-0.3-0.4-0.5c-0.2-0.7-0.7-1.2-1.2-1.5c-0.1-0.1-0.2-0.2-0.3-0.2c-0.2-0.2-0.5-0.5-0.8-0.6l0,0
c0.2-0.5,0.2-0.9-0.1-1.4c0-0.1,0-0.4,0-0.6c0-0.5-0.2-0.9-0.6-1.1l-0.8-0.4v-0.1c-0.1-0.1-0.1-0.2-0.2-0.3
c-0.1-0.1-0.2-0.2-0.2-0.3s0-0.4,0-0.7c0-0.3-0.1-0.5-0.2-0.7c0.3-0.1,0.5-0.4,0.5-0.7c0-0.1,0.1-0.2,0.1-0.3
c0.4-0.2,0.7-0.5,0.8-0.7l0.1-0.1c0.2-0.2,0.3-0.4,0.5-0.6c0,0,0,0,0-0.1c0.2,0,0.3,0,0.3,0c0.5-0.1,0.9-0.4,1-0.9s-0.1-0.9-0.4-1.2
l-0.4-0.3c0.4-0.1,0.7-0.3,0.9-0.7c0.3-0.3,0.3-0.7,0.4-1c0.2-0.1,0.4-0.2,0.4-0.2c0.3-0.2,0.5-0.4,0.5-0.7c0.1-0.3,0-0.6-0.2-0.9
c0.2-0.1,0.4-0.2,0.6-0.3c0.1,0.1,0.1,0.1,0.3,0.2c0.1,0.1,0.2,0.1,0.3,0.2c0.1,0.1,0.3,0.2,0.4,0.3c0.1,0.3,0.5,1.1,0.7,1.6
c0.2,0.4,0.6,0.7,1.1,0.7h0.1l0.9-0.1c0.4,0,0.7-0.2,0.9-0.5c0.6,0.5,1.2,1.1,2,1.1c0.3,0,0.6,0,0.8-0.1c0.2,0.3,0.5,0.5,0.8,0.6
c0.1,0,0.1,0.1,0.2,0.1c0.3,0.2,0.7,0.5,1.3,0.5c0.2,0.3,0.6,0.6,1,0.7c0,0.1,0.1,0.1,0.1,0.2c0.2,0.3,0.6,0.7,1.5,0.7
c0,0,0,0,0.1,0s0,0,0.1,0h0.1c0.3,0,0.5,0,0.6,0.1c0.1,0,0.1,0.1,0.2,0.1c0.2,0.1,0.4,0.3,0.8,0.5c0,0.4,0.1,0.9,0.4,1.2
c0,0,0.1,0.1,0.1,0.2c-0.1,0.1-0.1,0.3-0.1,0.5c0,0.4,0.2,0.7,0.5,0.9l0.7,0.6l0.3,0.3l0.1,0.6c0.1,0.3,0.2,0.6,0.5,0.8
c0.2,0.1,0.4,0.2,0.6,0.2c0.1,0,0.2,0,0.3,0l0.4-0.1c0.3,0.2,0.6,0.3,1,0.3l0,0c0.3,0.6,1.1,1.6,2.1,1.6c0.4,0,0.9-0.1,1.3-0.2
c0.3,0.1,0.5,0.2,0.9,0.2s0.8-0.2,1.1-0.5c0.5-0.6,0.3-1.3,0.1-1.9c0-0.1-0.1-0.3-0.1-0.4c0.5-0.1,1-0.3,1.6-0.7l0,0
c0.1-0.1,0.2-0.1,0.3-0.2c0.1-0.1,0.3-0.3,0.4-0.3c0.9-0.1,1.5-0.6,1.8-1s0.3-0.9,0-1.3l-0.7-0.9c0.3-0.4,0.6-1,0.8-1.2l0.7,0.1h0.1
c0.3,0,0.6-0.1,0.8-0.3l0.8-0.7l0.1-0.1l0.1-0.1c0.7-0.1,1.6-0.5,1.7-1.3c0.1-0.4,0.3-1.3,0.3-1.3c0.1-0.2,0-0.5-0.1-0.7
c0.7-0.6,1.2-1.5,1.3-1.8c0-0.1,0.1-0.2,0.1-0.3c0.1,0.1,0.2,0.1,0.4,0.2c0.3,0.2,0.7,0.5,1.2,0.6l0,0c0.1,0.2,0.2,0.4,0.3,0.5
c0.1,0.1,0.2,0.2,0.3,0.3l-0.1,0.2c-0.4,0.5-0.3,1.1,0.1,1.5l1.1,1.1l0,0c0,0.3-0.1,1.2,0.6,1.8c0.3,0.3,0.7,0.8,0.9,1
c0.1,0.2,0.3,0.3,0.5,0.3l0.3,0.1c0.1,0.2,0.3,0.4,0.5,0.6l0.5,0.2c0.4,0.4,0.9,0.7,1.4,0.7c0.1,0.3,0.2,0.8,0.3,1.4
c0.1,0.2,0.1,0.3,0.3,0.4c-0.5,0.2-0.8,0.5-0.9,0.9c0,0.1-0.1,0.2-0.2,0.3c-0.3,0.5-0.3,1,0.1,1.4s0.9,0.9,1,1.1
c0.2,0.2,0.3,0.4,0.5,0.6c0.1,0,0.1,0.1,0.1,0.1c0.1,0.4,0.3,0.7,0.4,0.7c0.1,0.2,0.3,0.3,0.5,0.4l0.9,0.4c0.1,0.1,0.3,0.1,0.5,0.1
c0.1,0,0.3,0,0.5-0.1s0.5-0.2,0.7-0.2c0.4,0,2-0.1,2.5-0.4c0.1,0.1,0.1,0.1,0.2,0.2s0.3,0.3,0.3,0.4c0,0.3,0.1,0.7,0.3,1.1
c-0.1,0.1-0.2,0.3-0.3,0.5l-0.1,0.7c-0.1,0.4,0.1,0.9,0.4,1.2l0.6,0.4c0.1,0.1,0.2,0.1,0.3,0.1c0.2,0.1,0.6,0.2,0.8,0.3
c0.1,0.1,0.2,0.3,0.2,0.4c0.3,0.5,0.5,0.9,0.9,1.2c0,0.3,0.1,1,0.6,1.4c0.1,0.1,0.2,0.3,0.4,0.5c-0.1,0.3,0,0.6,0.1,0.7
c0.1,0.3,0.4,0.7,1.1,0.9c0.2,0,0.4,0.2,0.6,0.4c0.3,0.3,0.7,0.7,0.7,0.7c0.1,0.1,0.2,0.2,0.3,0.2c0.1,0.2,0.2,0.4,0.3,0.6
c0,0,0,0,0,0.1c-0.4,0.9-0.1,1.7,0.4,2.1c0,0,0,0.1,0,0.2c0,0.2-0.1,0.5-0.1,0.5c-0.1,0.3-0.1,0.7,0.2,1c-0.1,0.3,0,0.6,0.1,0.9
c0.2,0.3,0.3,0.5,0.5,0.7c-0.1,0.2-0.3,0.4-0.4,0.8c-0.3-0.2-0.5-0.2-0.7-0.2c-0.5,0-0.9,0.3-1.1,0.5c-0.4,0.1-0.7,0.2-0.9,0.5
c-0.5,0.5-0.4,1.2-0.4,1.4c0,0.1,0,0.7,0.1,1.6c0,0.3,0.1,0.5,0.3,0.7l1.3,1.5c0.1,0.2,0.6,0.8,0.9,1.1c0.1,0.1,0.2,0.3,0.3,0.4
c0.1,0.1,0.1,0.3,0.2,0.4c-0.1,0.1-0.1,0.2-0.1,0.3c-0.8,0.2-1.2,0.4-1.4,0.8c-0.1,0.1-0.1,0.3-0.2,0.5c0,0.1-0.1,0.1-0.1,0.2
c-0.1,0-0.2,0-0.2,0c-0.2,0-0.4,0.1-0.5,0.1C124.1,147.6,124.1,147.7,124,147.7z`
  },
  {
    id: "KonTum",
    name: "Kon Tum",
    d: `M313.8,449.1c-0.1,0.1-0.2,0.3-0.3,0.5l-0.1,0.2l-0.5,0.2l-1.8-0.6c-0.1,0-0.1-0.1-0.1-0.2
c-0.1-1-0.9-1.6-1.9-1.6c-0.6,0-1.3,0.2-1.8,0.6c-0.6,0.4-0.9,1.1-0.9,1.8c0,0.5,0.2,1,0.6,1.3c-0.1,0.4-0.1,0.7,0.1,1.1
c-1.1,1.5-2.2,2.9-2.4,3.1c-0.3,0.3-0.5,0.7-0.6,1h-1.2c-0.5,0-0.9,0-1.2,0.1c-0.7,0.1-1.1,0.6-1.4,0.9c-0.1,0.1-0.1,0.1-0.2,0.2
c-0.2,0.1-0.4,0.1-0.6,0.2c-0.1,0-0.2,0.1-0.3,0.1c-0.3-0.1-0.6-0.2-0.9-0.2c-0.7,0-1.2,0.4-1.3,0.5c-0.1,0.1-0.2,0.2-0.3,0.3
c0,0-0.3,0.5-0.7,0.9s-0.8,1.2-1.1,1.9c-0.1,0-0.2,0-0.2,0c-0.4,0-0.7,0.2-0.9,0.5l-0.6,0.9c-0.2-0.1-0.3-0.1-0.5-0.1
s-0.5,0.1-0.7,0.2c0,0-0.3,0.2-0.7,0.3c-0.1-0.1-0.1-0.2-0.2-0.3c-0.4-0.4-0.9-1.1-0.9-1.1c-0.2-0.2-0.4-0.3-0.7-0.4l-1.4-0.2
c-0.1,0-0.1,0-0.2,0c-0.1,0-0.2,0-0.3,0.1l-1.6,0.5c-0.6,0.2-0.9,0.7-0.8,1.3c0.1,0.3,0.1,0.6,0.1,0.8c-0.1,0-0.1,0.1-0.2,0.1
c-0.3-0.1-0.5-0.1-0.7-0.1c-0.7,0-1.2,0.3-1.5,0.6c-0.1-0.1-0.4-0.1-0.5-0.2c-0.4-0.1-0.8-0.3-1.3-0.4c-0.1,0-0.1,0-0.2,0
c-0.5,0-1.8,0.2-2,2.1c-0.3,0.1-0.5,0.2-0.6,0.4c-0.2,0.3-0.2,0.5-0.2,0.9l0.2,1.4c-0.1,0.4-0.3,1.4-0.3,2c-0.1,0-0.1,0.1-0.2,0.1
h-0.3c-1.2,0-1.6,0.9-1.8,1.4h-1.3c-0.8,0-1.2,0.3-1.5,0.6c-0.4,0.4-0.4,0.9-0.4,1.3c-0.2,0.1-0.4,0.3-0.5,0.4
c-0.1-0.4-0.2-0.4-0.3-0.3c-0.1-0.2-0.2-0.4-0.3-0.5l-0.8-0.7c-0.2-0.2-0.5-0.3-0.7-0.3c-0.1,0-0.1,0-0.1,0l-1.4,0.2l-0.2-0.3v-1.1
c0.1-0.1,0.3-0.3,0.4-0.5c0.3-0.3,0.4-0.7,0.3-1.1c-0.1-0.4-0.4-0.7-0.8-0.8l-0.9-0.3l0.1-0.6l0.9-1.5c0.3-0.6,1-1.6,1.4-2.1
c1.2-1.2,0.5-3.4-0.1-4.5c0.1-0.1,0.2-0.2,0.2-0.3c0.2-0.2,0.3-0.4,0.5-0.6c0.4-0.6,0.5-1.5,0.4-2.2l0.7,0.7
c0.2,0.2,0.5,0.3,0.8,0.3c0.1,0,0.2,0,0.3-0.1c0.4-0.1,0.7-0.5,0.8-0.9l0.8-4.3c1.2-2.6,0.7-3.2,0.5-3.5c0,0,0,0,0-0.1
c1-0.5,1.1-1.7,1.1-2.4c0-0.2,0-0.5,0.1-0.5c0.1-0.2,0.3-0.7,0.2-2.6c0-0.4-0.1-0.7-0.1-0.7c0-0.5-0.3-0.8-0.8-1
c0.1-0.4,0.2-0.7,0.1-1.1c-0.1-0.4-0.4-0.7-0.6-0.9c0.5-0.4,1.8-1.4,2.5-1.9c0.3-0.3,0.5-0.7,0.5-1.1l-0.2-1.2
c-0.1-0.4-0.4-0.8-0.8-0.9c0,0-0.9-0.3-1.6-0.5c-0.3-0.1-1.1-0.7-1.9-1.4c-0.2-0.5-0.5-1.1-0.5-1.3c0-0.1,0-0.1,0-0.2
c0.7-0.1,1.4-0.4,1.8-0.7l1.3,0.7c0.2,0.1,0.4,0.1,0.5,0.1c0.1,0,0.3,0,0.5-0.1c0.3-0.1,0.6-0.4,0.7-0.7l0.1-0.3h0.1
c0.4,0,0.7-0.3,0.9-0.6c0.2-0.3,0.2-0.7,0-1.1l-0.3-0.7l0.4-0.3c0.4-0.4,0.5-1,0.2-1.5c0.3-0.2,0.5-0.6,0.5-0.9
c0-0.2-0.1-0.5-0.3-0.7l-1-1.3l0.6-0.4c0.5-0.3,0.7-0.8,0.5-1.3c-0.1-0.3-0.4-1.2-0.5-1.5c0-0.2,0.1-0.7,0.3-0.9
c0.2-0.3,0.4-0.7,0.4-1l0.9,1.2c0.2,0.3,0.5,0.4,0.8,0.4l0.2,1.9c0.1,0.4,0.3,0.8,0.7,0.9l1.6,0.6c0.1,0,0.1,0,0.1,0.1l1.9,0.4
c0.1,0,0.1,0,0.2,0h0.1c0,0,0.2,0,0.5,0c0.4,0,0.6,0,0.7,0.1c0.3,0.3,0.8,0.6,1.5,0.6c0.3,0,0.7-0.1,1-0.2l0.2-0.1
c0.5,0.7,1.2,1.2,1.7,1.4c0.2,0.1,0.5,0.6,0.4,0.7c-0.1,0.3-0.2,0.6-0.3,0.8c-0.1,0.2-0.1,0.4-0.2,0.5c-0.6,0.2-1,0.7-1.2,1.4
c-0.2,0.7-0.1,1.4,0.3,1.9c0.1,0.1,0.1,0.2,0.2,0.3c0.3,0.4,0.7,1,1.5,1.3c0.5,0.1,1.6,0.4,2.2,0.5l0.5,0.7c0.2,0.3,0.6,0.5,0.9,0.5
c0.1,0,0.3,0,0.4,0.1c0.3,0.3,0.8,0.5,1.4,0.5c0.7,0,1.8-0.2,2.3-0.5c0.8-0.5,1.4-1.6,0.8-2.5c-0.1-0.1-0.2-0.6-0.2-0.9
c0.6,0.6,1.2,0.9,1.7,0.9h0.1c0.3,0.6,0.8,1.4,1.2,1.8c0.3,0.4,0.7,0.6,1,0.9c0.1,0.1,0.3,0.2,0.4,0.3c0,0.1,0,0.1,0,0.2
c0.1,0.4,0.2,1.4,1.2,1.8c0.1,0.1,0.3,0.4,0.4,0.7c0.1,0.3,0.5,0.6,0.8,0.7c0.2,0.8,0.8,1.8,1.6,2.2c0.5,0.2,1.2,0.5,1.8,0.7
l0.2,0.4c0.2,0.4,0.6,0.7,1.1,0.7c0.1,0,0.2,0,0.3-0.1l0.6-0.2c0.1,0.3,0.5,0.6,0.8,0.7c0,0.1,0,0.2-0.1,0.3c-0.5,0.4-1,1.1-1.1,1.7
c-0.1,0.4,0,0.7,0.2,1c-0.4,0.4-0.7,0.7-0.7,0.7c-0.2,0.2-0.3,0.5-0.3,0.8v0.9c0,0.3,0.1,0.6,0.3,0.8s0.5,0.3,0.8,0.3l1.1-0.1`
  },
  {
    id: "KienGiang",
    name: "Kiên Giang",
    d: `M172,660.3c-0.2-0.1-0.4-0.2-0.6-0.2c-0.1,0-0.2,0-0.3,0.1c-0.3,0.1-0.5,0.2-0.6,0.4l-0.1-0.1
c-0.2-0.1-0.4-0.1-0.6-0.1c-0.3,0-0.6,0.1-0.8,0.3l-0.2-0.3c-0.2-0.2-0.5-0.4-0.8-0.4c-0.1,0-0.1,0-0.1,0c-0.4,0.1-0.7,0.3-0.9,0.6
l-0.7,1.2v-0.3l1.5-8.1l0.5-1.5l0.5-0.9l1.8-0.7c0.2-0.1,0.3-0.2,0.5-0.3l0.3-0.4c0.1-0.1,0.1-0.2,0.2-0.3l0.2-0.5l0.4-0.5l2.1-1.6
l0.9,2.5c0.2,0.5,0.6,0.7,1.1,0.7c0.1,0,0.1,0,0.2,0c0.5-0.1,0.9-0.6,0.9-1.2v-0.6V648l-0.2-1.5v-1.8c0-0.1,0-0.2,0-0.3l-0.3-0.9
c0-0.1-0.1-0.2-0.1-0.3l-0.5-0.9l-0.1-0.1l-1.8-2.5c0-0.1-0.1-0.1-0.1-0.1l-1.6-1.6c-0.1-0.1-0.1-0.1-0.2-0.1l-0.8-0.5
c-0.1-0.1-0.3-0.1-0.5-0.2l-0.8-0.1h-0.1c-0.1,0-0.2,0-0.3,0l-1.4,0.4h-0.5l-0.9-0.5l-0.8-1.1l-0.8-1.4l-0.1-0.1l-0.8-1.1
c-0.1-0.1-0.2-0.2-0.3-0.3l-1.3-0.8c-0.1-0.1-0.3-0.1-0.4-0.1l-1.4-0.2c-0.1,0-0.1,0-0.2,0c-0.1,0-0.2,0-0.3,0.1l-1.3,0.4
c-0.2,0.1-0.3,0.1-0.4,0.2l-1.1,0.9c-0.1,0.1-0.3,0.3-0.3,0.5l-0.1,0.4c0,0.1,0,0.1,0,0.1c-0.1,0-0.1,0.1-0.1,0.1l-0.3,0.2l0,0
c0-0.1-0.1-0.2-0.1-0.3l-0.2-0.3l-0.1-0.1l-0.4-0.4v-1c0-0.1,0-0.1,0-0.2l-0.2-1c0-0.1-0.1-0.3-0.2-0.4l-0.4-0.6
c-0.1-0.1-0.1-0.2-0.3-0.3l-1.1-0.9c-0.1-0.1-0.2-0.1-0.3-0.2l-0.4-0.8c0-0.1-0.1-0.1-0.1-0.2l-0.4-0.6c0.1-0.1,0.1-0.1,0.1-0.2
l0.3-0.5l0.1-0.1l0.2-0.4c0.1-0.1,0.1-0.3,0.1-0.4v-0.4c0-0.2,0-0.4-0.1-0.6l-0.1-0.2c0.7-0.4,1.2-1.2,1.4-2.2
c0.1-0.1,0.3-0.4,0.6-0.5c0.2-0.1,0.4-0.3,0.5-0.6l0.8,0.1c0.1,0,0.1,0,0.1,0c0.3,0,0.7-0.2,0.9-0.5l0.1-0.1c1,0.1,2.1,0.3,2.4,0.4
c0.3,0.1,0.5,0.2,0.9,0.3v0.2c-0.1,0.4,0.1,0.8,0.4,1.1l0.5,0.4l1.2,2.6c0,0.1,0.1,0.1,0.1,0.2l1.5,2.2c0.2,0.2,0.4,0.4,0.7,0.5
l6,1.5l3.9,2.4l3.4,3.2c0.2,0.2,0.5,0.3,0.8,0.3h0.1c0.3,0,0.6-0.2,0.8-0.5l0,0l1.9,2c0.2,0.2,0.5,0.3,0.8,0.3c0.1,0,0.3,0,0.4-0.1
l0.8,0.8l0.1,0.6c0,0.3,0.2,0.5,0.3,0.7l6.9,6.6l-0.5,1.2c-0.1,0.1-0.1,0.3-0.1,0.4l-1.5,0.7l-1.4,0.5c-0.5,0.2-0.8,0.7-0.7,1.3v0.2
l-0.5,0.4c-0.3,0.2-0.5,0.5-0.5,0.8c0,0.2,0,0.3,0.1,0.5c-0.3,0.2-0.5,0.4-0.6,0.5s-0.2,0.2-0.2,0.2c-0.1,0.1-0.2,0.1-0.3,0.2
c-0.1,0.1-0.2,0.1-0.3,0.2c-0.5,0.1-1.1,0.3-1.3,0.8c-0.3,0.5-0.3,1,0,1.5s0.6,1.2,1.3,1.4c0.2,0.2,0.5,0.3,0.8,0.4
c-0.1,0.1-0.2,0.3-0.3,0.4c-0.2-0.1-0.3-0.1-0.5-0.1c-0.1,0-0.2,0-0.3,0l0,0c-0.3-1.2-1.2-1.3-1.5-1.3c-0.4,0-0.9,0.2-1.2,0.6
c-0.2,0.3-0.5,0.8-0.3,1.4c0,0.1,0,0.2,0,0.3c0,0.3,0,0.6,0.1,0.9c0.1,0.6,0.8,1.3,1.2,1.7c0.1,0.1,0.2,0.2,0.3,0.3
c0,0.1,0,0.5,0,0.5c-0.3,0.1-0.5,0.2-0.7,0.4c-0.1,0.2-0.6,0.7-0.5,2.3v0.3c-0.3,0.1-0.5,0.3-0.6,0.5l-0.1,0.3L172,660.3z
M128.5,646.9l0.4-1.3l-1-1.6l0.8-3.2l2.2-5.8v-6.2l-0.2-1l-4.1-4.5l-0.3-0.3l-0.7,0.1l-0.5,0.5l-0.4,0.5l-0.2,0.6l-0.1,0.6
l-0.2,0.6l-0.5,0.2l-0.2,0.2l-0.6,0.9l-0.1,0.3l-0.5,0.5l-1.1,0.1l-3.6-0.5l-0.3,0.2l0.2,0.6l0.5,2.3l0.7,0.7l2.2,1.1l1.6,1.4
l1.1,1.9l1.5,4.4l0.8,5l0.7,2.6l1.1,0.5l0.2,0.3l0.9,0.5l-0.2-1l-0.2-0.6L128.5,646.9L128.5,646.9z`
  },
  {
    id: "KhanhHoa",
    name: "Khánh Hòa",
    d: `M338.1,564.4c-0.2-0.2-0.5-1.1-0.5-1.4c0-1-0.9-1.8-2.8-2.4c-0.1,0-0.2-0.1-0.3-0.1
c-0.1,0-0.2,0-0.3,0l-0.5,0.1c-0.4-0.2-1-0.5-1.2-0.6s-0.9-0.6-1.4-0.9c-0.1-0.1-0.2-0.1-0.4-0.2c-0.1,0-0.8-0.2-1.5-0.3
c0.2-0.6,0.2-1.2,0.2-1.3c0-0.4-0.3-0.8-0.7-1l-1.3-0.6c0.1-0.2,0-0.5-0.1-0.7l-0.8-1.9c-0.1-0.1,0-0.5,0.1-0.7
c0.2-0.5,0.2-1-0.1-1.5c-0.3-0.5-0.8-0.8-1.4-0.8c-0.3,0-0.6,0.1-0.9,0.3c-0.1,0-0.4-0.1-0.5-0.1s-0.2,0-0.3,0c0-0.1,0-0.1,0.1-0.2
c0.1-0.2,0.1-0.5,0.1-0.8c0-0.8-0.3-1.3-0.7-1.6c0.1-0.3,0.1-0.7,0.1-1c0-0.2,0-0.5,0-0.7c0-0.9-0.5-1.6-1.1-2c0-0.1,0-0.2,0-0.3
c0.5-0.8,0.3-2,0-2.6c0-0.1,0-0.3,0-0.4c0-0.1,0-0.3-0.1-0.5c0,0,0-0.1,0.1-0.2c0.2-0.3,0.4-0.7,0.7-1.1l1.1,0.1h0.1
c0.5,0,0.9-0.3,1.1-0.8c0.1,0,0.2,0,0.3,0l0,0c0.5,0.5,0.9,0.9,1.2,1.1c0.3,0.2,0.9,0.4,1.8,0.7c0.1,0,0.2,0.1,0.3,0.1
c0.3,0,0.7-0.2,0.9-0.5l0.6-0.8c0.3-0.4,0.3-0.9,0-1.4l-0.3-0.4c0.1-0.2,0.2-0.3,0.3-0.4c0.4-0.4,0.7-1,0.8-1.2
c0.1-0.2,0.1-0.3,0.1-0.5V534l0.5-0.3h0.1c0.2,0,0.3,0,0.3,0c0.2,0.1,0.4,0.1,0.6,0.1c1,0,1.8-1,1.8-1.9c0-0.2,0.1-1.2,0.2-2.1
l0.4-1c0.4-0.4,0.9-0.8,1.1-0.8c0.7,0,2.1-0.1,2.8-1c0.2-0.2,0.4-0.5,0.6-0.8c0.2,0.1,0.4,0.2,0.6,0.2c0.1,0,0.1,0,0.2,0
c0.4-0.1,0.7-0.3,0.8-0.7l0.6-1.4l0.9-0.1l0.1,0.1c0.2,0.3,0.5,0.4,0.9,0.4c0.1,0,0.1,0,0.2,0c0.4-0.1,0.7-0.4,0.9-0.8v-0.1h1.9
c0.3,0,0.7-0.1,1-0.1c-0.1,0.1-0.2,0.2-0.2,0.3l-0.1,0.3c-0.1,0.1-0.1,0.2-0.1,0.4v0.1l-1.1,0.7c-0.1,0-0.1,0.1-0.1,0.1l-0.4,0.4
l-0.1,0.1l-0.9,1.2l-0.4,0.3l-0.3,0.1c-0.1,0-0.2,0.1-0.2,0.1l-0.4,0.3c-0.1,0.1-0.2,0.2-0.3,0.3l-1.2,1.7c-0.1,0.2-0.2,0.3-0.2,0.5
v0.4c0,0.1,0,0.1,0,0.2l0.1,1.1c-0.2,0.2-0.3,0.5-0.3,0.8v0.6c0,0.1,0,0.1,0,0.2l0.1,0.6v0.3c0,0.4,0.2,0.7,0.4,0.9
c0.2,0.3,0.5,0.5,0.9,0.5v0.1c0.1,0.2,0.1,0.3,0.3,0.5l0.5,0.5c0.1,0.1,0.1,0.1,0.2,0.2l1.4,0.9l-0.1,0.1c-0.1,0.2-0.1,0.4-0.1,0.6
v0.4c0,0.1,0.1,0.3,0.1,0.4l0.2,0.4l-1.5-1.7c-0.1-0.1-0.1-0.1-0.2-0.2l-1.2-0.8c-0.2-0.1-0.4-0.2-0.6-0.2c-0.1,0-0.1,0-0.2,0
l-1.2,0.2c-0.4,0.1-0.7,0.3-0.9,0.7c0,0.1-0.1,0.2-0.1,0.3l-0.1,0.1c-0.2,0.3-0.3,0.7-0.1,1l0.2,0.6c0.1,0.1,0.1,0.3,0.2,0.4
l0.5,0.6l0.2,0.3c0.2,0.4,0.6,0.6,1,0.6c0.1,0,0.1,0,0.1,0l0.1,0.5v0.8c0,0.1,0,0.2,0.1,0.3l0.1,0.3c0,0.1,0.1,0.2,0.1,0.3l0.2,0.3
c0,0,0,0.1-0.1,0.1l-0.1,0.3c0,0,0,0.1-0.1,0.1c-0.1-0.1-0.3-0.1-0.5-0.1c-0.3,0-0.5,0.1-0.8,0.3c-0.4,0.4-0.5,1-0.2,1.5l0.2,0.4
c0.1,0.1,0.1,0.2,0.2,0.3l0.4,0.3c0.1,0,0.1,0.1,0.1,0.1l0,0c0,0.1,0,0.2,0,0.3c-0.3,0.1-0.5,0.4-0.6,0.7l-0.2,0.5
c-0.2,0.5,0,1.1,0.5,1.4c0.2,0.1,0.4,0.2,0.6,0.2v0.1l0,0c0,0.1,0,0.2,0.1,0.3c-0.1,0-0.2-0.1-0.4-0.1l0,0c-0.3,0-0.7,0.2-0.9,0.5
l-0.5,0.7c-0.3,0.3-0.3,0.8-0.1,1.2l0.5,1c0,0,0,0.1,0.1,0.1l-0.1,0.7c-0.1,0.3,0,0.5,0.1,0.8l0.2,0.4c0.1,0.1,0.1,0.2,0.2,0.3
l0.7,0.7l0.1,0.2l-0.1,0.8l-0.3,0.9l-0.5,0.9l-0.6,0.6l-0.4,0.1c-0.4,0.1-0.8,0.4-0.9,0.8l-0.5,1.4c-0.1,0.2-0.1,0.4-0.1,0.5
L338.1,564.4L338.1,564.4z`
  },
  {
    id: "HungYen",
    name: "Hưng Yên",
    d: `M215,193.2c-0.1-0.1-0.2-0.3-0.3-0.4c0-0.1,0-0.3,0.1-0.7c0.2-0.5,0.1-1.2-0.2-1.6
c-0.5-0.7-1.5-0.9-2-0.9c-0.1,0-0.2,0-0.4-0.1c0-0.1,0-0.4,0-0.9c0-1.3-1.1-2.3-2.2-2.6c0-0.1,0-0.1,0-0.2c0-0.2,0.2-1,0.4-1.4
s0.2-0.9,0.1-1.3c-0.2-0.5-0.6-0.9-1-1.2v-0.1c0.1-0.1,0.2-0.5,0.3-0.7c0.1-0.1,0.1-0.3,0.1-0.4h0.1c0.4,0,0.7,0,0.7,0
c0.5,0,1-0.4,1.1-1c0-0.1,0-0.2,0.1-0.4c0.3,0.1,0.8,0.1,1.2,0.1s0.7-0.1,1-0.3l0.1-0.1c0.4,0.2,0.7,0.2,1.2,0.2
c0.2,0,0.3,0,0.5-0.1c0.1,0.2,0.3,0.5,0.6,0.6c0.1,0.4,0.3,0.7,0.7,1v0.1c-0.2,0.4-0.3,0.9-0.3,1.4c0,0.2,0,0.6,0,0.9
c0,0.1,0,0.2,0,0.2s0,0.1-0.1,0.1c-0.5,0.4-0.7,1.2-0.6,1.8c0.1,0.3,0.2,0.5,0.3,0.7c0,0.2-0.1,0.4,0,0.6c0.1,0.6,0.5,1.1,1,1.4
c0.3,0.1,0.5,0.2,0.8,0.3c0.3,0.4,0.7,0.7,1.4,0.8h0.1c-0.1,0.4-0.1,1.2,0.6,1.8c0.1,0,0.1,0.1,0.2,0.2c0.1,0.1,0.2,0.2,0.3,0.3
c-0.2,0.1-0.4,0.1-0.5,0.3c-0.3-0.2-0.6-0.3-1-0.3c-0.3,0-0.5,0.1-0.7,0.2c-0.3-0.2-0.6-0.3-0.9-0.3c-0.5,0-0.9,0.2-1.1,0.6
c-0.6,0.2-1.2,0.7-1.3,1.4h-0.1C215.1,193.4,215,193.3,215,193.2z`
  },
  {
    id: "HoaBinh",
    name: "Hòa Bình",
    d: `M199,206.2c-1-0.8-1.3-0.9-1.4-0.9c0,0-0.5-0.2-1.2-0.8c-0.4-0.3-0.8-0.5-1.3-0.5
c-0.3,0-0.6,0.1-0.9,0.2c-0.2-0.1-0.3-0.1-0.5-0.2c-0.4-0.1-0.9-0.1-1.1-0.1c-0.2,0-0.8-0.1-1.3-0.1c-0.3,0-1.5-0.3-2.5-0.6
c-0.1,0-0.2-0.1-0.3-0.1c-0.3,0-0.5,0.1-0.7,0.3l-0.3,0.2c-0.5-0.2-0.9-0.3-1-0.4c-0.4-0.3-1.8-1-2.4-1.3l-0.9-0.5v-0.2
c0.1-0.4-0.1-0.9-0.5-1.2l-0.7-0.5c-0.1-0.3-0.4-0.6-0.7-1c-0.6-0.8-1.8-1-2.5-1c-0.1-0.4-0.5-0.9-1.4-1.2c-0.2-0.2-0.5-0.4-0.6-0.5
c-0.5-0.5-1-0.5-1.4-0.6c-0.2,0-0.4-0.1-0.6-0.1c-0.2-0.1-0.3-0.1-0.5-0.1c-0.7,0-1.2,0.3-1.6,0.6c-0.1,0-0.1,0-0.1-0.1
c-0.4-0.5-1-0.6-1.4-0.7c-0.1,0-0.1,0-0.2,0c-0.1,0-0.1,0-0.2,0v-1.2c0-0.4-0.2-0.7-0.5-0.9c-0.5-0.4-1.3-0.9-1.5-1.1
c-0.4-0.4-0.9-0.6-1.6-0.6c-0.4,0-0.7,0.1-0.9,0.1c-0.1,0-0.1,0-0.3,0.1c0-0.1,0-0.2,0.1-0.3c0-0.1,0-0.1,0-0.2c0-0.6,0-1-0.1-1.3
c0,0,0.1,0,0.1-0.1c0.8,0.2,1.8,0.5,2.2,0.7c0.6,0.2,1.2,0.4,1.6,0.5c0.1,0,0.2,0.1,0.3,0.1c0.1,0.1,0.3,0.1,0.5,0.1
c0.5,0,1.3-0.2,1.6-0.2c0.4-0.1,0.7-0.4,0.8-0.8c0.1-0.4,0-0.9-0.3-1.1l-0.2-0.2c0.3-0.5,0.8-1.3,0.9-1.5c0.1-0.3,0.2-0.6,0-0.9
c-0.1-0.3-0.4-0.5-0.7-0.7c0.1-0.2,0.1-0.3,0.1-0.4l0,0c0.4-0.9,0-1.5-0.3-1.8c-0.1-0.1-0.7-0.8-1-1.1c-0.2-0.2-0.3-0.4-0.4-0.4
c-0.1-0.1-1.6-1.6-1.9-2c-0.1-0.2-0.3-0.6-0.3-0.7c-0.1-0.3-0.8-1.4-1.1-1.7c-0.1-0.1-0.4-0.4-0.7-0.7l-0.1-0.3
c0.1,0.1,0.1,0.1,0.2,0.1c0.9,0.4,1.5,0.6,1.9,0.6h0.1c0.2,0,0.7,0.1,0.9,0.2c0.1,0.1,0.2,0.1,0.3,0.1c0.3,0.1,0.5,0.2,0.6,0.2
c0.2,0.7,0.5,1.2,1.4,1.4c0.2,0,0.5,0.1,0.8,0.2c0.1,0.2,0.2,0.3,0.3,0.4c0-0.1-0.1-0.1-0.1-0.1s0.7,1.6,1.6,1.9
c0.1,0,0.3,0.2,0.3,0.3c0.1,0.1,0.1,0.1,0.2,0.2c0.5,0.4,1.4,0.5,1.7,0.5s0.6,0,0.9-0.1c0,0.1,0.1,0.1,0.1,0.2
c0.3,0.4,0.6,0.7,1.1,0.7c0.1,0,0.3,0,0.4,0c0.7,0,1.3-0.3,1.6-0.9c0,0,0,0,0.1,0c0,0,0,0.1,0.1,0.1c0.1,0.2,0.5,0.7,1.2,0.7
c0.1,0,0.2,0,0.3-0.1c0.2,0.1,0.5,0.2,0.7,0.2c0.5,0,0.9-0.2,1.2-0.6l0.1-0.1c0.3-0.1,0.4-0.1,0.5-0.2c0.4-0.2,0.6-0.6,0.6-1.1
c0-1.3-0.1-1.6-0.2-1.7c0,0,0-0.1-0.1-0.1v-0.1h0.7l0.8,0.4c0.6,0.3,0.7,0.5,0.8,0.7v0.1c0.3,0.7,1,1,1.6,1.3c0.1,0,0.2,0.1,0.2,0.1
c0.5,0.6,1.6,1,2.2,1c0.1,0,0.2,0,0.3,0s0.3,0,0.4,0l0,0c-0.1,0.2-0.2,0.5-0.2,0.7c0,0.8,0.6,1.3,1,1.6c-0.7,0.5-0.9,1.4-0.9,1.6
c-0.1,0.5,0.1,1.1,0.7,1.3l1.4,0.6c0.1,0.1,0.3,0.1,0.5,0.1s0.4-0.1,0.6-0.2c0.1-0.1,0.1-0.1,0.2-0.2c0.2,0.7,0.6,1.6,0.7,1.9
c0.1,0.3,0.4,1.1,1.1,1.7c0,0.1,0,0.1,0,0.1c0,0.2-0.1,0.4-0.1,0.6s0.1,0.4,0.1,0.6c-0.4,0.3-0.7,0.8-0.7,1.4
c0.1,0.8,0.7,1.4,1.5,1.5c0.1,0.2,0.3,0.4,0.5,0.5s0.3,0.1,0.5,0.1s0.4-0.1,0.5-0.1l0.4-0.2c0.3,0.3,0.6,0.5,0.7,0.6
c0,0,0.1,0.1,0.1,0.3c0.1,0.4,0.3,0.9,0.3,1.1c0.3,0.9,1.5,1.8,1.5,1.8c0.1,0.1,0.2,0.1,0.3,0.3c0.3,0.2,0.6,0.5,0.9,0.6
c0,0.1,0,0.1,0,0.1c-0.4-0.5-1-0.6-1.2-0.6c-0.4,0-0.7,0.1-1.1,0.4c-0.1,0-0.1,0-0.2-0.1c-0.1,0-0.2-0.1-0.3-0.1
c-0.3,0-0.5,0.1-0.7,0.3c-0.3,0.3-0.4,0.7-0.4,1v0.1c-0.1-0.1-0.2-0.1-0.3-0.1c-0.3-0.1-0.5-0.1-0.8-0.1c-0.6,0-1.1,0.3-1.2,0.3
c-0.5,0.3-0.7,0.9-0.5,1.4c0.1,0.2,0.8,1.9,1,2.3c0,0,0,0.1,0.1,0.1L199,206.2z`
  },
  {
    id: "HoChiMinh",
    name: "Hồ Chí Minh",
    d: `M237.5,614.7c-0.4-0.4-0.9-0.7-1.6-0.7c-0.2,0-0.3,0-0.5,0.1l0,0c0,0-0.1,0-0.4,0
c-0.1,0-0.1,0-0.2,0c-0.2-0.4-0.5-0.7-0.7-1c0-0.1,0-0.3,0-0.4c0-0.3-0.1-0.6-0.3-0.8l-0.3-0.4c-0.2-0.3-0.5-0.4-0.8-0.4h-0.1
c-0.1,0-0.3,0-0.4,0.1l-0.3-0.2l0.7-0.5c0.2-0.2,0.3-0.4,0.4-0.6l1.1-4.8c0.1-0.5-0.1-1.1-0.6-1.3l-3-1.5l-1.6-1.2
c0.1-0.1,0.1-0.2,0.1-0.3s0.1-0.2,0.1-0.3s0-0.1,0-0.1c0.7-0.6,0.7-1.4,0.7-1.8c0-0.2,0.1-0.8,0.1-1.2l0.5-1.3c0.1,0,0.1,0,0.1,0
c0.1,0,0.3,0,0.4-0.1h0.1c0,0,0.1,0,0.2,0c0,0.1,0.1,0.1,0.1,0.1c-0.1,0.3-0.1,0.6-0.1,0.9c0.1,0.2,0.1,0.3,0.2,0.4
c0,0.2,0,0.5,0.1,0.8c0.1,0.4,0.4,0.8,1.2,1.4c0.3,0.3,0.8,0.5,1.2,0.5c0.1,0,0.2,0,0.3,0c0,0.1,0.1,0.2,0.1,0.3
c0.1,0.3,0.4,0.6,0.8,0.8c0,0.1,0,0.3,0,0.4c0.1,0.4,0.3,0.7,0.8,1l0,0c0.1,0.1,0.3,0.2,0.6,0.3c0,0.2,0,0.3,0,0.5
c-0.1,0.4-0.1,0.9,0.2,1.2c0.3,0.4,0.8,0.6,1.5,0.6c-0.1,0.2-0.1,0.5-0.1,0.9c0.1,0.7,0.6,1.2,1.3,1.2c0.3,0,0.6-0.1,0.8-0.2
c0.3-0.1,0.6-0.2,0.8-0.5c0.1,0.1,0.2,0.1,0.3,0.1c0.1,0.1,0.3,0.1,0.5,0.1c0.1,0,0.2,0,0.2,0c0.3,0.2,0.7,0.4,1.5,0.5h0.1
c0.3,0,0.5-0.1,0.7-0.3c0.2,0.6,0.7,1.3,1.1,1.8c-0.1,0.1-0.2,0.3-0.2,0.5v0.1l0,0c-0.2-0.2-0.5-0.3-0.8-0.3c-0.8,0-1.5,0.8-1.8,1.2
c-0.1,0.1-0.5,0.3-0.7,0.5c-0.2,0.1-0.3,0.2-0.5,0.3c-1.2,0.9-0.9,2.6-0.6,3.2c0.1,0.1,0.1,0.2,0.2,0.3l-0.1,0.2
c-0.1-0.1-0.1-0.1-0.2-0.1c-0.3-0.1-0.6-0.2-1-0.2c-0.1,0-0.2,0-0.3,0s-0.1,0-0.2,0c-0.4,0-0.7,0.2-0.9,0.3c-0.1,0-0.2,0-0.3,0
C238.1,614.4,237.8,614.4,237.5,614.7z`
  },
  {
    id: "HauGiang",
    name: "Hậu Giang",
    d: `M191.4,659.1c-0.3-0.2-0.6-0.3-0.9-0.3c-0.2,0-0.4,0.1-0.6,0.1c-0.4-0.3-0.9-0.3-1.1-0.3h-0.1
h-0.1c0.2-0.7,0.2-0.9,0.2-0.9c0-0.2,0-0.8-0.5-1.3c-0.4-0.4-0.9-0.6-1.7-0.6c-0.1,0-0.2,0-0.2,0l0,0c0.1-0.1,0.2-0.1,0.3-0.2
c0.1-0.1,0.2-0.1,0.3-0.2c0.2-0.1,0.4-0.3,0.6-0.5c0.1-0.1,0.4-0.4,0.5-0.4c0.5-0.1,0.9-0.5,1.1-0.9c0.1-0.3,0.1-0.5,0-0.8l0.3-0.2
c0.4-0.3,0.5-0.7,0.5-1.1l0.5-0.2c0.1,0,0.1,0,0.1-0.1l2.2-1.1c0.4-0.2,0.6-0.6,0.6-1v-0.4l0.6-1.3c0.1-0.2,0.1-0.4,0.1-0.6v-0.4
l0.6-1.5l0.8,0.9c0.2,0.2,0.5,0.4,0.8,0.4l0,0c0.3,0,0.6-0.1,0.8-0.3l0.4,0.3c0.2,0.2,0.5,0.3,0.7,0.3c0.1,0,0.3,0,0.4-0.1l1.8-0.7
c0.2-0.1,0.3-0.2,0.5-0.3l0.6,0.3c0.1,0.1,0.3,0.1,0.5,0.1c0.1,0,0.1,0,0.2,0l2.1-0.3c0.1,0,0.3-0.1,0.4-0.1l0.6-0.4l0.6,0.6
l-1.8,7.2l-5.7,3.8c-0.5,0.3-1.2,0.6-1.4,0.7c-0.3,0.1-0.5,0.3-0.8,0.5c-0.1,0-0.1,0.1-0.1,0.1c-0.3,0-0.6,0.1-0.8,0.2
c-0.1,0-0.2,0.1-0.2,0.1c-0.1-0.1-0.3-0.1-0.5-0.1c-0.7,0-1.1,0.4-1.4,0.7l0,0c-0.1,0-0.3,0.1-0.5,0.1
C192.3,658.8,191.7,659,191.4,659.1z`
  },
  {
    id: "HaiPhong",
    name: "Hải Phòng",
    d: `M231.7,193.6c0-0.5-0.3-0.9-0.7-1.2c-0.1-0.1-0.6-0.5-0.7-0.7c-0.1-0.1-0.2-0.3-0.3-0.4
c0,0,0-0.1,0.1-0.1h0.1c0.1,0,1.6,0,1.9-1.2v-0.1c0.7-0.1,1.3-0.6,1.3-1.7c0-0.2,0-0.4,0-0.6c0.5-0.2,0.7-0.6,0.8-0.9
c0.1-0.2,0.2-0.8,0-1.3c0.1-0.1,0.2-0.2,0.3-0.3s0.1-0.1,0.1-0.2c0.3-0.1,0.6-0.3,0.7-0.6c0.3-0.5,0.2-1.2-0.2-1.7
c-0.1-0.1-0.1-0.2-0.2-0.3c0,0,0,0,0.1-0.1c0.1,0.1,0.3,0.1,0.5,0.1c0.4,0,0.7-0.2,1.1-0.5c0.7-0.7,0.9-1.6,0.4-2.5
c-0.1-0.3-0.4-0.5-0.6-0.7c0.1,0,0.3-0.1,0.4-0.1c0.3,0.1,0.7,0.2,1,0.2s0.5-0.1,0.7-0.2c0.5,0.2,1.4,0.6,1.4,0.6
c0.1,0.1,0.2,0.1,0.4,0.1c0,0,0.1,0,0.2,0c0.1,0,0.2,0.1,0.3,0.1c0.3,0.1,0.7,0.2,1.1,0.3c0,0.1,0,0.1,0,0.2c0,0.2,0,0.9,0,0.9
c0,0.1,0,0.1,0,0.2l0.1,0.5v0.1l-0.2,0.3c-0.1,0.1-0.2,0.3-0.2,0.5l-0.1,0.5c-0.1,0.9,0.4,1.8,0.6,2.2c0,0,0,0,0,0.1
c0.1,0.1,0.2,0.3,0.3,0.5c-0.4,0.4-0.6,0.9-0.7,1.2l0,0c-0.3,0.2-0.5,0.5-0.5,0.8s0.1,0.7,0.3,0.9l0.7,0.8c0,0.1,0.1,0.1,0.1,0.1
l-0.3,0.2c0,0-0.1,0-0.1,0.1c-0.1,0-0.1,0-0.2,0.1l-0.3,0.1l-0.1,0.1h-0.2l-1-0.3c-0.1,0-0.2,0-0.3,0c0,0,0,0-0.1,0h-0.5
c-0.6,0-1.1,0.5-1.1,1.2v0.3c0,0.5,0.3,0.9,0.7,1.1l0.3,0.1l-0.1,0.1l-0.5,0.2l-0.4,0.1h-0.2l-0.4-0.6c-0.1-0.1-0.2-0.2-0.3-0.3
l-0.5-0.3c-0.2-0.1-0.4-0.2-0.7-0.2c-0.2,0-0.5,0.1-0.7,0.2c-0.4,0.3-0.6,0.8-0.4,1.3c-0.1,0-0.2,0-0.3,0c-0.5,0-1,0.3-1.3,0.7
c-0.2-0.1-0.5-0.2-0.8-0.2C232.4,193.3,232,193.4,231.7,193.6z`
  },
  {
    id: "HaiDuong",
    name: "Hải Dương",
    d: `M224.6,189.9c-0.2-0.1-0.5-0.2-0.7-0.2s-0.3,0-0.5,0.1c-0.2-0.1-0.3-0.1-0.5-0.1
s-0.5,0.1-0.7,0.1c-0.1,0-0.2-0.1-0.2-0.2c0.1-0.7-0.1-1.2-0.5-1.6c-0.3-0.4-0.9-0.7-1.5-0.9c-0.2-0.5-0.7-0.9-1.2-0.9
c0-0.3,0-0.5-0.1-0.7s-0.1-0.3-0.2-0.4c0.3-0.4,0.4-0.9,0.4-1.2c0,0,0-0.1,0-0.2c0-0.3,0.1-0.8,0-1.2c0-0.1,0.1-0.3,0.1-0.5
c0.1-0.4,0.3-0.7,0.3-1.2c0-0.1,0-0.2,0-0.3c0.2,0,0.3,0,0.5-0.1c0.5-0.1,0.8-0.3,1-0.4h0.1c0.1,0,0.3-0.1,0.4-0.1h0.1
c0.5,0.1,1.1,0.2,1.1,0.2h0.1c0.3,0,0.6-0.1,0.8-0.4c0.2-0.3,0.3-0.6,0.3-1v-0.1c0.5-0.5,0.8-0.9,0.8-1.5c0,0,0-0.1,0.1-0.1l0.1-0.1
c0.2-0.2,0.7-0.7,0.7-1.5c-0.1-1-0.1-1.2-0.2-1.3l0,0l-0.1-0.1c0.4-0.4,0.9-1,1-1.7h0.1c0.6,0,1-0.2,1.4-0.5
c0.2-0.2,0.3-0.4,0.3-0.6c0.7-0.1,1.3-0.6,1.6-1.2c0-0.1,0.1-0.1,0.1-0.1c0.3,0,0.4,0.1,0.7,0.1c0.1,0,0.3,0,0.4,0h0.2
c-0.1,0.2-0.1,0.4-0.1,0.7l0,0c0,0.1-0.1,0.1-0.1,0.2c-0.3,0.5-0.3,1-0.3,1.5c-0.1,0-0.1,0-0.2,0c-0.6,0-1.1,0.3-1.3,0.7
c-0.3,0.5-0.2,1.1,0,1.5c-0.3,0.5-0.2,0.9-0.1,1.2c0.2,0.4,0.5,0.7,1.1,0.9c0.2,0.1,0.5,0.1,1.5,0.1c0.3,0.2,0.6,0.3,1,0.4
c0.3,0.4,0.7,0.6,1.1,0.6c0.1,0,0.2,0,0.3,0c-0.2,0.4-0.2,0.9-0.2,1.1c0,0.1,0,0.3,0.1,0.4s0.1,0.3,0.3,0.5c-0.5,0.2-1,0.7-1,1.4
c0,0.1,0,0.2,0,0.3c-0.7,0.7-0.5,1.3-0.3,1.6c-0.2-0.1-0.4-0.1-0.6-0.1c-0.3,0-0.6,0.1-0.8,0.2c-0.4,0.2-0.6,0.6-0.6,1
c0,0.5,0.3,0.9,0.6,1.2c-0.1,0.2-0.1,0.4,0,0.5c-0.2,0.1-0.3,0.3-0.4,0.6c-0.1,0.5-0.1,1,0.2,1.4c-0.4,0.1-0.7,0.3-1,0.5
c-0.1,0.1-0.2,0.2-0.3,0.3c-0.2-0.1-0.5-0.2-0.7-0.2c-0.4,0-0.8,0.2-1.2,0.5c-0.1,0.1-0.4,0.3-0.6,0.6c-0.1,0-0.2,0.1-0.3,0.1
c-0.2,0-0.3,0.1-0.4,0.1c-0.3-0.1-0.5-0.2-0.8-0.2C225.1,189.4,224.8,189.7,224.6,189.9z`
  },
  {
    id: "HaTinh",
    name: "Hà Tỉnh",
    d: `M222.3,307c-0.2-0.1-0.4-0.2-0.6-0.2c-0.1,0-0.1,0-0.2,0c-1.3,0.2-1.6,0.3-1.8,0.3
c-0.1,0-0.5,0.1-0.9,0.1s-0.7,0-1-0.1s-1.4-0.4-2.3-0.8c0.1-0.1,0.2-0.2,0.2-0.2c0.4-0.4,0.8-1.2-0.1-2.3l-0.1-0.1
c-0.2-0.3-0.7-0.9-1.6-0.9c-0.1,0-0.3,0-0.4,0.1c-0.4-0.5-0.9-1.1-1.3-1.5c-0.2-0.2-0.5-0.3-0.8-0.4l0,0c-0.2,0-0.5,0.1-0.7,0.2
l-0.3-0.5c-0.2-0.3-0.5-0.5-0.9-0.5l-1.4-0.1H208c-0.2,0-0.3,0-0.5,0.1c-0.4,0.2-1,0.5-1.3,1c0,0-0.1,0-0.1,0.1l0,0
c-0.3,0-1.3,0.1-1.5,1.4c-0.1,0-0.1,0-0.2,0c-0.6,0-1.1,0.2-1.5,0.3c-0.1-0.1-0.3-0.1-0.5-0.3c-0.3-0.1-0.5-0.2-0.8-0.2
c-1,0-1.7,0.9-2,1.7h-1.7l-0.7-0.6c-0.2-0.2-0.5-0.3-0.7-0.3c0-0.3,0-0.7-0.1-0.9c-0.1-0.6-0.4-1.2-0.5-1.3
c-0.1-0.3-0.4-0.5-0.7-0.6l-0.7-0.2l-0.1-0.9c0-0.3-0.2-0.6-0.5-0.8c-0.1-0.1-0.2-0.1-0.3-0.1c-0.1-0.8-0.4-1.6-0.6-1.9
c0,0,0-0.1-0.1-0.1l-0.4-0.7c-0.2-0.3-0.5-0.5-0.9-0.5l-1.9-0.1c-0.5,0-1,0.4-1.1,0.9h-0.3c-0.3,0-0.7,0.1-0.9,0.4
c0,0,0,0.1-0.1,0.1c-0.2-0.1-0.3-0.3-0.6-0.4c-0.2-0.1-0.3-0.1-0.5-0.2c0.1-0.2,0.1-0.4,0.1-0.6c0.2-0.9,0-1.7,0-1.8
c-0.1-0.4-0.4-0.7-0.9-0.8l-0.9-0.2c-0.1,0-0.2,0-0.3,0c-0.3,0-0.6,0.1-0.8,0.3c-0.1,0-0.2,0-0.3,0s-0.1,0-0.1,0
c-0.3-0.6-0.7-1.3-1.5-1.5c-0.1-0.1-0.5-0.3-0.9-0.6c0-0.3,0-0.7,0-0.9s0-0.4-0.4-1.7c-0.1-0.5-0.6-0.8-1.1-0.8h-0.3l-0.6-0.8
c-0.1-0.2-0.3-0.3-0.5-0.4c-0.1-0.1-0.3-0.1-0.3-0.2c0-0.7,0.3-2.5,0.4-3c0.1-0.1,0.2-0.3,0.3-0.5c0.4-0.6,0.7-1.2,0.7-1.7
c0.3,0.1,0.5,0.1,0.7,0.1l1.1,0.3c0.1,0,0.2,0,0.3,0c0.3,0,0.5-0.1,0.7-0.3c0.4-0.3,0.9-0.7,1.1-0.7l0.2,0.1
c0.2-0.1,0.4-0.1,0.6-0.1c0.1,0,0.1,0,0.1,0c0.7,0.6,1.7,1.2,2.2,1.4c0.3,0.1,0.6,0.2,0.9,0.2c0.5,0,1-0.1,1.4-0.2
c0.6-0.1,1.6-0.5,2-0.6c0.3-0.1,1-0.3,1.3-0.4c0.3,0.6,0.9,1.2,1.4,1.6c0.1,0.1,0.3,0.2,0.4,0.2c0.3,0.1,1.2,0.3,1.8,0.3
c0.5,0,1.2-0.3,1.6-0.4h0.7c0.2,0,0.3,0,0.5,0.1s0.5,0.2,0.8,0.2c0.5,0,1.3-0.2,1.8-0.6c0.7-0.5,0.9-1.4,0.9-1.8v-0.1
c0-0.1,0-0.2,0-0.3c0.3-0.2,0.5-0.5,0.5-0.8l0,0c0.3-0.1,0.7-0.3,1-0.4c0.2-0.1,0.4-0.3,0.5-0.5l0.1-0.1l0.8,2.5
c0,0.1,0.1,0.2,0.1,0.3l2.2,3.5c0,0.1,0.1,0.1,0.1,0.2l1.5,1.6c0.2,0.2,0.5,0.3,0.8,0.4h0.5l0.3,0.9c0,0.1,0,0.1,0.1,0.2l0.4,0.7
c0,0.1,0.1,0.1,0.1,0.1l0.4,0.5c0.1,0.1,0.1,0.2,0.3,0.3l0.9,0.7l0.2,0.2l1.5,1.9c0.1,0.1,0.1,0.1,0.2,0.2l1.8,1.5
c-0.1,0.1-0.2,0.3-0.3,0.5c-0.1,0.4,0.1,0.9,0.5,1.2l0.4,0.3c0.2,0.1,0.4,0.2,0.7,0.2c0.1,0,0.1,0,0.2,0l0.7-0.1h0.1l0.8-0.3
l0.6-0.1l0.2,0.1l2,1.5l0.1,0.1l0.3,0.2c0.1,0,0.1,0.1,0.2,0.1l0.7,0.3l0.1,0.1l1,1.3c0.1,0.1,0.1,0.2,0.2,0.2l0.7,0.5l-0.1,0.1
c-0.1,0.1-0.2,0.2-0.3,0.3l-0.3,0.4c-0.2,0.3-0.2,0.6-0.1,0.9c0.1,0.3,0.3,0.6,0.6,0.7l0.5,0.3c0.1,0,0.1,0.1,0.1,0.1v0.1l0.4,0.8
c0.2,0.4,0.6,0.6,1,0.6c0.1,0,0.2,0,0.3,0c0.5-0.1,0.9-0.6,0.9-1.1v-0.4l0.1-0.2c0.1,0,0.2,0.1,0.3,0.1h0.1h0.4c0,0,0,0,0.1,0
l0.1,0.3c0,0.1,0.1,0.2,0.1,0.3l0.5,0.9c0,0.1,0.1,0.1,0.1,0.2l1.7,2.1c-0.1,0-0.1,0-0.2,0l-0.2-0.3c-0.2-0.3-0.4-0.4-0.7-0.5
c-1.2-0.2-2.7-0.5-3-0.7c-0.2-0.1-0.4-0.1-0.7-0.1c-0.6,0-1.3,0.3-1.6,0.8c-0.1,0.1-0.1,0.2-0.2,0.3h-0.1c-0.4,0-0.8,0.2-1,0.6
c-0.2,0.3-0.5,0.8-0.5,1.2c0,0,0,0.1-0.1,0.2L222.3,307z`
  },
  {
    id: "HaNoi",
    name: "Hà Nội",
    d: `M202.9,195c-0.4-0.4-1-0.9-1.2-1c-0.2-0.2-0.5-0.3-0.7-0.3c-0.1,0-0.1,0-0.2,0
c-0.1-0.1-0.2-0.2-0.3-0.3c0-0.3-0.1-0.6-0.2-0.8c0-0.1,0-0.1,0-0.1v-0.1c0.1-0.4,0.3-1.5-0.6-2.3c-0.3-0.2-0.5-0.7-0.5-0.8v-0.1
c-0.3-0.7-0.6-1.6-0.7-1.8c-0.1-0.5-0.2-1.5-0.2-1.5c0-0.3-0.2-0.6-0.4-0.8c-0.1,0-0.5-0.4-0.8-0.6c-0.1-0.1-0.4-0.2-0.7-0.3
c-0.1-0.1-0.1-0.3-0.2-0.4c-0.1-0.2-0.3-0.3-0.5-0.5c0.3-0.4,0.6-0.8,0.6-1.4c0-0.5-0.2-0.9-0.5-1.3c-0.3-0.3-0.9-0.7-1.6-0.7
c-0.4,0-0.7,0.1-0.9,0.3l0,0c-0.1,0-0.2,0-0.3,0s-0.2,0-0.3,0s-0.4-0.1-0.5-0.2c-0.3-0.4-0.7-0.5-1.1-0.7c-0.1-0.1-0.3-0.1-0.4-0.2
c-0.3-0.6-0.6-1.2-1.8-1.8l-1.1-0.6c-0.2-0.1-0.3-0.1-0.5-0.1h-1.2c-0.1,0-0.1,0-0.1,0l-1,0.1c-0.2-0.3-0.3-0.6-0.4-0.7
c-0.1-0.2-0.1-0.6,0-0.8c0.3-0.5,0.8-1.5,0.8-2c0,0-0.1-1.3-0.7-1.9c-0.1-0.1-0.2-0.4-0.2-0.5c0.1-0.3,0.3-0.6,0.4-0.7l0.1-0.1
c0.9-0.5,2-1.1,2-2.2c0-0.1,0-0.2-0.1-0.4c0-0.2-0.1-0.6,0-0.8c0.3-0.5,0.5-0.7,0.6-0.7c0.2-0.1,0.5-0.1,0.7-0.1c0.1,0,0.2,0,0.2,0
c0.1,0.1,0.1,0.1,0.2,0.1v0.1c0.1,0.3,0.1,0.6,0.1,0.7c0-0.1,0-0.1,0-0.1c0,0.3,0.2,1.4,0.5,2c0,0,1.1,1.9,1.8,2.4l0.1,0.1
c0.3,0.3,0.9,0.8,1.9,0.9c0.3,0,0.8,0.1,1.3,0.1c0.4,0,0.7,0,0.9,0c0.1,0,0.1,0,0.1,0h0.1c0.2,0,0.5,0,0.7,0c0.4,0,0.7,0,1-0.2
c0.6-0.3,0.8-0.9,0.9-1.5c0.4-0.1,0.9-0.2,1.2-0.5c0.2,0.1,0.4,0.1,0.7,0.1c0.1,0,0.3,0,0.5,0c1.2-0.1,2-0.8,2-1.6
c0-0.3-0.1-0.5-0.3-0.9c0,0,0-0.1-0.1-0.1c0.3-0.3,0.7-0.7,0.8-0.8s0.1-0.1,0.1-0.2c0.2-0.4,0.6-0.9,0.7-1.1
c0.1-0.1,0.1-0.2,0.2-0.3l0,0c0.5-0.2,0.8-0.6,0.9-1.1c0-0.2,0-0.4,0-0.5c0.1,0,0.2,0,0.3,0l0,0c0.1,0.4,0.2,0.8,0.4,1.1
c0.3,0.3,0.6,0.8,1.4,0.9c0.2,0.7,0.5,0.9,0.9,1.1c0,0.3,0.1,0.5,0.3,0.8c-0.4,0.2-0.6,0.5-0.7,0.9c-0.1,0.3-0.1,0.5,0,0.8
c0,0.1-0.1,0.2-0.1,0.3c-0.1,0.5,0.1,1,0.5,1.5c0,0.4,0.1,0.7,0.1,0.9c-0.1,0.5-0.3,1.4,0.3,2c0.1,0.1,0.2,0.2,0.2,0.3l0.1,0.1
c0.3,0.9,0.8,1.3,1.2,1.4c0.2,0.6,0.8,1.1,1.5,1.1c0.1,0,0.1,0,0.2,0c-0.1,0.1-0.1,0.3-0.1,0.4c-1,0.1-1.6,0.7-1.6,1.3
c0,0,0,0-0.1,0c-0.1-0.1-0.3-0.1-0.5-0.2c-0.1-0.1-0.3-0.1-0.5-0.1c-0.3,0-0.6,0.1-0.8,0.4c-0.6,0.7-0.8,1.5-0.4,2.1
c0,0.1,0.1,0.1,0.1,0.2c0,0.1-0.1,0.2-0.1,0.2v0.1c-0.4,1.2-0.1,2.2,0.7,2.6c0.1,0,0.1,0.1,0.2,0.1c-0.2,0.6-0.5,1.6-0.5,2.3
c0.1,0.5,0.2,1.7,1.6,2.1c0.3,0.1,0.5,0.3,0.6,0.4c0,0.8,0,1.6,0.3,2.2c-0.2-0.2-0.5-0.3-0.7-0.3s-0.3,0-0.5,0.1l-1.6,0.7
c-0.4,0.1-0.6,0.5-0.7,0.9v0.1c-0.1,0-0.3-0.1-0.6-0.2c-0.4-0.2-0.7-0.3-1-0.3c-0.5,0-0.9,0.2-1.1,0.6c-0.1,0.2-0.2,0.4-0.2,0.6
c-0.2,0.5-0.5,0.9-0.6,1.1C203.2,194.1,203.1,194.3,202.9,195z`
  },
  {
    id: "HaNam",
    name: "Hà Nam",
    d: `M207.9,202.9c-0.1-0.1-0.4-0.5-0.6-0.8c-0.1-0.2-0.2-0.3-0.2-0.4c0-1.4-0.4-2.3-1.1-2.6
c-0.2-0.1-0.5-0.4-0.7-0.5s-0.3-0.3-0.5-0.3c-0.1-0.1-0.4-0.3-0.5-0.5c0.3-0.2,0.5-0.4,0.5-0.7c0.1-0.7,0.4-1.5,0.5-1.7
s0.3-0.5,0.5-0.9c0.4,0.1,0.8,0.3,1.2,0.3c0.5,0,0.9-0.1,1.2-0.4s0.6-0.6,0.7-0.9c0.1,0.1,0.3,0.2,0.4,0.3c0.3,0.1,0.5,0.2,0.9,0.2
c0.8,0,1.6-0.5,1.8-0.6s0.4-0.3,0.5-0.6v-0.1c0,0.6,0.1,1.2,0.4,1.6c0.1,0.1,0.2,0.2,0.3,0.4c0.3,0.4,0.4,0.6,0.6,0.8
c0.4,0.4,0.9,0.6,1.4,0.6c0.4,0,0.7-0.1,1.1-0.3c0.1-0.1,0.3-0.1,0.3-0.2l0,0l0,0c0,1.8,0.8,2.3,1.5,2.3c0.1,0,0.2,0,0.3,0
c0.1,0.2,0.2,0.4,0.2,0.5s0,0.2,0,0.3c-0.1-0.1-0.2-0.1-0.3-0.2l-0.9-0.3c-0.1,0-0.2-0.1-0.4-0.1c-0.4,0-0.8,0.2-1,0.6
c-0.3,0.6-0.5,1-0.7,1.3c-0.2-0.1-0.3-0.1-0.5-0.2c-0.2-0.1-0.4-0.2-0.6-0.2c-0.3,0-0.6,0.1-0.9,0.4c-0.7,0.7-1,1.2-1.2,1.5
c-0.2-0.1-0.3-0.1-0.5-0.1h-0.1l-1.2,0.1c-0.3,0-0.6,0.2-0.8,0.5l-0.5,0.7l-0.5,0.2c-0.2,0.1-0.3,0.1-0.4,0.3L207.9,202.9z`
  },
  {
    id: "HaGiang",
    name: "Hà Giang",
    d: `M161.4,127.1c0.3-0.3,0.4-0.8,0.2-1.2l-0.6-1.2c-0.1-0.3-0.5-0.6-0.8-0.7c-0.1,0-1.5-0.3-2-0.4
c-0.3-0.1-1.4-0.4-2.2-0.7c-0.1-0.1-0.3-0.1-0.4-0.1h-0.1l-0.1-0.3c0.3-0.6,0.3-1.1,0.1-1.6c-0.2-0.4-0.7-0.7-1.2-0.7
c0-0.4-0.1-0.7-0.2-0.8c-0.1-0.3-0.3-0.5-0.5-0.6c-0.7-0.3-1.4-0.7-1.6-0.9c-0.3-0.3-0.8-0.5-1.3-0.7l-0.2-0.4
c0.4-0.3,0.6-0.9,0.5-1.4c-0.1-0.6-0.6-1.2-1.2-1.4c-0.1,0-0.3-0.1-0.3-0.2c-0.1-0.2-0.1-0.4-0.3-0.6c0.5,0,0.9-0.2,1.2-0.5
c0.2-0.2,0.8-0.8,0.1-2.9c-0.1-0.3-0.4-0.6-0.7-0.7l-1.2-0.4c-0.1-0.2-0.1-0.3-0.1-0.4c-0.1-0.3-0.4-1.1-0.4-1.1
c-0.1-0.1-0.1-0.3-0.2-0.4c0.2-0.2,0.3-0.5,0.3-0.8c0-0.1,0.1-0.2,0.1-0.2c0.6-0.2,1.1-0.3,1.4-0.6c0.2-0.1,0.5-0.4,0.7-0.5l1.6-0.7
c0.1-0.1,0.3-0.1,0.4-0.3c0.5-0.6,1.2-1.3,1.5-1.8c0.1,0,0.1-0.1,0.3-0.1l0.2,0.3c0.2,0.4,0.6,0.6,1,0.6h0.9c0.3,0,0.7-0.1,0.9-0.4
l0,0c0.3,0.1,0.4,0.3,0.4,0.3c0.2,0.1,0.4,0.2,0.6,0.2c0.1,0,0.1,0,0.2,0l0,0c0,0,0.2,0,0.5,0c0.1,0,0.3,0,0.4,0
c0.6,0.1,1.5,0.1,1.6,0.1c0.3,0,0.7-0.2,0.9-0.4c0.2-0.3,0.3-0.6,0.2-1l-0.1-0.3c0.4-0.1,0.7-0.4,0.8-0.8c0.1-0.6,0.4-1.2,0.6-1.5
c0.9-0.1,1.6-0.5,2-0.7c0.5-0.3,1.2-0.9,1.5-1.2c0.3-0.3,0.4-0.6,0.3-1l-0.2-1.2c-0.1-0.4-0.3-0.7-0.7-0.9l-0.3-0.1l-0.6-1.3v-1.4
c0-0.1,0-0.3-0.2-0.9c0.2,0,0.3,0,0.4,0c0.6,0,1.2-0.3,1.3-0.3c0.3-0.2,0.5-0.5,0.6-0.8l0.2-0.9l0.1,0.1c0.2,0.2,0.5,0.4,0.8,0.4
h0.9c0.6,0,1.1-0.5,1.2-1.1c0-0.3,0.1-0.7,0.2-0.9c0.8-0.3,2.5-1.1,3-1.6c0.2-0.2,0.5-0.5,0.9-0.9l0.3,0.3c0.2,0.2,0.5,0.3,0.7,0.3
c0.1,0,0.3,0,0.4-0.1l2.1-0.8c0.3-0.1,0.5-0.3,0.7-0.6l1.8,0.4c0.1,0,0.2,0,0.3,0c0.4,0,0.8-0.2,1-0.6c0.1-0.3,0.5-1.1,0.6-1.4
c0-0.1,0.1-0.3,0.1-1l0.5-0.1c0.3-0.1,0.5-0.2,0.7-0.5l0.3-0.4c0,0.3,0.1,0.5,0.3,0.7l2.7,2.6c0.2,0.2,0.5,0.3,0.8,0.3h0.1
c0.2,0,0.4-0.1,0.5-0.2c0.4,0.9,0.7,1.3,1,1.6c0.1,0.2,0.2,0.6,0.2,1c0.1,0.5,0.4,0.8,0.9,0.9c1,0.3,1.6,0.4,2,0.5
c0.1,0.1,0.1,0.3,0.1,0.4s0,0.4-0.1,0.7l-0.6,0.1c-0.4,0.1-0.8,0.4-0.9,0.9c-0.2,0.8-0.5,1.9-0.6,2.1c-0.1,0.3-0.4,0.9-0.5,1.5
l-1.4,0.3c-0.8,0.2-1.8,0.3-2.1,0.4c-0.7,0-1.4,0.5-1.7,0.9c-0.3,0.5-0.4,1-0.3,1.4c0,0.1,0,0.2,0,0.4c-0.2,0.1-0.4,0.2-0.5,0.3
c-0.6,0.1-1.1,0.6-1.4,1.5l-0.5-0.1c-0.1,0-0.1,0-0.1,0c-0.3,0-0.5,0.1-0.7,0.3c-0.3,0.2-0.4,0.5-0.4,0.9v1.1c0,0.5,0.3,1,0.8,1.1
l1.5,0.4c0.4,0.3,1.2,0.9,1.5,1.1c0.5,0.3,1,0.4,1.5,0.4c0.1,0,0.2,0,0.3,0s0.2,0,0.3,0c0.3,0.2,0.9,0.7,1.1,0.9
c0.1,0.1,0.3,0.4,1.6,1.4c0,0.6,0.3,1.2,0.7,1.7l-0.2,0.3c-0.2,0.3-0.2,0.7,0,1.1l0.1,0.3v0.4l-0.1,0.3c-0.2,0.1-0.4,0.2-0.5,0.2
c-0.3,0-0.9-0.1-1.2-0.2c0,0,0,0-0.1,0c-0.3-0.1-0.8-0.3-0.9-0.4c-0.3-0.3-0.8-0.7-1.7-0.7c-0.3,0-0.7,0.1-1,0.1h-0.1
c-0.4,0.1-0.7,0.2-0.9,0.5c-0.3-0.6-1-1.1-2.1-1.4c-0.1,0-0.3-0.1-0.4-0.1c-0.2,0-0.7-0.1-0.8-0.2c-0.4-0.3-0.9-0.5-1.6-0.7
c-0.3-0.1-0.6-0.2-0.8-0.3c-0.3-0.1-0.5-0.2-0.7-0.2c-0.4,0-1.3,0.1-1.5,1.5v0.1c-0.2,0.6,0,1.2,0.3,1.7c0,0.1-0.1,0.1-0.1,0.2
l-0.9,2c-0.1,0.3-0.1,0.5-0.1,0.8l0.4,1.5c-0.1,0.2-0.3,0.4-0.5,0.7c-0.8,1.1-0.3,1.9-0.1,2.2v0.1c-0.4,0.2-0.6,0.5-0.7,0.9
c-0.1,0.3-0.1,0.7,0,1c-0.6,0.3-0.7,0.9-0.7,1.5c0,0,0,0-0.1,0c-0.7,0-1.2,0.4-1.4,1s0.1,1.3,0.5,1.8v0.1c0-0.1-0.1-0.2-0.1-0.3
c-0.5-0.8-1.7-1-2.3-1c-0.3,0-0.4-0.1-0.5-0.1c-0.4-0.3-1.4-0.9-1.6-1c-0.2-0.1-0.4-0.1-0.6-0.1c-0.1,0-0.3,0-0.4,0.1
c-0.3,0.1-0.6,0.4-0.7,0.7l-0.4,1.2c-0.2,0.6,0.1,1.2,0.7,1.4c0,0,0.2,0.1,0.3,0.1c-0.3,0.1-0.5,0.4-0.5,0.7
c-0.1,0.3-0.1,0.6,0.1,0.9c0,0,0.1,0.2,0.3,0.5c0,0.1-0.1,0.1-0.1,0.2s-0.1,0.3-0.1,0.5c0,0.1,0,0.1-0.1,0.2
c-0.2-0.1-0.4-0.2-0.7-0.2c-0.5,0-1.1,0.3-1.4,1.1c-0.3,0-0.6,0.1-0.7,0.1c-0.3,0-0.9,0-1.4,0.1L161.4,127.1z`
  },
  {
    id: "GiaLai",
    name: "Gia Lai",
    d: `M320.3,514.7c-0.1-0.1-0.3-0.1-0.4-0.1c-0.3-0.1-0.5-0.1-0.8-0.1s-0.6,0.1-0.8,0.1
c-0.1-0.1-0.3-0.3-0.4-0.4c-0.2-0.3-0.6-0.5-0.9-0.5c-0.1,0-0.3,0-0.4,0.1l-0.5,0.2l-0.6-0.3c-0.1,0-0.1-0.1-0.2-0.1
c0.1-0.3,0-0.7-0.1-1c-0.1-0.4-0.5-0.7-0.8-0.9c-0.1-0.1-0.1-0.2-0.2-0.5c-0.2-0.4-0.5-0.7-0.9-0.9c0-0.2,0-0.4-0.1-0.6l-0.4-1
c-0.1-0.2-0.2-0.4-0.4-0.5c0,0-0.6-0.4-1.1-0.8c-0.1-0.2-0.2-0.8-0.1-1.3c0-0.2-0.1-0.5-0.2-0.7l-0.6-0.9c-0.2-0.3-0.6-0.5-1-0.5
c-0.1,0-0.1,0-0.2,0c0-0.3-0.1-0.5-0.1-0.7c-0.1-0.7-0.6-1.2-0.9-1.4l0,0c0-0.5-0.3-0.9-0.8-1.1l-2.2-0.7l-0.8-0.7
c-0.2-0.2-0.5-0.3-0.7-0.3c-0.1,0-0.1,0-0.2,0l-1.2,0.2c-0.4-0.1-0.9-0.1-1.1-0.2c-0.5-0.3-2.5-1.3-3.2-1.5
c-0.3-0.1-0.5-0.1-0.8-0.1c-0.5,0-0.9,0.2-1.1,0.3l-0.1,0.1c-0.1,0-0.2-0.1-0.5-0.2c-0.2-0.1-0.4-0.2-0.7-0.2c-0.2,0-0.9,0-2.2,1.2
c-0.1,0-0.3,0-0.5,0c-0.5,0-0.9,0.1-1.2,0.3c0,0-0.1,0-0.2,0c-0.4,0-1-0.1-1.2-0.1c-0.3-0.1-1.6-0.4-2.4-0.4c-0.1,0-0.1,0-0.2,0
s-0.3-0.1-0.3-0.1c-0.2-0.2-0.4-0.3-0.7-0.3c-0.1,0-0.1,0-0.1,0l-1.2,0.1c-0.2,0-0.5,0.1-0.6,0.3c0,0-0.4,0.3-0.8,0.7
c-0.1,0-0.5,0.1-1.1,0.1c-0.3,0-0.5,0-0.7,0s-0.5,0-0.7,0c-0.7,0-1.3,0.1-1.8,0.5l-0.1,0.1l-0.3-6.1c0-0.1-0.1-0.3-0.1-0.4l-1.8-3.7
l-0.2-1.6c0-0.2-0.1-0.4-0.2-0.5l-0.8-1.2l-0.6-2.2c-0.1-0.3-0.3-0.5-0.5-0.7l-2.3-1.4l0.5-1.8c0.1-0.5-0.1-0.9-0.4-1.2
c0.3-0.7,0.4-1.6,0.2-2.3c0,0,0,0,0.1,0c0.6-0.2,1-0.6,1.3-0.9l0.1-0.1c0.3-0.1,0.6-0.2,0.8-0.5c0.2-0.2,0.4-0.6,0.4-1.1
c0.1,0,0.2,0,0.3,0c1.9,0,2.5-0.8,2.6-1.5c1.2-0.1,2.4-0.9,2.4-2.2c0-0.4,0.2-1.4,0.3-1.9c0-0.1,0.1-0.3,0-0.4l-0.1-0.9
c0.3-0.3,0.6-0.7,0.7-1.4c0-0.1,0-0.1,0-0.1c0.1,0.1,0.3,0.1,0.4,0.1c0.5,0.2,1,0.4,1.5,0.4s0.9-0.2,1-0.4c0.1-0.1,0.1-0.1,0.2-0.1
c0.1-0.1,0.1-0.1,0.2-0.1l0,0c0.2,0.1,0.4,0.1,0.7,0.1c1.1,0,2.1-0.8,2.4-1.6c0.1-0.3,0.1-0.7,0.1-1l0.4-0.1l0.7,0.1
c0.2,0.2,0.5,0.5,0.6,0.7c0.1,0.1,0.1,0.1,0.1,0.2c0.2,0.3,0.6,0.9,1.7,0.9c0.1,0,0.2,0,0.3,0c0.5-0.1,0.9-0.2,1.3-0.3l0.4,0.2
c0.2,0.1,0.4,0.1,0.5,0.1c0.4,0,0.7-0.2,0.9-0.5l0.6-0.9c0.1,0.1,0.3,0.1,0.5,0.1s0.3,0,0.4-0.1c0.3-0.1,0.5-0.4,0.7-0.7
c0.3-1,0.8-2,1-2.2c0.3-0.3,0.5-0.6,0.7-0.9c0.2,0.1,0.5,0.1,0.7,0.1c0.5,0,1-0.2,1.3-0.3c0.1,0,0.2-0.1,0.2-0.1
c0.5-0.1,0.9-0.4,1.2-0.7c0.1-0.1,0.3-0.3,0.4-0.3c0.2,0,0.4-0.1,0.7-0.1c0.2,0,0.4,0,0.4,0h0.1l1.8-0.1c0.3,0,0.6-0.1,0.8-0.4
c0.2-0.2,0.3-0.5,0.3-0.9c0-0.1,0-0.3,0.1-0.4c0.5-0.5,2.6-3.4,3.3-4.2c0.2-0.3,0.3-0.7,0.1-1.1c-0.1-0.2-0.2-0.4-0.4-0.5
c0-0.2,0-0.3-0.1-0.5c0.4,0.5,0.8,0.7,0.9,0.7s0.1,0,0.1,0.1l2.3,0.8c0.1,0,0.3,0.1,0.4,0.1s0.3,0,0.4-0.1l1.4-0.5
c0.3-0.1,0.6-0.4,0.7-0.8l0.1-0.3c0.2,0.2,0.5,0.4,0.8,0.5c0.2,1.2,0.9,1.5,1.3,1.5c0,0.2,0.1,0.5,0.2,0.9c-0.1,0-0.1,0-0.2,0
c-0.3,0-0.5,0.1-0.8,0.3c-0.3,0.3-0.5,0.7-0.3,1.2c0.8,3.1,1,3.5,1.1,3.7s0.2,0.6,0.3,0.9c0.1,0.5,0.3,1,0.4,1.4
c0.1,0.2,0.3,1.1,0.5,1.8c0.4,1.4,0.6,2.4,0.8,2.8c0,0.2-0.1,0.6-0.3,0.9c-0.2,0.3-0.2,0.6-0.1,0.9l0.6,1.7c0.1,0.2,0.2,0.3,0.3,0.5
l1,0.9l0.3,1.4c0.1,0.4,0.3,0.7,0.7,0.8l0.6,0.2c0,0.5,0.1,1,0.1,1.2s0,0.9,0,1.5l-0.3,0.6c-0.1,0.2-0.1,0.4-0.1,0.6l0.1,1.4
c0,0.4,0.3,0.8,0.7,1h0.1c0,0.1,0.1,0.3,0.1,0.4c0.1,0.1,0.5,0.9,1.2,1.5c0.1,0,0.1,0.1,0.1,0.1c0,0.1,0,0.3,0,0.4
c0,0.9,0.4,1.5,1.2,1.8l0,0c0,0.8,0.2,1.3,0.5,1.8v0.1c0,0.1,0,0.3-0.1,0.4l-0.3,0.1c-0.5,0.2-0.8,0.7-0.7,1.3l0.3,1.7
c0,0.1,0,0.1,0.1,0.2l0.7,1.9h-0.1h-1.2c-0.3,0-0.6,0.1-0.8,0.4l-0.2,0.2h-0.4h-0.1c-0.4,0-0.8,0.2-1,0.6c-0.2,0.3-0.5,1-0.7,1.6
c-0.3,0.1-0.8,0.5-0.8,1.2c0,0.1-0.1,0.4-0.2,0.6c-0.1,0.3-0.1,0.7,0,0.9l0.4,0.9c0.1,0.3,0.4,0.5,0.7,0.6c0.4,0.1,1.1,0.3,1.4,0.3
c0.1,0,0.3,0,1.3-0.3l0.1,0.2l0.1,1.8l0.1,1.5c0,0.3,0.1,0.6,0.3,0.8l1,1l1,1.7c0.1,0.1,0.1,0.2,0.2,0.3v0.1c0,0,0,0.3,0,0.8
c0,0.1-0.3,0.5-0.5,0.8c-0.1,0.2-0.2,0.4-0.2,0.7v0.9v0.1l-1.9,0.6c-0.1,0-0.2,0.1-0.3,0.1c-1.2,0.8-1.3,1.1-1.4,1.3
c-0.1,0.1-0.2,0.3-0.4,1.9c0,0.1,0,0.1,0,0.2l0.1,1.5L322,514h-0.9C320.8,514.2,320.5,514.4,320.3,514.7z`
  },
  {
    id: "DongThap",
    name: "Đồng Tháp",
    d: `M204.8,635.3c-0.1,0-0.2,0-0.4-0.1l-0.8-1.2c-0.2-0.3-0.5-0.5-0.8-0.5h-0.1c-0.1,0-0.3,0-0.4,0.1
c-0.1-0.1-0.3-0.3-0.5-0.3l-0.9-0.2c-0.1,0-0.1,0-0.2,0c-0.4,0-0.7,0.2-1,0.5l-1.1,1.7c-0.3-0.3-0.6-0.6-0.7-0.7
c-0.2-0.3-0.6-1.2-0.7-1.4c-0.2-0.8-1.3-1.8-1.7-2.1c-0.1-0.1-0.2-0.2-0.3-0.3c-1.1-1-1.7-1.6-2.1-1.8c0,0-0.1-0.1-0.4-0.3l0.5-0.4
c0.2-0.2,0.4-0.5,0.4-0.8l0.1-0.8l0.4-0.4c0.1-0.1,0.2-0.2,0.2-0.3c0,0,0,0,0.1,0c0.2,0,0.4-0.1,0.5-0.1c0.3-0.1,0.5-0.4,0.6-0.7
c0-0.1,0.2-0.7,0.1-1.3c0-0.1,0-0.2-0.1-0.4c-0.1-0.9-0.2-1.6-0.5-2c-0.4-0.7-1.6-1.7-2-2c-0.5-0.3-1.5-0.5-1.9-0.5
c-0.1,0-0.1,0-0.1,0c-0.1,0-0.3,0-0.4,0.1l-0.9-0.3c-0.1-0.1-0.3-0.1-0.4-0.1c-0.1,0-0.1,0-0.1,0s-0.2,0-0.5,0c-0.1,0-0.2,0-0.2,0
l0,0c-0.4-0.4-0.8-0.7-1.4-0.9c-0.1,0-0.2-0.1-0.2-0.1c-0.1-0.4-0.4-1.3-0.5-1.7c0-0.5,0-1.4,0.1-1.7c0-0.1,0-0.1,0.1-0.2
c0.1-0.5,0.3-1.2-0.2-1.8c-0.2-0.3-0.5-0.6-1.2-0.7c-0.1,0-0.3,0-0.4,0h-0.1c0.1-0.2,0.1-0.4,0-0.7c-0.1-0.5-0.5-1.1-1.5-1.2
c-0.1,0-0.1,0-0.2,0c-0.3-1.2-0.8-1.7-1.7-1.7l0,0c-0.1-0.3-0.3-0.7-0.5-1c-0.1-0.1-0.1-0.3-0.2-0.4c0.1,0,0.3,0,0.5,0
c0.1,0,0.1,0,0.2,0c0.2,0,0.4,0,0.6-0.1c0.1,0,0.3,0,0.3,0s0.1,0,0.3,0.1c0.9,0.5,2.4,1,2.6,1c0.1,0,0.2,0.1,0.3,0.1
c0.4,0,0.8-0.2,1-0.5c0.5-0.8,1.1-1.7,1.4-1.9c0.4-0.2,0.8-0.7,1.2-1.4c0.1,0.1,0.3,0.1,0.4,0.1c0.1,0,0.1,0,0.1,0
c0.2,0,0.3-0.1,0.5-0.2c0.2,0.1,0.4,0.2,0.7,0.3l0.3,0.7c0.1,0.2,0.3,0.4,0.5,0.5l2.5,1.2l0.7,1.4c-0.1,0.1-0.1,0.3-0.2,0.5
c0,0.3,0.1,0.7,0.3,0.9l7,6.6c0.1,0.1,0.3,0.2,0.4,0.3l1.7,0.6l3.2,4.4c0.2,0.3,0.5,0.5,0.9,0.5h0.2v0.2l-3.1,3.3
c-0.1,0.1-0.2,0.3-0.3,0.5l-0.2,0.7h-0.4c0,0,0,0-0.1,0c-0.3,0-0.6,0.1-0.9,0.4c-0.2,0.3-0.3,0.6-0.3,0.9l0.3,2.3
c0,0.1,0,0.1,0.1,0.2c0.1,0.3,0.5,1.4,1.6,1.6c0.2,0,0.5,0.1,0.8,0.2c-0.1,0.3,0,0.7,0.2,0.9l0.4,0.6c0.1,0.1,0.1,0.2,0.2,0.2
c0,0.1,0.1,0.2,0.1,0.3c-0.4,0-0.7,0.3-0.9,0.6l-0.4,0.8C205.2,635,205,635.1,204.8,635.3z`
  },
  {
    id: "DongNai",
    name: "Đồng Nai",
    d: `M248.9,614.3c-0.2-0.2-0.5-0.3-0.7-0.3s-0.3,0-0.5,0.1l-1.2,0.6l0,0c0-0.1-0.1-0.2-0.2-0.3
l-0.5-0.6c0-0.1-0.1-0.1-0.1-0.1l-0.6-0.5c-0.1-0.1-0.2-0.1-0.3-0.2l-0.5-0.3c-0.1-0.1-0.3-0.1-0.5-0.1c-0.1,0-0.3,0-0.5,0.1
l-0.2,0.1c0-0.1,0-0.1,0-0.2c0.1-0.1,0.2-0.2,0.4-0.3c0.4-0.3,0.7-0.5,1-0.7c0.1,0.1,0.3,0.1,0.5,0.1c0.3,0,0.6-0.1,0.9-0.3
c0.2,0.1,0.4,0.2,0.6,0.2l0,0c0.5,0,1-0.3,1.2-0.8c0.2-0.4,0.3-0.8,0.1-1.2c0.2-0.2,0.3-0.5,0.3-0.9c0.1-0.7-0.2-1.3-0.6-1.7
c-0.2-0.2-0.7-0.9-0.7-1.2c-0.1-1-0.3-2-1.2-2.2c-0.2-0.1-0.3-0.1-0.5-0.1l0,0c-0.3-0.2-0.7-0.6-0.9-0.9c0.2-0.1,0.3-0.3,0.4-0.5
c0.2-0.4,0.1-0.9-0.2-1.3c0-0.1-0.1-0.1-0.1-0.1c0.1,0,0.1,0,0.1-0.1c0.3-0.1,0.5-0.2,0.7-0.5h0.1h0.1c0.3,0.4,0.9,0.7,1.4,0.7
c0.2,0,0.5-0.1,0.7-0.1h0.1c0.2,0.1,0.3,0.1,0.5,0.1c0.8,0,1.4-0.6,1.5-1.3l0,0c0.6,0,1.2-0.4,1.4-1c0.1-0.4,0.1-0.9,0-1.3
c0.1,0,0.1-0.1,0.2-0.1c0.5-0.3,0.6-0.9,0.4-1.4c0,0-0.1-0.2-0.2-0.4c0.1-0.1,0.2-0.3,0.2-0.5c0.1-0.6-0.1-1.3-0.7-1.6
c-0.1-0.1-0.2-0.2-0.3-0.3c-0.1-0.3-0.3-0.8-0.9-1.1c0-0.2,0-0.3,0-0.5c0.3-0.3,0.5-0.7,0.4-1.2V590v-0.1c0-0.1,0-0.2,0-0.3
c0.1-0.1,0.3-0.3,0.3-0.4c0.2-0.1,0.4-0.2,0.6-0.4c0.4,0,0.8-0.2,1.1-0.6c0.1-0.1,0.1-0.3,0.2-0.4c0.4-0.2,0.7-0.5,0.9-0.7l0,0
c0,0,0.1,0,0.1-0.1c0.3-0.1,0.8-0.3,1-0.9c0.5-0.2,0.9-0.8,0.9-1.5c0.1-0.1,0.1-0.2,0.1-0.2c0.5-0.4,0.6-1,0.3-1.8
c0.3-0.2,0.6-0.5,0.6-0.5c0.1-0.1,0.1-0.1,0.1-0.2l0,0l0.4-0.1c0.4-0.1,0.7-0.3,0.8-0.7c0.1-0.1,0.2-0.4,0.7-0.5
c1.2-0.2,6.4-1.4,7.1-1.5c0.1,0.2,0.1,0.4,0.3,0.5c0.3,0.3,0.7,0.5,1.1,0.5c0.2,0,0.5-0.1,0.7-0.2c0.3,0.3,0.7,0.4,1.1,0.4
c0.1,0,0.3,0,0.5-0.1l0.1,0.1c0,0,0,0,0.1,0.1c-1.1,0.5-1.3,1.8-1.3,2.1c-0.1,0.4,0.2,0.9,0.5,1.1c0.1,0.1,0.5,0.3,1.1,0.3
c0.2,0,0.4,0,0.6-0.1c0.1,0.5,0.3,1,0.9,1.5c0.2,0.1,0.6,0.5,0.9,0.9c0,0.1,0,0.1,0,0.2c0,0.2-0.2,0.7-0.3,1
c-0.1,0.2-0.1,0.4-0.2,0.5c0,0.1-0.1,0.4,0,1.6c-0.5,0.3-0.9,0.7-1.1,1.3c-0.1,0.3-0.5,0.9-0.7,1.4c-0.3,0.6-0.5,0.9-0.6,1.2
c-0.1,0.1-0.1,0.2-0.2,0.3c-0.4,0.5-0.9,1.1-0.8,1.8c0,0.3,0.2,0.6,0.4,0.8c-0.1,0.3-0.3,0.5-0.4,0.6s-0.2,0.2-0.2,0.3l-0.6,1.2
c-0.3,0.6,0,1.2,0.5,1.5c0.2,0.1,0.7,0.4,1,0.6c0.1,0.1,0.1,0.1,0.2,0.2c0.3,0.3,0.8,0.9,1.7,0.9c0.3,0,0.5-0.1,0.7-0.1
c0.1,0,0.1-0.1,0.2-0.1c0.1,0.1,0.2,0.1,0.2,0.2c0.1,0.1,0.3,0.5,0.5,0.7c0.1,0.2,0.2,0.4,0.4,0.6c0.1,0.2,0.3,1,0.3,1.4
c0,0.1,0,0.3,0,0.4c-0.1,1.1-0.1,2.3,0.7,2.9c0.1,0.1,0.1,0.2,0.1,0.2c-0.3,0.3-0.9,0.9-1.1,1.1l-0.2,0.1c-0.1,0.1-0.3,0.1-0.5,0.2
c-0.1-0.1-0.2-0.2-0.3-0.3c-0.1-0.1-0.1-0.1-0.3-0.2c-0.5-0.4-1-0.9-1.7-0.9c-0.5,0-1,0.3-1.2,0.9c0,0.1-0.2,0.2-0.3,0.4
c-0.5,0.1-1.2,0.3-1.5,0.8c-0.1,0.2-0.8,1.2-1.2,1.7c-0.1,0.1-0.2,0.2-0.3,0.4l-0.2-1c-0.1-0.5-0.5-0.9-1.1-0.9l-2.7-0.2h-0.1
c-0.1,0-0.2,0-0.3,0.1c-0.4-0.3-0.9-0.6-1.4-0.6c-0.3,0-0.5,0.1-0.7,0.2c-0.3,0.2-0.7,0.5-1.1,0.7c-0.1,0.1-0.2,0.2-0.3,0.2
c-0.6,0.4-1.1,1.3-1.1,2c0,0.2-0.1,0.7-0.1,0.9c-0.1,0.1-0.5,0.4-0.8,0.7L255,614h-0.4l-0.1-0.2c-0.1-0.1-0.1-0.1-0.2-0.2l-1.2-1.2
c-0.2-0.2-0.5-0.3-0.8-0.3c-0.1,0-0.2,0-0.3,0c-0.4,0.1-0.7,0.4-0.8,0.8l-0.2,0.6c-0.1,0.4,0,0.8,0.2,1.1l0.4,0.5
c0.1,0.1,0.1,0.1,0.2,0.2l0.2,0.1c-0.3,0.1-0.5,0.4-0.6,0.7c-0.1,0-0.2,0.1-0.3,0.1H251L248.9,614.3z`
  },
  {
    id: "DienBien",
    name: "Điện Biên",
    d: `M99.5,181.6l-1.9-0.5c-0.1,0-0.2-0.1-0.3-0.1c-0.1,0-0.2,0-0.4,0.1l0,0c0,0-0.1,0-0.3,0
c-0.1,0-0.1,0-0.2,0s-0.2,0-0.3,0c-0.1,0-0.3,0-0.4,0.1l-0.2-0.5c-0.1-0.3-0.3-0.5-0.5-0.6v-0.3c0-0.2-0.1-0.4-0.2-0.6
c-0.7-1.2-1.6-2.6-1.6-2.8c-0.2-0.3-0.5-0.9-0.6-1c-0.2-0.3-0.5-0.5-0.8-0.5c-0.5-0.1-1.2-0.2-1.6-0.2c0.3-1.1-0.3-1.6-0.8-1.9
c0.1-0.3,0-0.6-0.1-1c-0.2-0.5-0.6-0.8-0.7-0.9s-0.2-0.1-0.4-0.2l-0.4-0.1c0-0.1,0-0.1,0-0.2c0.2-0.5,0.1-1.2,0-1.6
c0-0.2-0.1-0.4-0.2-0.5l-0.7-0.8c-0.1-0.1-0.2-0.2-0.4-0.3L86,167c0.5-0.1,0.9-0.3,1.2-0.7c0.3-0.4,0.3-1,0.1-1.5
c0-0.1-0.1-0.3-0.1-0.3c0.1-0.3,0.2-0.8,0.2-0.9c0-0.1,0-0.2,0-0.2v-0.2c0.8-0.7,0.9-1.2,0.9-1.4c0-0.1,0.2-0.5,0.2-0.7
c0.1-0.3,0.2-0.4,0.2-0.6c0-0.1,0.1-0.2,0.2-0.3c0.3-0.3,0.4-0.8,0.2-1.2c-0.2-0.4-0.6-0.7-1-0.7l0,0c0-0.1,0-0.1,0-0.2
c0-0.1,0.1-0.3,0.1-0.3c0.4-0.3,1.1-0.9,1.5-1.3c0.1-0.1,0.3-0.3,0.3-0.5c0-0.1,0.1-0.3,0.1-0.4c0.2-0.2,0.7-0.7,0.9-0.9
c0.4-0.4,0.5-1,0.2-1.4l-0.4-0.6c0-0.3-0.1-0.7-0.1-1c0-0.1,0.1-0.2,0.1-0.4c0-0.1,0-0.1,0.1-0.1c0-0.1,0.1-0.3,0.1-1
c0-0.2-0.1-0.4-0.2-0.6c0,0,0,0,0-0.1c0.3-0.6,0.1-1.3,0-1.5l0,0c0-0.1-0.1-0.4-0.8-1.5c-0.2-0.3-0.5-0.5-0.9-0.5l0,0
c-0.3,0-0.7,0.2-0.9,0.4l-0.5,0.6c-0.9,0.1-1.6,0.3-1.9,0.4c0.1-0.5,0.1-1.2,0.1-1.7c0-0.1,0-0.1,0-0.2c0,0,0-1.9-0.3-2.5
c-0.4-0.9-1.1-1.1-1.6-1.1c-0.3,0-0.5,0.1-0.7,0.2c-0.7,0.3-1.2,0.8-1.3,0.9c-0.4,0.3-0.5,0.9-0.3,1.3l0.3,0.6l0,0
c-0.1,0.2-0.1,0.4-0.1,0.6l0.3,1.5c-0.2,0.1-0.3,0.3-0.3,0.6c-0.3,0.1-0.5,0.2-0.5,0.2c-0.3,0.1-0.5,0.3-0.6,0.5
c-0.1,0.3-0.4,0.7-0.5,0.8c-0.2,0.1-0.3,0.2-0.5,0.3c-0.1,0-0.2,0-0.2,0h-1.5c0-0.3-0.1-0.7-0.3-1.2c-0.1-0.3-0.2-0.4-0.3-0.6
c0-0.3,0-0.5,0-0.7v-0.1c0.7-0.7,0.8-1.8,0.3-2.5c-0.1-0.1-0.1-0.2-0.1-0.2s0,0,0-0.1c0-0.3-0.1-0.6-0.3-0.9c0-0.1-0.1-0.2-0.1-0.3
c0.1-1.1-0.6-1.5-0.9-1.7V141c0.1-0.3,0.1-0.5,0-0.8l-0.3-1c-0.1-0.4-0.4-0.6-0.8-0.7c-0.8-0.2-1.6-0.5-1.9-0.6
c-0.4-0.5-1.2-0.9-1.4-1.1h-0.1c-0.2-0.1-0.3-0.1-0.4-0.2c0.2-0.1,0.3-0.2,0.5-0.3c0.4-0.3,0.6-0.8,0.4-1.3l-0.2-0.5
c-0.2-0.5-0.6-0.7-1.1-0.7H71h-0.1c-0.1,0-0.1,0-0.1,0c-0.3-0.4-0.8-0.9-1.3-1.2c0,0,0,0-0.1,0c-0.1-0.7-0.1-0.8-0.2-0.9
c-0.1-0.4-0.9-1.4-1.8-1.6c-0.2,0-0.4,0-0.5-0.1l0,0c-0.1-0.4-0.3-0.8-0.7-1.1c-0.4-0.4-0.8-0.5-1.1-0.6c0-0.3-0.2-0.7-0.5-0.9
l-0.8-0.6c-0.2-0.1-0.5-0.2-0.7-0.2h-0.5v-0.3c-0.1-0.6-0.5-1-1.2-1h-0.3c0-0.1,0-0.1,0-0.1c0-0.1-0.1-0.6-0.1-0.9
c0-0.6-0.5-1.1-1.1-1.2h-0.5l-0.1-0.1c0.1-0.3,0.2-0.5,0.3-0.5c0.1-0.3,0.1-0.6-0.1-0.9c-0.1-0.3-0.4-0.5-0.7-0.5
c-0.1,0-0.2-0.1-0.3-0.1c0.1-0.4,0-0.8-0.3-1.1l-0.5-0.5h0.1c0.3,0.1,0.7,0.3,1.1,0.3h0.9c0.4,0.3,0.8,0.5,1.3,0.5s0.9-0.2,1.2-0.7
s0.3-1.1,0-1.8c-0.1-0.1-0.1-0.2-0.2-0.3l0.4-0.6c0.1-0.1,0.1-0.2,0.1-0.3c0.1-0.2,0.2-0.5,0.3-0.5c0.1-0.1,0.3-0.2,0.4-0.4l0,0
c0.1,0.4,0.4,0.7,0.6,1l0.1,0.3c0.1,0.2,0.3,0.4,0.5,0.5l0.6,0.3c0,0.3,0.1,0.5,0.1,0.7c-0.1,0.1-0.1,0.2-0.2,0.3
c-0.4,0.5-0.3,1.2,0.3,1.6c0.3,0.2,0.8,0.6,1.2,0.8c0.1,0.1,0.2,0.2,0.3,0.2c0.2,0.2,0.5,0.5,0.8,0.6c0,0,0.1,0.1,0.1,0.2
c0.2,0.5,0.6,1,0.9,1.3c0.1,0.1,0.1,0.1,0.1,0.1c0.1,0.1,0.3,0.5,0.6,0.9l0.7,1.4c0.1,0.1,0.2,0.3,0.3,0.4c0.9,0.7,1.2,0.9,1.2,0.9
c0.3,0.2,0.7,0.2,0.9,0.2c0.4,0,0.8-0.1,1.2-0.3c0.3,0.2,0.5,0.3,0.7,0.4l-0.1,0.5c0,0.3,0.1,0.7,0.3,0.9l0.4,0.5l0.2,0.3
c0.2,0.4,0.6,0.6,1,0.6c0.1,0,0.1,0,0.2,0c0.3,0.2,0.8,0.4,1.3,0.4c0.6,0,1.1-0.3,1.4-0.8c0.1,0.4,0.1,0.8,0.1,0.9
c0,0.3,0.1,0.9,1.1,1.7c0.1,0,0.1,0.1,0.1,0.1l0.2,0.1l0,0c0,0.1,0,0.2,0,0.3c0.1,0.3,0.2,0.8,0.9,1.2c0.2,0.1,0.4,0.2,0.5,0.2
s0.2,0.1,0.3,0.1c0.6,0.5,1.5,0.6,1.8,0.6c0.1,0,0.5,0.1,0.8,0.1c0.1,0,0.1,0,0.1,0c0.4,0,0.8-0.3,1-0.6c0.3,0,0.5-0.1,0.8-0.2
c0.2,0.3,0.6,0.6,1,0.7c0.1,0,0.1,0.1,0.2,0.1c0.1,0.4,0.4,0.9,1.3,1.3c0.2,0.1,0.4,0.1,0.6,0.1l0,0c0.4,0,0.7-0.1,1-0.3
c0.2-0.2,0.4-0.4,0.5-0.7c0.3,0.1,0.5,0.2,0.8,0.2c0.5,0,0.9-0.2,1.2-0.5h1c0.4,0,0.8-0.3,1-0.6c0.3,0.2,0.7,0.4,1.1,0.4
c0.3,0,0.6-0.1,0.9-0.3c0,0,0,0,0.1,0s0.3,0,0.6,0.1c0.1,0.1,0.3,0.1,0.5,0.1c0.2,0.1,0.5,0.1,0.7,0.2c0.4,0.3,0.7,0.4,0.9,0.5
c0.1,0,0.1,0.1,0.2,0.1c0.5,0.3,0.9,0.4,1.4,0.5c0.1,0,0.2,0,0.2,0c0.7,0,1.2-0.4,1.4-0.9c0.2-0.5,0.1-1.1-0.4-1.6
c0.1-0.2,0.2-0.5,0.3-0.7c0.5-0.3,0.9-0.7,1-1.3c0-0.1,0.1-0.1,0.1-0.2c0.1,0,0.1-0.1,0.1-0.1c0.3,0,0.6-0.1,0.9-0.1h0.1
c0.1,0.2,0.3,0.5,0.5,0.6c0,0.1,0.1,0.2,0.1,0.3c0,0.5,0.2,1,0.3,1.5v0.1c0.1,0.2,0.2,0.4,0.5,0.7v0.1c0.1,0.3,0.2,0.6,0.5,0.8
c0.1,0.1,0.3,0.1,0.4,0.2c-0.1,0.2-0.2,0.4-0.2,0.7c0,0.5,0.2,0.9,0.7,1.2c0.1,0,0.1,0.1,0.1,0.1c0.1,0.5,0.4,0.9,0.8,1.2
c0,0.1-0.1,0.1-0.1,0.2l-0.2,1.2c0,0.2,0,0.4,0.1,0.6c0,0.1,0.1,0.1,0.1,0.2c-0.1,0.7,0.4,1.5,0.9,1.9c0.3,0.2,0.5,0.4,0.8,0.5
l0.1,0.6c0.1,0.3,0.2,0.4,0.4,0.6l0,0c0,0.1,0.1,0.2,0.1,0.3s0.1,0.2,0.1,0.2c-0.3,1,0.1,1.6,0.5,2c0.2,0.2,0.3,0.3,0.5,0.4l0,0
c0.1,0.1,0.1,0.3,0.2,0.5c0.1,0.3,0.4,0.8,0.5,1c0,0,0,0,0,0.1c-0.1,0.1-0.2,0.2-0.3,0.3c-0.1,0.1-0.7,0.9-1.2,1.5
c-0.3,0.4-0.3,0.9-0.1,1.3c0.1,0.1,0.1,0.3,0.2,0.4c-0.2,0.2-0.4,0.5-0.5,0.6c-0.2,0.2-0.4,0.6-0.3,0.9l0,0c-0.1,0-0.1,0.1-0.2,0.1
c-0.2,0.2-0.3,0.5-0.3,0.8v0.1c-0.5,0.1-0.8,0.5-0.9,0.7c-0.1,0-0.1,0.1-0.2,0.1c-0.8,0.1-1.1,0.8-1.6,2c-0.1,0.2-0.1,0.4-0.1,0.7
l0.2,0.9v0.1l0,0c-0.6,0-1-0.1-1-0.1c-0.1,0-0.2-0.1-0.3-0.1s-0.2,0-0.3,0l-0.5,0.1h-1.2c-0.3,0-0.5,0.1-0.8,0.3l-0.5,0.5
c-0.2,0.2-0.4,0.5-0.4,0.8s0.1,0.6,0.3,0.8l0.8,0.8l0.1,0.4c0.1,0.4,0.3,0.7,0.6,0.9l-0.1,0.2c-0.2,0.5-0.1,1.1,0.4,1.4
c0.8,0.5,1.4,0.8,1.9,0.8c0.1,0,0.1,0,0.2,0s0.1,0,0.2,0c0,0.1,0,0.1,0,0.2c0,0.2,0,0.4,0,0.5c-0.4,0.6-0.4,1.5-0.4,1.8
c0,0.1,0,0.2,0,0.2c0.1,0.6,0.3,1.3,0.3,1.6c-0.2,0-0.3,0.1-0.3,0.1c-0.1,0-0.3,0.1-0.3,0.2l-0.5,0.4c-0.3,0.3-0.5,0.7-0.4,1.1
c0.1,0.7,0.3,1.6,0.3,1.8c0,0.1,0,0.3,0.1,0.5l-0.1-0.1c-0.3-0.3-0.7-0.7-1.2-0.8c-0.3-0.1-1.4-0.3-1.9-0.4c-0.1,0-0.1,0-0.2,0
c-0.3,0-0.5,0.1-0.8,0.3c-0.2,0.2-0.7,0.8-0.7,1.5c0,0.1,0,0.1,0,0.2l-0.1,0.1c-0.4,0.8-0.3,1.6,0.2,2.1c-0.1,0.1-0.1,0.2-0.2,0.3
c-0.3,0.4-0.2,1,0.1,1.4c0.1,0.1,0.3,0.3,0.4,0.5v0.1c-0.2,0.1-0.4,0.3-0.5,0.4c-0.1,0.2-0.4,0.8-0.4,1.4c-0.1,0.1-0.2,0.1-0.3,0.2
L99.5,181.6z`
  },
  {
    id: "DakNong",
    name: "Đắk Nông",
    d: `M269.8,567.3c-0.2,0-0.3-0.1-0.5-0.1s-0.3,0-0.5,0.1c0,0,0,0,0-0.1s0.1-0.1,0.1-0.1
c0.1-0.2,0.2-0.4,0.3-0.6c0.1-0.2,0.4-1,0.6-1.4c0.1-0.3,0.5-1.2-0.8-2.2c0-0.3-0.1-0.5-0.2-0.7c-0.2-0.6-0.9-1.3-1.4-1.6
c0-0.2,0-0.5-0.1-0.9c-0.3-1.1-1.5-2-1.9-2.3l-0.1-0.1c-0.5-0.3-1.4-0.7-2.1-0.7c-0.2,0-0.4-0.1-0.9-0.3c-0.1-0.1-0.2-0.1-0.3-0.1
c0-0.4,0-0.7,0.1-0.8c0.1-0.4,0.3-1.3,0.4-1.6s0-0.6-0.2-0.9c-0.1-0.1-0.3-0.3-0.4-0.4V552c0-0.1,0-0.1,0-0.2l-0.3-2.7l-0.3-2
c0.5-0.2,0.9-0.5,1.1-0.8l0.1-0.1c0.1-0.1,0.4-0.3,0.8-0.5c0,0,0,0,0.1,0s0.3,0,0.5,0s0.5,0.1,0.7,0.1c0.5,0,0.9,0.1,1,0.2
c0.1,0.1,0.3,0.5,0.4,0.8c0.1,0.9,1.1,1.7,1.5,2c0.2,0.1,0.4,0.2,0.7,0.2h1.2c0.6,0,1.2-0.5,1.2-1.2v-0.9c0.1,0,0.1-0.1,0.3-0.2
c0.5-0.3,0.7-0.6,0.9-0.8c0,0,0,0,0.1-0.1c0.7-0.1,1.4-0.7,1.7-0.9c0.3-0.1,0.7-0.3,0.9-0.5c0.3-0.1,0.5-0.4,0.6-0.7l0.3-1.1
c0.1-0.3,0.1-0.7-0.1-0.9c-0.1-0.1-0.2-0.4-0.2-0.5c0.6-0.6,0.7-1.4,0.8-1.9v-0.1c0.3-0.2,0.5-0.5,0.6-0.9c0.1-0.6,0-1.3-0.4-1.8
c0.5-0.4,1.3-1.3,0.6-2.5c0-0.1,0-0.2,0-0.3s0-0.2,0-0.3s0-1-0.3-1.8c-0.1-0.4-0.2-0.9-0.2-1.2c0.3-0.7,0.1-1.5,0-1.9
c-0.1-0.1-0.1-0.3-0.3-0.4c-0.1-0.1-0.2-0.2-0.3-0.3c0.1-0.5,0.1-1,0.1-1.5c0-0.2,0-0.3,0-0.5c0-0.1,0-0.2,0-0.3c0.2,0,0.3,0,0.5,0
c0.3,0,0.5,0,0.8,0.1c1.3,0.3,6.4,0.9,6.9,1c0.1,0,0.1,0,0.1,0c0.2,0,0.3,0,0.5-0.1c0.3-0.2,0.6-0.3,0.8-0.5c0,0.3,0.1,0.6,0.4,0.9
c0.3,0.3,0.7,0.5,1.2,0.5c0.1,0,0.3,0,0.4,0c-0.2,0.8,0,1.7,0.2,2.2c0.1,0.1,0.1,0.6,0.2,0.9c0.3,1.2,0.4,1.8,0.7,2.2
c-0.5,0.2-0.7,0.5-0.9,0.9c0,0.1-0.1,0.1-0.1,0.2c-0.3,0.4-1,1.4-0.5,2.3c0.3,0.5,0.8,0.8,1.4,0.8c0.2,0,0.3,0,0.5-0.1
c0.3-0.1,0.7-0.1,1-0.1h0.1c0.3,0.5,0.8,1,1.2,1.4l-0.1,0.2c-0.1,0-0.2,0-0.3,0c-0.9,0-1.8,0.5-2.3,1.3c-0.7,1-0.6,2.1,0.1,3
c0,0.1,0.1,0.3,0.1,1.1s0.6,1.6,1.3,1.9c0.3,1,1.2,1.1,1.5,1.1c0.1,0,0.3,0,0.4,0s0.2,0,0.3,0c0.2,0,0.4,0.1,0.7,0.3
c0.1,0.1,0.2,0.1,0.3,0.1c0.4,0.2,1.5,1.3,1.7,1.7l0.1,0.3c0.3,0.7,0.7,1.4,1.2,1.7c0,0.1,0.1,0.2,0.1,0.3v0.1c-0.6,0-1,0.2-1.2,0.4
c-0.5-0.2-1-0.2-1.4-0.2c-0.3,0-0.4,0-0.5,0c-0.2,0-0.4,0.1-0.6,0.2c-0.8,0.6-1.7,1.5-1.7,2.4l0,0l0,0c-0.6,0.4-0.8,1.2-0.7,1.8
c0.2,0.7,0.7,1.2,1.5,1.3c0.1,0,0.3,0.1,0.4,0.3l0,0c0.1,0.5,0.3,1.6,1.2,2.3c0,0.1,0,0.1,0,0.2c0.1,1.2,0.9,1.6,1.4,1.6
c0.1,0.4,0.2,1.3,0.6,1.8c-0.2,0.3-0.4,0.6-0.6,0.8c-0.6,0.1-1.3,0.3-1.8,0.8c-0.1,0.1-0.4,0.3-0.7,0.5l-0.6,0.1L291,565
c-0.2-0.2-0.5-0.3-0.8-0.3c-0.1,0-0.2,0-0.3,0c-0.4,0.1-0.9,0.2-1,0.2s-0.2,0-0.3,0c0.1-0.4,0.1-0.9-0.2-1.5c-0.4-0.9-1.1-1-1.5-1
c-0.2,0-0.3,0-0.5,0.1h-0.1c-0.1,0-0.3,0-0.5,0c-0.1,0-0.3,0-0.5,0c-0.1,0-0.2,0-0.2,0c-0.1,0-0.2-0.1-0.4-0.1c-0.5,0-0.8,0.2-1,0.3
c-0.1,0-0.1,0.1-0.2,0.1c-0.4,0-0.9,0.2-1.3,0.3c-0.2-0.1-0.3-0.1-0.5-0.2c0-0.2,0.1-0.4,0.1-0.6c0-0.8-0.6-1.8-2.2-1.8
c-0.2,0-0.4,0-0.6,0c-0.7,0.1-1.2,0.5-1.4,0.7c-0.3,0.3-0.3,0.7-0.3,0.9v0.1c-1.3,0.4-1.8,1-1.9,1.6c-0.1,0.1-0.3,0.2-0.6,0.3
c-0.6,0.2-1.2,0.9-1.6,1.6c-0.5-0.1-1-0.1-1.3-0.2c-0.1,0-0.2,0-0.3,0C270.7,565.6,270,566.9,269.8,567.3
C269.9,567.2,269.8,567.2,269.8,567.3z`
  },
  {
    id: "DakLak",
    name: "Đắk Lắk",
    d: `M302.4,549.9c-0.3-0.1-0.6-0.3-1-0.3c-0.2,0-0.4,0-0.5,0.1c-0.1-0.1-0.3-0.3-0.6-0.5
c-0.4-0.3-0.7-0.4-1.1-0.4c-0.5,0-0.9,0.2-1.1,0.4c-0.1-0.2-0.3-0.4-0.5-0.5c-0.1-0.2-0.3-0.6-0.4-0.8l-0.1-0.3
c-0.4-0.8-1.9-2.3-2.7-2.7c-0.1,0-0.1-0.1-0.2-0.1c-0.4-0.2-1-0.5-1.8-0.5c-0.1,0-0.1,0-0.2,0c-0.3-0.5-0.7-0.7-1-0.8l0,0
c0-0.8-0.1-1.7-0.6-2.3c0-0.1-0.1-0.1,0.1-0.3c0.2-0.3,0.3-0.3,0.4-0.3h0.1c0.1,0,0.2,0,0.3,0c0.9,0,1.6-0.5,1.9-1
c0-0.1,0.1-0.1,0.1-0.1l0.6-1.2c0.2-0.5,0.1-1.1-0.4-1.4c-0.6-0.4-1.2-1-1.4-1.2c-0.2-0.5-0.7-1-1.6-1.1c0.2-0.2,0.4-0.5,0.5-0.8
c0.2-0.6,0.1-1.6-0.3-2.2c-0.1-0.3-0.3-1-0.4-1.5c-0.2-0.8-0.3-1.2-0.4-1.4c-0.1-0.1-0.1-0.6,0-0.7c0.3-0.5,0.3-1.1,0-1.6
c-0.4-0.6-1.1-1.1-1.9-1.2V525c0.1-0.5-0.1-0.9-0.3-1.3c-0.3-0.3-0.8-0.5-1.3-0.5s-1.1,0.2-1.4,0.6c-0.1,0.1-0.5,0.3-0.9,0.5
c-1.7-0.2-5.4-0.7-6.3-0.9c-0.4-0.1-0.8-0.1-1.3-0.1c-0.3,0-0.7,0-1,0.1l-1.6-1.8c-0.1-0.2-0.5-0.8-0.7-1.1l0.1-0.1
c0.2-0.3,0.3-0.8,0.1-1.2l-0.5-0.9c0.1-0.1,0.2-0.1,0.3-0.2c0.3-0.3,0.4-0.6,0.3-1l-0.4-2.3l4.8-11.1c1.2-0.3,1.5-0.7,1.6-0.9
c0.3-0.4,0.8-0.9,0.9-1s0.3-0.1,0.5-0.1s0.3,0,0.5,0c0.3,0,0.7,0.1,1,0.1c1.8,0,2.4-0.5,2.6-0.7s0.4-0.4,0.6-0.5l0.4-0.1
c0.3,0.2,0.8,0.3,1.3,0.3c0.1,0,0.1,0,0.2,0l0,0c0.4,0,1.4,0.2,1.9,0.3h0.1c0.1,0,0.9,0.2,1.7,0.2c0.4,0,0.9,0,1.4-0.3l0,0
c0.2,0,0.5,0.1,0.6,0.1s0.2,0,0.3,0c0.3,0,0.6-0.1,0.8-0.4c0.3-0.3,0.7-0.7,0.9-0.8c0.3,0.1,0.8,0.3,1.2,0.3c0.3,0,0.6-0.1,0.9-0.2
c0.1-0.1,0.2-0.1,0.3-0.1c0,0,0,0,0.1,0c0.5,0.2,2.4,1.1,2.7,1.3c0.5,0.3,1.5,0.5,2.2,0.5h0.1c0.1,0,0.1,0,0.2,0l0.8-0.1l0.6,0.5
c0.1,0.1,0.2,0.1,0.4,0.2l1.7,0.5c0.1,0.4,0.3,0.7,0.8,1c0,0,0.1,0,0.1,0.1c0,0.3,0.1,0.8,0.1,1c0,0.3,0.1,0.5,0.3,0.7l0.7,0.7
c0.2,0.2,0.5,0.3,0.8,0.3c0,0,0,0,0.1,0c0,0.8,0.2,1.9,0.9,2.5c0.3,0.3,0.7,0.6,1,0.8c-0.2,0.3-0.3,0.7-0.1,1.1l0.3,0.7
c0.2,0.4,0.6,0.7,1.1,0.7h0.1c0.2,0.5,0.4,0.9,0.9,1.2c-0.1,0.4-0.1,0.9-0.1,1c0,0.6,0.5,1.1,1,1.2l0.7,0.1l0.8,0.5
c0.2,0.1,0.4,0.1,0.5,0.1s0.3,0,0.4-0.1l0.2-0.1c0.4,0.4,0.9,0.7,1.5,0.7c0.1,0,0.2,0,0.3,0h0.2c0.2,0,0.4-0.1,0.5-0.1s0.1,0,0.2,0
s0.4,0.2,0.7,0.5c0.1,0.1,0.1,0.1,0.1,0.1l0.1,0.1c0.1,0.1,0.2,0.2,0.3,0.3c0,0.5,0.1,1,0.4,1.4c0.3,0.3,0.7,0.5,1.1,0.5
c0.2,0,0.5-0.1,0.9-0.3h0.1c0.4,0.8,0.9,1.7,1,1.9c0.2,0.5,0.5,1.6,1.6,1.6c0.3,0,0.5-0.1,0.7-0.2l0,0l0.4,0.2
c0.3,1.1,1,2.2,2.3,2.2c0.9,0,2.3,0.2,2.3,0.2c0.1,0,0.1,0,0.1,0c0.1,0,0.2,0,0.3,0c0,0,0.1,0,0.3,0c0.1,0.3,0.3,0.5,0.5,0.7
l-0.1,0.1c-0.3,0.3-0.3,0.8-0.2,1.2l0.5,1.2l-0.4,0.9c-0.1,0.1-0.1,0.2-0.1,0.3c0,0.5-0.1,1.3-0.2,1.9c-0.1,0-0.3,0-0.5,0
c-0.3,0-0.6,0-0.6,0c-0.2,0-0.3,0.1-0.5,0.2l-1.2,0.8c-0.3,0.2-0.5,0.6-0.5,1v1.4c-0.1,0.1-0.2,0.3-0.2,0.3c-0.3,0.3-0.8,1.2-1,1.5
c-0.1,0.2-0.1,0.3-0.2,0.5c-0.1-0.1-0.2-0.2-0.3-0.4c-0.4-0.5-0.9-0.7-1.5-0.7c-0.1,0-0.2,0-0.4,0c-0.1,0-0.2,0-0.3,0
c-0.7,0-1.2,0.3-1.5,0.7l-1-0.1h-0.1c-0.3,0-0.7,0.2-0.9,0.4c-0.1,0.1-0.9,1.1-1.3,1.9c-0.4,0.9-0.3,1.5-0.2,1.9
c0,0.4,0.1,0.9,0.3,1.3c0,0.1,0,0.3,0,0.3c-0.3,0.4-0.3,1-0.3,1.5c-0.5,0.3-1,0.5-1.2,0.6c-0.3,0.1-0.9,0.2-1.3,0.3
c-0.3-0.1-0.7-0.2-1.1-0.3c-0.2-0.1-0.3-0.1-0.5-0.1c-0.7,0-1.1,0.4-1.3,0.6c-0.3-0.5-0.8-0.9-1.5-0.9c-0.5,0-1.1,0.2-1.4,0.6
c-0.1,0.1-0.1,0.1-0.2,0.2c-0.6,0.5-1,1-1.2,1.4c-0.1,0.1-0.1,0.1-0.1,0.1l0,0c0,0-0.1,0.1-0.4,0.1l-0.2,0.1c-0.1,0-0.3,0.1-0.4,0.1
c0,0-0.1,0-0.1-0.1c-0.4-0.3-0.8-0.5-1.3-0.5c-0.9,0-1.7,0.6-1.9,1.4c0,0-0.1,0.1-0.3,0.2c-0.1,0.1-0.3,0.2-0.9,0.3
C303.5,548.5,302.7,549.1,302.4,549.9z`
  },
  {
    id: "DaNang",
    name: "Đà Nẵng",
    d: `M292.3,391c0.1-0.1,0.1-0.1,0.1-0.1c1-0.6,1-1.9,0.6-2.8c-0.1-0.2-0.2-0.3-0.3-0.5l-0.4-0.3
l0.1-0.6c0.1-0.4-0.1-0.8-0.5-1.1c-0.2-0.1-0.4-0.2-0.7-0.2c-0.2,0-0.3,0-0.5,0.1l-2,0.9l-0.7-0.5c-0.2-0.1-0.4-0.2-0.6-0.2l0,0
c0.1-0.1,0.2-0.2,0.3-0.3c0.2-0.2,0.3-0.4,0.4-0.7l0.2-0.9c0.5-0.4,0.9-1,0.8-1.8c0-0.2,0-0.3-0.1-0.5l0,0c0.6,0.4,1.4,0.8,2.1,0.8
c0.3,0,0.6-0.1,0.8-0.2c0.3-0.1,0.5-0.3,0.7-0.5c0.6,0.4,1.4,0.8,2,0.9c0.8,0.1,2.3,0.1,2.3,0.1c0.1,0,0.1,0,0.2,0
c0.5-0.1,0.9-0.2,1.2-0.2l-0.1,0.2c-0.1,0.2-0.1,0.3-0.1,0.5v1.2c0,0.3,0.1,0.6,0.3,0.8l0.7,0.7c0.1,0.1,0.1,0.1,0.2,0.2l0.7,0.4
l0.5,0.6c0.2,0.3,0.5,0.4,0.8,0.4h0.1c0.1,0,0.2,0,0.3,0l-0.1,0.2l-0.3,0.3l-0.4,0.3c-0.5,0.3-0.7,0.9-0.4,1.5c0,0,0,0.1,0.1,0.1
c-0.1,0-0.1,0.1-0.2,0.1l-0.3,0.3l-0.3-0.1c-0.2-0.1-0.3-0.1-0.5-0.1c-0.1,0-0.3,0-0.4,0.1c-0.3,0.1-0.5,0.3-0.7,0.6l-0.3-0.2
c-0.2-0.1-0.4-0.1-0.5-0.1c-0.3,0-0.6,0.1-0.8,0.4l-0.5,0.6c-0.3-0.2-0.7-0.3-1.2-0.3c-0.7,0-1.4,0.2-2,0.5L292.3,391z`
  },
  {
    id: "CanTho",
    name: "Cần Thơ",
    d: `M186.7,638.6l-0.1-0.6c0-0.3-0.2-0.5-0.4-0.7l-1.7-1.6c-0.2-0.2-0.5-0.3-0.8-0.3
c-0.1,0-0.2,0-0.3,0.1l-1.6-1.6l0.2-0.3h0.3c0.3,0,0.6-0.2,0.7-0.4l0.3-0.4l0.4,0.7c0.2,0.3,0.6,0.5,1,0.5c0.1,0,0.3,0,0.4-0.1
l4.2-1.5c0.6-0.2,0.9-0.8,0.7-1.4l0,0l1.4-0.3c0.2,0.2,0.4,0.3,0.6,0.4c0.3,0.2,1.2,1.1,1.5,1.4c0.2,0.2,0.3,0.3,0.4,0.4
c0.4,0.3,0.8,0.8,0.9,1c0.2,0.7,0.9,2,0.9,2c0.2,0.3,1.6,1.6,2.1,2.1c0.4,0.3,0.5,0.5,0.6,0.5c0.1,0.1,0.2,0.2,0.3,0.3
c0.7,0.7,1.4,1.2,1.8,1.4c0.3,0.1,1.7,1.1,2,1.3c0.2,0.2,0.7,0.7,1.1,1.1c0,0.1,0.1,0.3,0.1,0.5l-0.2,0.1l-1.5,0.3l-1.3-0.6
c-0.2-0.1-0.3-0.1-0.5-0.1c-0.4,0-0.8,0.2-1,0.6l-0.1,0.3c-0.1-0.1-0.3-0.3-0.4-0.3l-0.9-0.4c-0.1-0.1-0.3-0.1-0.5-0.1
c-0.3,0-0.6,0.1-0.8,0.3l-0.3,0.3l-0.8-0.9c-0.2-0.3-0.5-0.4-0.9-0.4c-0.1,0-0.3,0-0.4,0.1l-0.8,0.3c-0.3,0.1-0.5,0.3-0.7,0.7
l-0.4,1.1L186.7,638.6z`
  },
  {
    id: "CaoBang",
    name: "Cao Bằng",
    d: `M230.4,119.8c-0.1,0-0.5-0.1-0.7-0.3c-0.3-0.1-0.7-0.2-1-0.2c-0.6,0-1.2,0.2-1.6,0.3
c0-0.1,0-0.1,0-0.2c0-1.1-1.2-1.6-2-1.8c-0.3-0.2-0.7-0.5-0.8-0.6c-0.4-0.4-0.9-0.8-1.2-1c-0.5-0.3-1.1-0.4-1.6-0.4
c-0.3,0-0.5,0-0.7,0.1c-0.5,0.2-0.9,0.6-1.6,1.6l-0.1,0.2c-0.1,0.1-0.1,0.1-0.1,0.2c-0.1,0-0.2-0.1-0.3-0.1c-0.2,0-0.3,0-0.5,0.1
l-0.6,0.3l-0.1-0.5c0.2-0.3,0.5-0.6,0.6-0.9c0.5-0.9,0.1-2.2,0.1-2.4c-0.1-0.4-0.5-0.7-0.9-0.8h-0.1c-0.1-0.2-0.1-0.3-0.1-0.4
c-0.1-0.4-0.3-1.2-0.3-1.2c-0.1-0.5-0.5-0.8-0.9-0.9l-1.2-0.2c-0.1,0-0.1,0-0.2,0c-0.2,0-0.3,0-0.5,0.1l-1.5,0.8l0,0
c-0.7,0.4-1.6,0.9-1.8,1s-0.8,0.2-1.2,0.2c-0.3,0.1-0.5,0.2-0.7,0.4l-0.3,0.4c-0.2-0.1-0.3-0.2-0.4-0.3c-0.5-0.3-1.3-0.4-2.2-0.4
c-0.1,0-0.3,0-0.6-0.1c-0.1-0.1-0.1-0.3-0.2-0.3c-0.2-0.4-0.5-0.7-0.7-0.8c-0.1-0.6-0.4-1-0.8-1.2c0-0.4-0.1-1-0.4-1.4
c0.3-0.3,0.5-0.6,0.8-0.9c0.5-0.7,0.4-1.3,0.3-1.6c-0.1-0.3-0.4-0.6-0.6-0.7c-0.1-0.5-0.2-1-0.6-1.4c-0.4-0.4-0.9-0.5-1.2-0.5
c-0.7,0-1.1,0.4-1.3,0.6l0,0c-0.2,0-0.5,0.1-0.8,0.1c-0.1,0-0.1,0-0.2,0c-1.2,0.2-1.8,0.6-2,0.9l0,0c-0.7,0-1.6,0.3-2,1.1
c-0.3,0.1-0.6,0.3-0.9,0.4c-0.1,0.1-0.2,0.1-0.2,0.1c-0.5,0.1-1,0.4-1.2,0.9c0-0.1-0.1-0.1-0.1-0.2l0,0l0.3-0.4
c0.3-0.4,0.2-1-0.1-1.4c-0.3-0.3-0.6-0.7-0.7-0.9v-0.5c0-0.4-0.2-0.7-0.5-0.9c-0.6-0.4-1.4-1-1.6-1.2c-0.4-0.5-1.6-1.6-2.4-1.8
c-0.2-0.1-0.5-0.1-0.6-0.1s-0.1,0-0.2,0c-0.1,0-0.1,0-0.2,0c-0.1,0-0.2,0-0.2,0c-0.3-0.3-1.1-0.8-1.4-1.1c0.1-0.1,0.1-0.2,0.1-0.3
c0.1-0.3,0.1-0.6,0.2-0.8c0.6-0.2,1.2-0.6,1.5-0.8c0.3-0.2,0.5-0.6,0.5-0.9c0-0.4,0-0.8,0-1.1c0.7-0.1,1.7-0.3,2.2-0.4l2.1-0.4
c0.4-0.1,0.8-0.4,0.9-0.8c0.2-0.7,0.5-1.6,0.6-1.8c0.1-0.2,0.2-0.6,0.4-1.1c0.1,0.1,0.2,0.3,0.3,0.4c0.3,0.4,0.8,0.6,1.3,0.6
c0.8,0,1.8-0.1,2.4-0.3c0.2,0.1,0.4,0.3,0.7,0.4c0.2,0.1,0.4,0.2,0.7,0.2c0.1,0,0.2,0,0.3-0.1c0.1,0,0.4-0.1,0.7-0.1
c0.1,0.7,0.3,1.1,1.2,1.4c0.1,0,0.1,0.1,0.2,0.1c0.4,0.2,0.8,0.3,1.2,0.3c0.2,0,0.4,0.1,0.5,0.1c0.3,0.3,0.6,0.5,1.1,0.9
c0.1,0.1,0.3,0.3,0.4,0.3c0.1,0.1,0.3,0.6,0.4,0.9c0.1,0.3,0.3,0.5,0.5,0.6h0.1l0.1,0.6c0.1,0.4,0.4,0.8,0.8,0.9
c0.1,0,0.2,0.1,0.3,0.1c0.3,0,0.6-0.1,0.8-0.4c0.2-0.2,0.8-0.7,1.4-0.7c0.1,0,0.1,0,0.1,0c0.4,0.1,0.7,0.1,1,0.1
c0.5,0,0.9-0.1,1.1-0.2c0.5,0.1,0.9,0.1,0.9,0.1c0.6,0,1.2-0.5,1.2-1.2v-1.1c0.5-0.1,1.1-0.2,1.5-0.2c0.1,0,0.2,0,0.2,0
c0.5,0.2,1.2,0.3,2.2,0.3c0.3,0,0.5,0,0.7,0c0.1,0,0.2,0,0.3,0c0.2,0,0.7,0,1.1,0.1c0.3,0.4,0.7,0.9,1.4,1.1c0.1,0.3,0.2,0.7,0.6,1
c0,0.1-0.1,0.3-0.1,0.4c-0.2,0.5,0,1.2,0.5,1.5c0.3,0.2,1.4,0.7,2.3,0.7c0.3,0,1.2,0.3,2.1,0.6c0.1,0.1,0.3,0.1,0.4,0.1
c0.2,0,0.3,0,0.5-0.1c0.3-0.1,0.5-0.4,0.6-0.7l0.1-0.3c0.2,0,0.4,0,0.6,0c0.2,0,0.4,0,0.6-0.1c0.5-0.1,1.4-0.4,1.7-0.5l2.4-0.6
c0.3-0.1,0.6-0.3,0.7-0.5c0.1-0.2,0.2-0.5,0.1-0.7c0.3,0.3,0.8,0.6,1.4,0.6c0.1,0,0.3,0,0.4,0c0.1,0,0.1,0,0.2,0
c0.4,0.6,0.9,1.5,0.9,1.5c0,0.1,0.1,0.1,0.1,0.2l0.8,0.9c0.2,0.2,0.5,0.4,0.8,0.4l0,0c0.3,0,0.6-0.1,0.8-0.3l0.6-0.6
c0,0,0,0,0.1-0.1c0.1,0,0.1,0.1,0.2,0.1c0.3,0.8,0.7,1.2,1.3,1.6c0.5,0.3,1.6,1,1.6,1c0.2,0.1,0.4,0.2,0.6,0.2c0.1,0,0.1,0,0.2,0
c0,0,0.2,0,0.4,0c-0.5,0.2-1.1,0.5-1.7,1.1c-0.4,0.3-0.5,0.9-0.3,1.3l0.3,0.9c-0.3,0.5-0.7,1-0.9,1.3c-0.4,0.5-1,1.2-1,1.2
c-0.2,0.2-0.3,0.5-0.3,0.7v1.2H240l-0.8-0.7c-0.2-0.2-0.5-0.3-0.8-0.3c-0.1,0-0.1,0-0.2,0l-1.2,0.2c-0.3,0.1-0.6,0.2-0.8,0.5
s-0.3,0.6-0.2,0.9c0.1,0.4,0.1,0.9,0.1,1.2c-0.3,0.4-0.6,0.9-0.6,0.9c-0.1,0.2-0.2,0.4-0.2,0.6v1.6l-0.7,0.3
c-0.5,0.3-0.7,0.7-0.6,1.3l0.1,0.4c-0.4,0.1-0.7,0.3-0.9,0.6l-0.1,0.1H232c-0.4,0-0.8,0.3-1,0.6c-0.1,0.1-0.3,0.6-0.3,1.1l-0.1,0.1
C230.5,119.8,230.4,119.8,230.4,119.8z`
  },
  {
    id: "CaMau",
    name: "Cà Mau",
    d: `M165.4,702L165.4,702c-0.1-0.1-0.3-0.2-0.3-0.3l-0.3-0.1c-0.1-0.1-0.2-0.1-0.3-0.1l-0.6-0.1l0.8-0.7
l0.2-0.1h0.2c0.1,0,0.2-0.1,0.3-0.1l0.3-0.2c0.2-0.1,0.4-0.4,0.5-0.6l0.1-0.4c0-0.1,0-0.1,0-0.2l1.6,0.3c0.1,0,0.1,0,0.2,0
s0.2,0,0.3,0l0.6-0.1c0.1,0,0.2-0.1,0.3-0.1l0.7-0.3c0.1,0,0.1-0.1,0.2-0.1l0.6-0.5c0.1-0.1,0.2-0.2,0.3-0.3l0.3-0.5
c0.3-0.4,0.2-1-0.1-1.4c-0.2-0.3-0.5-0.4-0.9-0.4c-0.1,0-0.2,0-0.3,0c0,0,0,0,0-0.1v-0.3c0-0.2-0.1-0.4-0.2-0.6l-0.3-0.5v-0.4
l0.1-0.6c0.1-0.4,0-0.9-0.4-1.2c-0.2-0.2-0.5-0.3-0.7-0.3c-0.1,0-0.3,0-0.5,0.1l-2.5,1.1l-0.8,0.2l-0.1-0.1l0.1-0.5l0.5-1.7
c0-0.1,0-0.1,0.1-0.2l0.1-0.9c0-0.1,0-0.2,0-0.3l-0.3-2v-0.3l0.3-0.3c0.3-0.2,0.5-0.6,0.5-0.9v-0.3c0-0.4-0.2-0.7-0.5-1l-0.3-0.2
l-0.1-0.5v-4.8l0.3-2.5v-0.1l0.3-6.1l0.6-1.8c0-0.1,0.1-0.3,0.1-0.4l-0.2-3.6c0.1,0,0.1,0,0.2,0h0.1c0.4,0,0.7-0.3,0.9-0.6l0.7-1.2
c0.2,0.2,0.5,0.4,0.8,0.4l0,0c0.3,0,0.6-0.1,0.8-0.4l0.2-0.2l0.3,0.2c0.2,0.1,0.4,0.1,0.6,0.1c0.1,0,0.2,0,0.3-0.1
c0.2-0.1,0.4-0.2,0.5-0.4l9.3,6.1c-0.2,0.2-0.4,0.4-0.5,0.5c-0.6,0.8-0.5,2,0.3,2.9c0.1,0.1,0.3,0.4,0.5,0.6l-0.2,0.1
c-0.1,0.1-0.1,0.1-0.2,0.2c-0.1,0.1-0.5,0.7-0.7,0.9c-0.1,0.1-0.2,0.1-0.3,0.2c-0.5,0.3-0.8,0.9-0.5,1.5l0.4,0.9
c0.1,0.3,0.4,0.6,0.8,0.7l0.1,0.2c0,0.1,0.1,0.2,0.2,0.3c-0.1,0.3-0.4,0.7-0.5,1c-0.2,0.5-0.2,1.3-0.2,1.9c0,0.4,0.1,0.7,0.5,0.9
c0.2,0.1,0.4,0.2,0.6,0.2c0.1,0,0.2,0,0.3,0c0.3,0.2,0.6,0.3,1,0.3c0.1,0,0.2,0,0.3,0s0.1,0,0.1,0c0.5,0.3,1.2,0.6,1.3,0.7
c0.1,0.1,0.3,0.1,0.5,0.1c0.1,0,0.2,0,0.3,0c0,0,0.1,0,0.2,0c0.3,0.3,0.7,0.5,1.4,0.5c0.2,0,0.3,0,0.4,0c0,0,0,0,0.1,0
c0,0,0,0,0.1,0.1s0.1,0.4,0.3,0.9c-0.1,0.1-0.1,0.2-0.1,0.3v0.2c0,0.1,0,0.2,0,0.3v0.3l-2.7,6.4l-0.5,0.8l-1,1.1l-0.8,0.5l-0.6-0.1
c-0.1,0-0.1,0-0.2,0c-0.3,0-0.5,0.1-0.7,0.3c-0.2,0.1-0.3,0.4-0.4,0.6l-2.2,1.8c-0.1,0.1-0.2,0.2-0.3,0.3l-1.2,2l-0.8,0.6l-1.3,0.5
l-8.6,1.8L165.4,702z`
  },
  {
    id: "BinhThuan",
    name: "Bình Thuận",
    d: `M276.4,617c-0.3-0.6-1-2-1.3-2.4c0-0.2,0-0.6,0-0.9c0-0.7,0-1.6-0.9-2.5
c-0.2-0.2-0.3-0.3-0.5-0.4l0.2-0.1c0.6-0.3,1.7-1.4,2.1-2.1c0.6-1,0.1-2.3-0.5-2.9c0-0.3,0-0.9,0-1.2c0-0.2,0-0.4,0-0.5
c0-0.4-0.1-2-0.7-2.8c-0.1-0.1-0.1-0.2-0.2-0.4c-0.2-0.4-0.5-1-1-1.4c-0.3-0.3-0.8-0.7-1.6-0.7c-0.3,0-0.6,0.1-0.9,0.2l-0.1-0.1
c-0.1-0.1-0.3-0.3-0.5-0.4c-0.1-0.1-0.3-0.2-0.5-0.3l0.1-0.1c0.2-0.3,0.6-0.8,0.8-1.5c0.2-0.7,0.2-1.2-0.1-1.6l0,0
c0.3-0.3,0.5-0.6,0.6-1c0.1-0.1,0.3-0.5,0.4-0.8c0.4-0.7,0.7-1.3,0.9-1.7c0.1,0,0.2-0.1,0.3-0.1c0.6-0.1,1.1-0.6,1-1.3
s-0.1-1.5-0.1-1.8c0-0.1,0.1-0.2,0.1-0.3c0.2-0.6,0.3-1.1,0.4-1.6l0,0c0.1-0.1,0.2-0.2,0.3-0.3c0.2,0.2,0.4,0.4,0.6,0.6
c0.5,0.5,1.3,0.6,2,0.7c0.3,0.4,0.8,0.7,1.4,0.7s2.2-0.1,2.9-1c0.2-0.3,0.4-0.6,0.5-0.9c1,0,2.7,0.1,3.7,0.1c1.5,0,3.4,0.2,3.9,0.3
c0.4,0.1,1.8,0.6,2.8,0.9c0.2,0.9,0.5,1.9,0.7,2.3c0,0.2,0,1-0.1,1.6c-0.1,0.4,0.1,0.9,0.5,1.1c0.2,0.1,0.4,0.2,0.6,0.2
s0.4-0.1,0.6-0.2l0.8-0.5c0.3-0.2,0.6-0.6,0.5-1c0-0.1,0.1-0.2,0.1-0.3c0.7-0.4,1.6-1.2,1.8-1.4c0.2-0.1,0.5-0.3,0.7-0.3
c0.8,0,1.7-0.5,2-0.6c0.1,0,0.1-0.1,0.1-0.1s1.6-1.2,2.3-1.7c0.8-0.5,1.4-1.1,1.8-1.6c0.2-0.3,0.4-0.6,0.5-0.6
c0.8-0.5,1-1.4,0.6-2.3c0-0.1-0.1-0.1-0.1-0.2c-0.1-0.3-0.5-1.2-1.5-1.2c0.2-0.2,0.4-0.5,0.5-0.8c0.2-0.2,0.6-0.4,0.7-0.5
c0.1,0,0.1-0.1,0.2-0.1s0.1,0,0.2,0v0.1c0.1,0.5,0.6,0.9,1.1,0.9c0.1,0,0.1,0,0.1,0l0.9-0.1c0.4-0.1,0.8-0.3,0.9-0.7
c0.1,0,0.1,0,0.2,0s0.2,0,0.3,0s0.1,0,0.1,0c0.3,0.3,0.8,0.4,1.5,0.4c0.5,0,1-0.1,1.1-0.1c0.2,0,0.3-0.1,0.5-0.2
c0.1-0.1,0.3-0.1,0.3-0.1c0.5,0.4,1.1,0.5,1.7,0.5s1.1-0.2,1.2-0.2c0.2-0.1,0.4-0.3,0.6-0.5l0.6-1h1.2c0.6,2.2,1,2.5,1.1,2.7
c0.6,0.6,1.5,0.9,2.4,0.9c0.3,0,0.7,0,1-0.1l-0.1,1.3c0,0.4,0.2,0.8,0.5,1l1.2,0.8c0.1,0.1,0.2,0.1,0.3,0.1l1.7,0.5l0.1,0.1
l-0.4,0.2c-0.1,0-0.1,0.1-0.2,0.1l-1.4,1c-0.1,0-0.1,0.1-0.1,0.1l-0.5,0.5c-0.1,0.1-0.1,0.1-0.1,0.2l-0.3,0.5c0,0.1-0.1,0.1-0.1,0.2
l-0.3,0.8c0,0.1-0.1,0.1-0.1,0.2l-0.5,2l-0.2,0.4H322h-0.1l-0.7-0.4c-0.1-0.1-0.3-0.1-0.4-0.1l-0.5-0.1h-0.1c-0.1,0-0.2,0-0.2,0
l-0.4,0.1c-0.1,0-0.2,0.1-0.3,0.1l-0.7,0.3l-0.2,0.1H317c-0.2,0-0.4,0.1-0.6,0.2L316,592c-0.1,0.1-0.2,0.1-0.3,0.2l-0.3,0.3
l-0.8,0.3c-0.1,0.1-0.3,0.1-0.4,0.2l-0.7,0.6c-0.1,0.1-0.1,0.1-0.2,0.2l-0.6,0.8c0,0.1-0.1,0.1-0.1,0.1l-0.3,0.7
c-0.1,0.1-0.1,0.2-0.1,0.3l-0.3,1.4l-0.5,0.1c-0.1,0-0.2,0.1-0.3,0.1l-0.3,0.1c0,0,0,0-0.1,0l-1.3,0.7h-0.2l-0.7,0.1
c-0.1,0-0.3,0.1-0.4,0.1l-0.3,0.1c-0.2,0.1-0.4,0.3-0.5,0.5l-0.3,0.5c0,0.1-0.1,0.1-0.1,0.2l-0.4,1.6h-0.1c-0.2,0-0.4,0.1-0.6,0.2
l-0.3,0.3c-0.1,0-0.1-0.1-0.2-0.1l-1.1-0.3c-0.1,0-0.2-0.1-0.3-0.1c-0.1,0-0.1,0-0.2,0l-5,1c-0.1,0-0.1,0-0.1,0l-1.2,0.4
c-0.2,0.1-0.4,0.2-0.6,0.4l-0.5,0.7c0,0.1-0.1,0.1-0.1,0.2l-0.1,0.3l-0.8,0.9c-0.1,0.1-0.2,0.2-0.2,0.3l-0.2,0.6
c0,0.1-0.1,0.1-0.1,0.2l-0.1,0.4l-0.5,1.1c-0.1,0.1-0.1,0.2-0.1,0.3l-0.3,1.6l-1,2l-1.6-0.5c-0.1,0-0.1,0-0.2,0l-0.7-0.1
c-0.1,0-0.1,0-0.1,0h-0.9c-0.1,0-0.1,0-0.2,0l-0.6,0.1c-0.1,0-0.1,0-0.2,0.1l-2.4,1c-0.1,0.1-0.2,0.1-0.3,0.2l-1.6,1.4l-0.3,0.2
l-1.4,0.5c-0.1,0-0.1,0.1-0.2,0.1l-2.4,1.3L279,616c-0.1,0-0.2,0-0.3,0.1l-0.9,0.3c-0.1,0-0.1,0.1-0.2,0.1l-1,0.6L276.4,617z`
  },
  {
    id: "BinhPhuoc",
    name: "Bình Phước",
    d: `M250.7,585.5c-0.5-0.8-1.1-1-1.5-1.1c-0.2,0-0.3,0-0.5,0c-0.7,0-1.2,0.2-1.4,0.5
c-0.2-1.4-1.2-1.4-1.5-1.4s-0.5,0-0.7,0s-0.3,0-0.5,0c-0.1,0-0.2,0-0.4,0c0-0.1-0.1-0.1-0.1-0.2c0.2-0.1,0.4-0.3,0.6-0.6
c0.1-0.1,0.1-0.1,0.1-0.1c0.5-0.5,0.6-1.2,0.4-2c-0.1-0.3-0.1-0.6-0.1-0.6c0-0.5-0.4-0.9-0.9-1c-0.1,0-0.3-0.1-0.6-0.1
c-0.4,0-0.8,0.1-1.1,0.2l0,0c-0.2,0.1-0.4,0.2-0.4,0.2c-0.5,0.1-0.9,0.4-1.1,0.9c-0.2,0.4-0.2,0.8-0.1,1.2l0,0
c-0.4,0.2-0.8,0.5-1.1,1c0,0.1-0.1,0.1-0.1,0.2c-0.1,0-0.1,0-0.2,0c-0.5,0-1,0.3-1.3,0.7c-0.2,0.3-0.2,0.6-0.2,0.9
c-0.1,0-0.1,0-0.2,0c0,0-0.1,0-0.2,0c-0.2-0.7-0.8-1.2-1.5-1.2c-0.3,0-0.6,0.1-0.9,0.3c0,0-0.1,0-0.1,0.1c0-0.1,0-0.2,0-0.3
c0.5-0.3,0.8-0.8,1-1.3s0.1-0.9-0.2-1.3c-0.2-0.3-0.6-0.8-0.7-0.8c-0.2-0.3-0.5-0.4-0.9-0.4h-0.1c-0.3,0-0.5,0.1-0.7,0.3
c-0.1-0.1-0.2-0.1-0.3-0.2c-0.6-0.5-0.9-0.7-1.3-0.8c-0.1,0-0.3-0.1-0.4-0.1c0.2-0.1,0.3-0.3,0.5-0.4c0.6-0.6,0.7-1.5,0.6-2.1l0,0
c-0.1-0.5-0.1-1.5-1-2l0,0l0,0l0,0c0-0.1,0-0.1-0.1-0.1l-0.4-1v-0.1c0-0.1,0.1-0.2,0.1-0.3c0.1-0.9-0.2-1.7-0.9-2
c0-0.6-0.5-1.5-0.7-2c0.1-0.3,0.4-0.9,0.7-1.2c0.6-0.7,0.7-1.5,0.5-2c0.3-0.1,0.5-0.3,0.6-0.5c0.2-0.3,0.2-0.7,0.1-1l-1.3-2.9
c0.2,0.1,0.5,0.1,0.7,0.1c0.3,0,0.5-0.1,0.8-0.1c0.1,0,0.1,0,0.2,0c0.1,0,0.1,0,0.1,0c0.1,0,0.3,0,0.5,0.1c0.5,0.2,1.4,0.3,3.4,0.3
c0.1,0,0.2,0,0.3,0s0.2,0,0.3,0c0.3,0,1.1,0,1.6-0.4c0.5,0.3,0.9,0.4,1.6,0.4c1.2,0,2.1-0.9,2.3-1.6c0.1-0.2,0.5-1,0.9-1.7
c0.1-0.1,0.7-1,1-1.6l0,0c0.2,0,0.5,0.1,0.5,0.1h0.1c0.3,0.1,0.9,0.3,1.7,0.3c0.2,0,1,0.2,1.7,0.5c0.1,0.1,0.3,0.1,0.4,0.1
s0.3,0,0.5-0.1c0.1,0,1.9-0.8,2.9-1.4c0.6-0.4,0.9-0.7,1.2-1c0.2-0.2,0.2-0.2,0.3-0.2c1.1-0.4,1.9-1.9,2.1-2.3c0,0,0,0,0-0.1
c0.1-0.2,0.3-0.6,0.6-0.7c0.7-0.4,1-0.9,1.2-1.2c0.9-0.3,1.4-1.2,1.4-2.4c0.1-0.1,0.3-0.2,0.4-0.3c0.1,0,0.1-0.1,0.1-0.1l0.3,2.1
l0.3,2.6l-0.1,1.4c0,0.4,0.1,0.7,0.4,1c-0.1,0.2-0.1,0.3-0.1,0.4c-0.1,0.1-0.1,0.5-0.1,2.4c0,0.3,0.1,0.6,0.3,0.9
c0.2,0.2,0.5,0.3,0.7,0.3h0.1c0.1,0,0.2,0.1,0.5,0.2c0.6,0.3,1.2,0.6,1.9,0.6c0.2,0,0.6,0.2,0.9,0.4c0.4,0.3,0.9,0.7,0.9,0.9
s0,0.3,0,0.4c0,0.3,0,1,0.7,1.5c0.3,0.2,0.7,0.6,0.9,0.8c0,0.2,0.1,0.5,0.1,0.7c0.1,0.4,0.3,0.7,0.6,0.9c0,0,0.1,0,0.1,0.1
c-0.2,0.4-0.3,0.8-0.4,1c0,0.1-0.1,0.1-0.1,0.2c-0.2,0.3-0.5,0.9-0.5,1.4c-0.4,0.1-0.9,0.3-1.2,0.5c-0.2,0.1-0.6,0.3-1.4,0.4
c-1.4,0.2-2,1.7-2,2.7c0,0.1-0.1,0.4-0.2,0.5c-0.6,0.6-0.7,1.6-0.3,2.6c0.3,0.7,0.8,1,1.2,1.2c0,0.1,0,0.1,0,0.2
c0,0.4,0,0.9,0.2,1.3l0.1,0.1c0,0.1,0.1,0.2,0.1,0.3c-2,0.4-4.2,0.9-4.9,1c-1.1,0.2-1.8,0.8-2.2,1.4l-0.4,0.1
c-0.3,0.1-0.5,0.3-0.7,0.5l-0.2,0.3c-0.1,0.1-0.2,0.1-0.3,0.2c-0.7,0.5-1.1,1.5-0.9,2.2c0,0.1,0.1,0.2,0.1,0.3
c-0.1,0.1-0.2,0.3-0.3,0.5c-0.1,0.1-0.1,0.3-0.1,0.4c-0.3,0.2-0.6,0.5-0.8,0.8c-0.2,0.1-0.5,0.2-0.7,0.4c-0.1,0.1-0.1,0.1-0.2,0.2
c0,0,0,0-0.1,0.1l0,0C251.2,585.3,250.9,585.4,250.7,585.5z`
  },
  {
    id: "BinhDuong",
    name: "Bình Dương",
    d: `M242.2,604.1c-0.9-0.4-1-0.4-1.2-0.4c-0.1,0-0.3,0-0.4,0.1l0,0c0,0,0-0.1-0.1-0.1
c-0.3-0.7-0.7-1.1-1.3-1.1h-0.1c0-0.1,0-0.1,0-0.1s0-0.1,0-0.2c0-1.2-0.6-1.7-1.2-1.9c0-0.3,0-0.5-0.2-0.8c-0.1-0.3-0.4-0.5-0.6-0.6
c0-0.2,0-0.5-0.1-0.7c-0.2-0.5-0.7-0.9-1.3-0.9c-0.1,0-0.2,0-0.3,0c-0.2-0.1-0.5-0.2-0.7-0.2s-0.3,0-0.5,0.1c0.1-0.3,0.1-0.7,0-1
c0-0.1-0.1-0.2-0.1-0.3c0.1-0.6-0.3-1.1-0.5-1.3c0-0.1-0.1-0.1-0.1-0.2c-0.1-0.3-0.5-1.1-1.4-1.1c-0.1,0-0.1,0-0.2,0
c-0.1,0-0.3,0-0.4,0c-0.2-0.4-0.7-0.7-1.2-0.7c-0.1,0-0.2,0-0.3,0c-0.3-0.3-0.6-0.5-1-0.5c-0.1-0.2-0.3-0.3-0.6-0.4
c0-0.6-0.3-1.1-0.4-1.3l0,0c-0.1-0.3-0.4-0.8-0.8-1.1c0-0.3,0.1-0.8-0.3-1.3c-0.1-0.1-0.2-0.2-0.4-0.3c0.2-0.7,0.1-1.3-0.2-1.7
c0.3-0.4,0.4-0.9,0.4-1.1c0,0,0,0,0-0.1c0.5-0.6,0.7-1.5,0.7-1.9c0-0.1,0.1-0.4,0.1-0.6c0.7-0.3,1-0.4,1.2-0.5
c0.1-0.1,0.4-0.3,0.7-1c0.3,0,0.6-0.1,0.7-0.1c0.1,0,0.2,0,0.3,0c0.2,0,0.5,0,0.7,0.1c0.1,0.1,0.4,0.3,0.6,0.5
c0.3,0.2,0.5,0.4,0.7,0.5c0.1,0.1,0.1,0.1,0.2,0.1c-0.1,0.2-0.3,0.4-0.3,0.5c-0.1,0.4-0.1,0.8,0.2,1.2c-0.1,0.4-0.1,1.5,0.6,2.2
c0.3,0.3,0.7,0.4,1.2,0.4c0.3,0,0.6-0.1,0.6-0.1c0.1,0,0.2-0.1,0.2-0.1s0.1-0.1,0.2-0.1c0.2,0.3,0.5,0.5,0.9,0.6
c0.5,0.1,0.9,0.2,1.2,0.2c0.1,0,0.5,0.1,0.7,0.2c0.1,0.1,0.3,0.1,0.4,0.1c0.2,0,0.4-0.1,0.5-0.1c0.3-0.2,0.5-0.5,0.6-0.8
s0.1-0.5,0.1-0.8h0.1c0.5,0,0.9-0.3,1.1-0.7c0.1-0.3,0.3-0.6,0.3-0.7l0.1-0.1c0.1,0.3,0.1,0.6,0.3,0.9c0.1,0.1,0.1,0.1,0.2,0.2
c0,0.6,0.5,1.1,1.1,1.1c0.1,0,1.1,0,1.4,0c0.1,0,0.3,0,0.4,0c0,0.1,0,0.1,0,0.2c-0.5,0.9-0.2,1.9,0.4,2.3c0.1,0.1,0.3,0.2,1.1,0.3
c0.1,0,0.1,0,0.2,0c0.3,0,0.6-0.1,0.8-0.3s0.4-0.5,0.3-0.9c0.1,0,0.2,0,0.4-0.1c-0.1,0.2-0.1,0.4,0,0.6c-0.4,0.5-0.5,1.2-0.5,1.6
c-0.3,0.3-0.5,0.7-0.5,1.4c-0.2,0.2-0.4,0.4-0.5,0.6c-0.1,0.3-0.1,0.7,0,1.1c0.3,0.7,0.9,0.8,1.3,0.9c0.1,0,0.2,0,0.3,0.1l0,0
c0.1,0.4,0.3,0.7,0.5,0.9c-0.1,0.3-0.1,0.7,0.1,1.1c0,0.1,0.1,0.1,0.1,0.3c-0.4,0.5-0.6,0.9-0.6,1.2l0,0c-0.3,0.1-0.7,0.4-0.8,1
c-0.1,0.1-0.2,0.2-0.3,0.4c-0.2,0-0.4,0.1-0.5,0.1c-0.2-0.2-0.5-0.3-0.7-0.4c0-0.1,0-0.1,0-0.1c-0.1-0.7-0.6-1.2-1.4-1.2
c-0.3,0-0.5,0.1-0.8,0.1c-0.1,0-0.1,0-0.2,0.1c-0.8,0.2-1,0.7-1.1,1s0,0.7,0.1,0.9h-0.1c-0.6,0.3-1.2,1.4-1.1,2.2l0,0
c0,0.1,0.1,0.2,0.1,0.3c-0.1,0.1-0.2,0.1-0.3,0.2c-0.3,0.2-0.4,0.5-0.4,0.8s0.1,0.6,0.3,0.9l0.9,0.9
C242.3,604.1,242.2,604.1,242.2,604.1z`
  },
  {
    id: "BinhDinh",
    name: "Bình Định",
    d: `M333.3,493c-0.4,0-0.8-0.1-0.9-0.1c-0.5-0.3-1.2-0.7-1.4-0.7c-0.1-0.1-0.3-0.1-0.5-0.1
s-0.7,0-1.2,0.1c-0.1-0.2-0.1-0.3-0.2-0.4l-0.4-1.5v-0.1l-0.8-2.3l-0.1-0.6c0.2-0.1,0.3-0.3,0.5-0.5c0.3-0.5,0.8-1.8,0.3-2.8
l-0.1-0.2c-0.2-0.4-0.2-0.5-0.2-0.8c0-0.9-0.4-1.6-1.2-2c0-0.6-0.1-1.3-0.9-2c-0.2-0.1-0.4-0.4-0.5-0.6l-0.1-0.4
c-0.1-0.4-0.3-0.7-0.7-0.9l0,0v-0.3l0.3-0.6c0.1-0.1,0.1-0.3,0.1-0.5c0-0.8,0.1-1.9,0-2.3c0-0.2-0.1-1-0.1-1.6c0-0.5-0.3-0.9-0.7-1
l-0.7-0.3l-0.3-1.2c-0.1-0.2-0.2-0.4-0.3-0.5l-1-0.9l-0.3-0.9c0.3-0.6,0.5-1.5,0.2-2.4c-0.1-0.3-0.5-1.6-0.7-2.6
c-0.3-1.1-0.5-1.8-0.6-2.1c-0.1-0.2-0.2-0.7-0.3-1.2c-0.2-0.7-0.3-1.1-0.4-1.3c-0.1-0.2-0.2-0.7-0.5-1.5c0.5-0.1,0.8-0.4,0.9-0.6
c0.2-0.3,0.4-0.8,0.1-1.6c-0.3-0.9-0.4-1.8-0.5-1.9v-0.3c0.3-0.2,0.5-0.5,0.5-0.9v-0.1l1.2-0.5c0.1-0.1,0.3-0.2,0.4-0.3
c0.2,0.2,0.5,0.3,0.8,0.3c0.1,0,0.1,0,0.2,0l1.4-0.3c0.4-0.1,0.7-0.4,0.9-0.8c0.1-0.3,0.4-1.4,0.2-2.4c0.1,0,0.1,0,0.2,0
c0.8,0,1.5-0.4,1.9-0.6c0.3,0,0.7-0.1,1-0.1c0.1,0,0.2,0,0.3,0c0.2,0.1,0.4,0.1,0.6,0.1c0.5,0,1-0.2,1.6-0.5
c0.3,0.2,0.5,0.3,0.9,0.4c0.1,0,0.2,0.1,0.3,0.2c0.3,0.2,0.8,0.5,1.4,0.5h0.1c0.1,0.1,0.4,0.2,0.5,0.3l0.5,0.6l0.1,0.9
c0,0.3,0.2,0.7,0.5,0.9l0.3,0.2l-0.1,0.1c-0.1,0.3-0.2,0.7,0,1l0.2,0.5c0.2,0.4,0.5,0.7,1,0.7l0.7,1.9c0,0.1,0.1,0.2,0.1,0.3
l0.6,0.8l0.1,0.2l-0.1,2.1c0,0.1,0,0.1,0,0.2l0.1,0.6c0,0.1,0,0.1,0,0.1l0.2,0.7c0,0.1,0,0.1,0.1,0.2l0.5,1.2
c0.1,0.1,0.1,0.2,0.2,0.3l1.2,1.4l0.2,0.3l0.1,0.3l0.2,1.3l-0.2,0.3c-0.2,0.3-0.2,0.7-0.1,1.1l0.2,0.5c-0.2,0-0.3,0.1-0.5,0.2
c-0.3,0.2-0.4,0.5-0.5,0.8l-0.1,0.6c0,0.3,0,0.5,0.1,0.7l0.5,0.9c0.1,0.2,0.3,0.3,0.4,0.4l1,0.6l0.2,0.3l1,2.7l0.5,4.6v0.1
c-0.1,0.1-0.2,0.1-0.3,0.2l-0.5,0.5c-0.1,0.1-0.3,0.3-0.3,0.5l-0.1,0.4c0,0.1,0,0.1,0,0.2l-0.1,1.4l-0.3,0.6
c-0.1,0.1-0.1,0.2-0.1,0.3l-0.1,0.3c-0.1,0.4,0.1,0.8,0.4,1.1c0.1,0.1,0.3,0.2,0.4,0.2c0,0.1,0.1,0.1,0.1,0.2l-0.1,0.1l-0.4,0.5
c-0.1,0.2-0.2,0.4-0.2,0.6l-0.1,0.7h-0.1c-0.2,0-0.4,0.1-0.6,0.1l-1.7,0.9c-0.3,0.2-0.5,0.5-0.6,0.9c0,0.1,0,0.2,0,0.5
c0,0,0,0-0.1,0c-0.5,0-1,0.3-1.2,0.7c-0.2,0.3-0.2,0.7-0.1,1c-0.1,0.1-0.1,0.2-0.1,0.3s-0.2,0.4-0.3,0.7c-0.1,0.1-0.1,0.1-0.1,0.1
h-1c-0.4,0-0.7,0.2-1,0.5l-0.9,1.5C333.8,492.8,333.5,493,333.3,493C333.4,493,333.4,493,333.3,493z`
  },
  {
    id: "BenTre",
    name: "Bến Tre",
    d: `M236.7,649.1l-0.7-0.5l-2.4-2.6c-0.1-0.1-0.1-0.1-0.1-0.1l-1-0.8l-0.1-0.1l-3.3-2l-10-10.6
c-0.1-0.1-0.1-0.1-0.1-0.1l-0.3-0.3c-0.1,0-0.1-0.1-0.1-0.1l-0.3-0.1c0.7-0.1,1.7-0.3,2.4-0.6c0.4-0.2,1.2-0.3,1.6-0.3
c0.1,0,0.1,0,0.1,0c0.2,0,0.4,0,0.6,0s0.4,0,0.6,0c-0.1,0.1-0.2,0.1-0.2,0.3c-0.3,0.4-0.3,1,0,1.4l0.2,0.3c0.1,0.2,0.3,0.3,0.5,0.4
l0.2,0.1l0,0l1,0.8l0.2,0.3l0.3,1.3c0,0.1,0.1,0.2,0.1,0.3l0.4,0.9c0,0.1,0.1,0.1,0.1,0.2l3.8,5.1c0,0.1,0.1,0.1,0.1,0.1l0.9,0.9
c0.1,0.1,0.2,0.1,0.3,0.2l3,1.5l2.1,1.6c0.1,0,0.1,0.1,0.2,0.1l1.5,0.8c-0.1,0.1-0.1,0.2-0.1,0.4l-0.1,1.2l-0.4,0.5l-0.4,0.1
L236.7,649.1z M238,642.6L238,642.6c0-0.3,0.1-0.5,0.2-0.7l0.1-0.2c0,0,0,0,0.1-0.1c-0.1-0.2-0.1-0.4,0-0.6l0.1-0.4
c0-0.1,0.1-0.3,0.2-0.4l0.2-0.3c0-0.1,0.1-0.1,0.1-0.1l0.4-0.5l0.1-0.5c0.1-0.5,0.6-0.9,1.1-0.9h0.3c0.4,0,0.8,0.3,1,0.6l0.1,0.3
l1.4-1.5l0.1-0.3v-0.3l-0.4-0.1c-0.1,0-0.3-0.1-0.4-0.2l-0.3-0.2c-0.1,0-0.1-0.1-0.1-0.1l-0.1-0.1c0,0-0.1,0-0.1-0.1l-0.3-0.1
c-0.1,0-0.1-0.1-0.2-0.1l-0.5-0.5l0,0l-9.6-3.4c-0.1,0-0.2-0.1-0.2-0.1l-1.6-1l-0.3-0.1c-0.3-0.1-1.2-0.3-2-0.7c0,0-0.1,0-0.3,0
c-0.4,0-1,0.1-1.7,0.4c-0.2,0.1-0.4,0.1-0.7,0.2l1.5,0.5c0.2,0.1,0.3,0.1,0.5,0.3l1.2,1.2c0.1,0.1,0.1,0.1,0.2,0.2l3.3,5.5l0.9,1.2
l1,0.8l1.9,0.8c0.1,0,0.2,0.1,0.3,0.1l0.6,0.5l0.1,0.1l0.5,0.5l0.4,0.3l0.3,0.1L238,642.6z`
  },
  {
    id: "BacNinh",
    name: "Bắc Ninh",
    d: `M219.5,178.1c-0.3-0.2-0.5-0.3-0.7-0.4c-0.1,0-0.2-0.1-0.3-0.1c-0.3-0.2-0.6-0.3-0.9-0.3
c-0.1,0-0.3,0-0.4,0.1c-0.3-0.3-0.6-0.4-1.1-0.4c-0.3,0-0.6,0.1-0.8,0.1c-0.1,0-0.1,0-0.2,0.1H215h-0.1c0,0-0.1,0-0.1-0.1
c-0.3-0.1-0.6-0.2-0.9-0.2h-0.1c0.1-0.3,0.1-0.9-0.2-1.4c0-0.1-0.1-0.1-0.1-0.3c-0.1-0.1-0.2-0.3-0.3-0.5c-0.3-0.5-0.7-0.7-1.3-0.7
c-0.2,0-0.5,0.1-0.7,0.1c-0.2-0.3-0.5-0.5-0.8-0.7c-0.1-0.4-0.3-0.6-0.6-0.9c0-0.1,0-0.1,0.1-0.2c0.1-0.3,0.2-0.7,0-1.2V171
c0.2-0.7-0.1-1.2-0.3-1.4c0-0.1,0.1-0.1,0.1-0.1l0.1,0.1c0.4,0.1,0.8,0.3,1.2,0.3c0.6,0,0.9-0.3,1.1-0.5l0.2-0.1
c0.1,0.5,0.4,0.9,0.4,0.9c0.2,0.3,0.7,0.6,1.2,0.6c0.4,0,0.7-0.2,1-0.5l0.1-0.1c0.2,0.2,0.5,0.4,0.9,0.5c0.1,0,0.1,0,0.1,0.1h0.1
c0.2,0.1,0.5,0.2,0.9,0.2c0.3,0,0.5-0.1,0.7-0.2c0.5,0.8,1.1,1.2,1.7,1.2c0.1,0,0.2,0,0.3-0.1c0.2,0.4,0.7,0.7,1.2,0.7
c0.2,0,0.5-0.1,0.7-0.1c0.1,0.1,0.1,0.1,0.1,0.2c0.1,0.3,0.4,1,1.1,1.2c0,0.3,0.1,0.6,0.3,0.9c0,0.1,0,0.2,0.1,0.4
c-0.1,0.1-0.1,0.1-0.1,0.1c-0.3,0.3-0.5,0.8-0.6,1.3l-0.1,0.1c-0.2,0.2-0.4,0.4-0.5,0.6c-0.1,0-0.2,0-0.3,0c-0.4,0-0.7,0.1-0.9,0.2
l0,0c-0.3,0-0.5,0.2-0.7,0.3C219.8,178,219.6,178,219.5,178.1z`
  },
  {
    id: "BacGiang",
    name: "Bắc Giang",
    d: `M241.9,170.4C241.9,170.4,241.8,170.4,241.9,170.4l-1.8-0.4H240c-0.3,0-0.6-0.1-0.7-0.1
c-0.2-0.1-0.5-0.2-0.9-0.2c-0.3,0-0.8,0.1-1.2,0.3l0,0c-0.1-0.1-0.3-0.1-0.4-0.2c-0.2-0.3-0.6-0.8-1.5-0.8c-0.2,0-0.4,0-0.6,0
c0-0.1-0.1-0.2-0.1-0.3s-0.1-0.2-0.1-0.3c-0.1-0.3-0.5-0.9-1.5-0.9c-0.2,0-0.3,0-0.5,0c-0.1,0-0.1,0-0.2,0c-0.3-0.1-0.5-0.2-0.8-0.2
c-0.2,0-0.4,0-0.5,0.1c-0.1,0-0.2,0-0.2,0h-0.1c-0.1,0-0.2,0-0.3,0s-0.3,0-0.7-0.1c-0.1,0-0.2,0-0.3,0c-0.8,0-1.4,0.5-1.7,1.4h-0.1
c-0.7,0-1.2,0.3-1.5,0.8c-0.3-0.4-0.7-0.7-1.1-0.7c-0.4,0-0.7,0.2-1,0.5s-0.5,0.7-0.5,1.1c-0.2-0.4-0.7-0.7-1.2-0.7
c-0.2,0-0.4,0.1-0.6,0.1c-0.4-0.3-0.9-0.4-1.2-0.5l0,0c-0.1,0-0.3,0-0.4,0c-0.2,0-0.4,0-0.5,0.1c-0.1-0.1-0.1-0.1-0.1-0.2
c-0.1-0.1-0.1-0.2-0.2-0.3c0-0.1-0.4-0.5-0.7-0.7c-0.3-0.3-0.7-0.4-1-0.4c-0.4,0-0.8,0.2-1.1,0.5c-0.4-0.3-0.9-0.5-1.3-0.6
c-0.1,0-0.1-0.1-0.3-0.1c-0.1-0.1-0.3-0.1-0.4-0.2c-0.1-0.3-0.4-0.7-1-0.9c0,0-0.1,0-0.1-0.1c-0.2-0.1-0.4-0.1-0.7-0.3
c-0.3-0.1-0.5-0.2-0.8-0.2s-1,0.1-1.4,0.9c0,0.1-0.1,0.1-0.1,0.1c-0.1-0.2-0.2-0.5-0.4-0.7c0.1-0.2,0.2-0.6,0.1-1.1
c-0.1-0.2-0.3-0.8-1.2-0.9c0-0.1-0.1-0.2-0.1-0.3c0.1-0.1,0.1-0.2,0.2-0.3l0.5,0.1h0.1c0.3,0,0.5-0.1,0.8-0.3
c0.2-0.2,0.4-0.5,0.4-0.9c0-0.3,0-0.6,0.1-0.7c0.4,0.2,1.2,0.6,1.4,0.7c0.1,0.1,0.3,0.1,0.5,0.1c0.3,0,0.7-0.1,0.9-0.4
c0,0,0.6-0.7,1-1.3c0.5-0.7,0.6-1.6,0.6-1.8c0-0.3,0-0.5-0.2-0.7c-0.1-0.1-0.2-0.4-0.2-0.5v-1.2l0.4-1.1c0.1-0.4,0-0.8-0.2-1.1
c0.2-0.2,0.3-0.4,0.3-0.5c0,0,0-0.1,0.1-0.1c0.3-0.1,0.5-0.4,0.7-0.6c0.3,0.1,0.7,0.2,1.1,0.2c0.2,0,0.4,0,0.6-0.1
c0,0.1,0,0.2,0,0.4c0,0.4,0.1,2.9,0.1,3c0,0.4,0.3,0.8,0.7,1c0.6,0.3,1.7,0.7,2.4,0.7c0.1,0,0.5,0.1,0.7,0.2
c0.2,0.1,0.3,0.1,0.5,0.1h0.1h0.3c0,0.3,0.1,0.8,0.4,1.2c0.3,0.3,0.7,0.5,1.2,0.5c0.1,0,0.1,0,0.1,0c0,0.3,0.1,0.6,0.3,0.9
c0.3,0.4,1.1,1.2,2,1.2h1.4c0.4,0,1.5-0.3,1.9-1c0.1-0.1,0.1-0.3,0.1-0.4c0.1,0,0.3,0.1,0.5,0.1c1.1,0,1.6-0.9,1.9-1.3
c0,0,0-0.1,0.1-0.1c0.2-0.2,0.5-0.8,0.7-1l1.1-0.8c0.5-0.4,0.6-1.1,0.2-1.6l-0.3-0.4c0.2-0.3,0.4-0.7,0.5-0.9
c0.1-0.3,0.4-0.8,0.6-1.1c0.1,0,0.3,0,0.4,0c0.3,0,0.7,0,1.1-0.1c0.1,0,0.4,0.1,0.6,0.1c0.3,0.1,0.7,0.1,1,0.1s0.5-0.1,0.8-0.2
l0.3,0.1c-0.1,0.5,0.2,1,0.7,1.3l1.6,0.7c0.2,0.1,0.6,0.3,1.1,0.3c0.7,0,1.2-0.5,1.3-1.1c0.3,0.1,0.7,0.2,1.2,0.2c0.1,0,0.3,0,0.4,0
l0,0c-0.3,0.4-0.4,0.9-0.3,1.4c0.1,0.3,0.4,0.9,1.1,1.2c0.2,0.1,0.5,0.3,0.7,0.4l0.1,0.6c0,0.2,0.1,0.4,0.2,0.5l0.6,0.9
c0.2,0.3,0.6,0.5,0.9,0.5c0.1,0,0.3,0,0.5-0.1c0,0,0.1,0,0.2,0c0.5,0.3,1,0.5,1.5,0.5h0.1c0,0.1-0.1,0.2-0.1,0.3v1.4
c0,0.4,0.2,0.7,0.5,0.9c0,0,0.3,0.2,0.7,0.5c-0.7,0.1-1.6,0.3-1.6,1.4c0,0,0,0.1,0,0.2c-0.1,0.5-0.1,1.2,0.2,1.8
c0,0.1-0.1,0.1-0.1,0.3c-0.1,0.2-0.4,0.7-0.8,1.2c-0.2-0.1-0.5-0.2-0.8-0.2c-0.5,0-1.2,0.1-1.5,0.2l-2-0.3c-0.1,0-0.1,0-0.1,0
c-0.3,0-0.5,0.1-0.7,0.3c-0.3,0.2-0.4,0.5-0.4,0.8v0.4l-1.2,0.2L241.9,170.4z`
  },
  {
    id: "BacCan",
    name: "Bắc Cạn",
    d: `M203.8,141.8c0.2-0.5,0.2-1.1-0.1-1.8c0-0.1,0-0.3,0-0.4c0.3-0.7,0.4-1.7-0.2-2.3
c-0.1-0.2-0.3-0.9-0.3-1.2c0-1.1-0.8-1.7-1.6-1.7c-0.1,0-0.2,0-0.3,0s-0.6-0.2-1.2-0.7c-0.5-0.4-0.9-0.6-1.3-0.6
c-0.5,0-1,0.3-1.3,0.7c-0.3,0.1-0.5,0.1-0.6,0.1c-0.5,0-0.9,0.2-1.2,0.3c-0.1,0.1-0.3,0.1-0.3,0.1c-1.1,0.2-1.4,1.4-1.4,2.2l0,0
c-0.3-0.4-0.6-0.6-0.8-0.7l-0.1-0.1c-0.1-0.2-0.3-0.5-0.4-0.7l-0.3-1.2c0-0.1-0.1-0.2-0.1-0.2l-0.5-0.9l0.1-0.4
c0.1-0.3,0.1-0.6,0-0.8l-0.1-0.3l0.7-1.3c0.2-0.3,0.2-0.7,0.1-1c-0.1-0.3-0.5-0.6-0.8-0.7h-0.1c0-0.3-0.2-0.5-0.4-0.7
s-0.6-0.5-0.7-0.8c-0.1-0.1-0.1-0.1,0-0.3c0-0.1,0-0.2,0-0.2v-0.1c0.7-0.3,0.9-0.7,1.1-0.9c0.1-0.1,0.4-0.4,0.6-0.6
c0.4-0.3,0.5-0.8,0.3-1.3c-0.1-0.1-0.1-0.3-0.1-0.4c0.3-0.3,0.6-0.6,0.7-0.7c0.2-0.2,0.4-0.5,0.4-0.8l0.1-1.2h0.4
c0.6,0,1.2-0.5,1.2-1.2s0.1-1.5,0.2-1.9c1.1-0.4,1.5-1.3,1.5-2.1c0,0-0.1-3.3-1.6-3.9c-0.1,0-0.1-0.1-0.2-0.2l-0.1-0.5
c0-0.3-0.1-0.5-0.3-0.7l-0.1-0.1c0.1-0.2,0.2-0.5,0.2-0.7c0.3-0.1,0.5-0.2,0.7-0.3c0.1,0,0.1-0.1,0.1-0.1c0.6-0.1,1.2-0.4,1.5-1
c0.5,0,1.4-0.2,1.8-0.8c0.1,0,0.3-0.1,0.5-0.1c0.8,0,1.2-0.1,1.5-0.2c0,0.2,0.1,0.4,0.2,0.6c0,0.1,0.1,0.1,0.1,0.2
c-0.4,0.4-0.7,0.8-0.9,0.9c-0.2,0.2-0.4,0.5-0.4,0.8s0.1,0.6,0.3,0.8c0.1,0.1,0.3,0.3,0.6,0.5v0.1c0,0.9,0.3,1.6,0.8,1.8
c0,0.4,0.3,0.9,0.9,1.2c0.3,0.5,0.6,1,0.7,1c0.2,0.2,0.4,0.3,0.6,0.4c0.1,0,0.9,0.2,1.5,0.2c0.2,0,0.7,0,1,0.1
c0.4,0.2,1.2,0.8,1.3,0.8c0.2,0.1,0.4,0.2,0.6,0.2c0.3,0,0.7-0.1,0.9-0.5l0.7-0.9c0.4-0.1,0.9-0.2,1.2-0.3c0.3-0.1,1-0.5,2.2-1.1
l1.2-0.6h0.1c0.1,0.2,0.1,0.4,0.1,0.5c0.1,0.5,0.5,1.2,0.6,1.4c0.1,0.3,0.4,0.5,0.8,0.6l0,0c-0.1,0.3-0.5,0.7-0.6,0.8
c-0.3,0.3-0.4,0.7-0.3,1.1l0.5,2c0.1,0.5,0.6,0.9,1.1,0.9h0.9c0.2,0,0.3,0,0.5-0.1l0.4-0.2c0,0.1,0,0.1,0,0.1c0,0.3,0.1,0.6,0.4,0.9
l0.5,0.5c0,0.1-0.1,0.3-0.1,0.4c-0.4,1,0,2,0.5,2.6c0.2,0.2,0.3,0.3,0.5,0.5c0.1,0.1,0.3,0.2,0.4,0.3c0,0.3-0.2,0.6-0.3,0.8
c-0.7,0.7-0.5,1.6-0.5,2.1c0,0.1,0,0.2,0,0.3c-0.1,0-0.1,0.1-0.2,0.1c-0.1,0.1-0.2,0.2-0.3,0.3c-0.7,0.5-1.4,2-1.6,2.4
c-0.1,0.1-0.3,0.2-0.9,0.2c-0.7,0-1.6,0.1-2.1,0.8c-0.2,0.3-0.3,0.6-0.2,0.9v1.5c0,0.2,0.1,0.4,0.2,0.6c0,0,0.2,0.4,0.4,0.7
c0,0.2,0,0.8,0,1.4c-0.1,0-0.2,0-0.3,0l-0.1-0.1c-0.2-0.3-0.5-0.5-0.8-0.5l-0.9-0.1c-0.1,0-0.1,0-0.2,0c-0.5,0-1,0.4-1.1,0.9
c0,0.1,0,0.1-0.1,0.2c-0.1,0-0.3-0.1-0.4-0.1c-0.2-0.1-0.4-0.1-0.6-0.1c-1.2,0-1.5,1.2-1.6,1.8c-0.1,0.1-0.3,0.1-0.4,0.1
c-1.3,0-2.1,1.2-2.4,1.8l0,0c0,0-0.2,0.1-0.6,0.2c-0.1,0-0.3,0.1-0.4,0.1h-0.1c0,0-0.1,0-0.2,0c-0.1,0-0.3-0.1-0.4-0.1
C204.4,141.5,204.1,141.6,203.8,141.8z`
  },
  {
    id: "BacLieu",
    name: "Bạc Liêu",
    d: `M189,682.2c-0.1-0.2-0.3-0.6-0.7-0.7c-0.3-0.2-0.7-0.4-1.2-0.4c-0.2,0-0.4,0-0.5,0.1l0,0
c-0.2-0.3-0.6-0.5-1.3-0.5c-0.1,0-0.3,0-0.4,0c-0.3-0.1-0.6-0.3-0.7-0.4c-0.3-0.2-0.7-0.3-1.1-0.3c-0.1,0-0.2,0-0.3,0l0,0l-0.1-0.1
c0-0.1,0-0.1,0-0.1c0.1-0.1,0.2-0.4,0.3-0.6l0.4-0.2c0.5-0.2,0.7-0.7,0.6-1.2l-0.1-0.9c-0.1-0.3-0.2-0.6-0.5-0.8
c-0.2-0.1-0.4-0.2-0.6-0.2l0,0c-0.1-0.1-0.1-0.3-0.3-0.4c0.1-0.2,0.3-0.5,0.5-0.7l0.9-0.8c0.5-0.4,0.6-1.1,0.2-1.6
c0,0-0.8-1-1.3-1.6l-0.1-0.1c0.3-0.3,0.9-0.9,1.4-1.4c0.3-0.2,0.4-0.6,0.3-0.9s-0.2-0.7-0.5-0.9l-0.1-0.1c0.3-0.2,0.5-0.5,0.5-0.9
v-1.4V665c0-0.1,0-0.2,0-0.4c0.3-0.1,0.6-0.3,0.8-0.7c0.3-0.4,0.4-0.8,0.4-1.2c0-0.2,0-0.5-0.1-0.7c0-0.2,0-0.3,0-0.4
c0-0.2,0-0.4-0.1-0.6c0.1,0,0.3,0.1,0.4,0.1c0.1,0,0.2,0,0.3,0s0.3,0,0.4,0c0.7,0,1.1-0.2,1.4-0.5c0.3,0.1,0.5,0.1,0.6,0.1
c0.3,0.3,0.7,0.4,1.1,0.4c0.2,0,0.4-0.1,0.6-0.1c0.3,0.2,0.7,0.3,1,0.3s0.5-0.1,0.8-0.1c0.1,0,0.2-0.1,0.4-0.1l0.1,0.1l-0.6,4.4
c0,0.3,0.1,0.6,0.3,0.9l1.4,1.7c0.2,0.3,0.5,0.4,0.9,0.4c0,0,0,0,0.1,0c0.2,0,0.4-0.1,0.6-0.2l0.2,0.1c0.2,0.1,0.4,0.2,0.6,0.2
c0.1,0.2,0.3,0.4,0.5,0.5c0.9,0.5,1.4,0.6,1.6,0.6c0.1,0,0.1,0,0.2,0.1c0.3,0.1,0.6,0.2,1.1,0.2c0.1,0,0.2,0,0.3,0
c0.7,0,1.2-0.4,1.3-0.6c0,0,0,0,0-0.1c0.3-0.1,0.5-0.2,0.8-0.4l0.1-0.1c0.1,0.2,0.3,0.4,0.5,0.5s0.5,0.3,0.6,0.4
c-0.1,0.3-0.2,0.5-0.1,0.9l0.5,1.8c-0.3,0.3-0.4,0.8-0.2,1.2l0.2,0.6l-1.3,0.5l-8.9,3.5c-0.1,0.1-0.2,0.1-0.3,0.2l-1,0.9l-0.1,0.1
l-1.7,1.9C190,681.4,189,682.2,189,682.2C189,682.3,189,682.3,189,682.2z`
  },
  {
    id: "BaRiaVungTau",
    name: "Bà Rịa - Vũng Tàu",
    d: `M262.9,625.1c0.1-0.1,0.1-0.3,0.1-0.5v-0.4c0-0.2-0.1-0.3-0.1-0.5l-0.2-0.4
c-0.1-0.1-0.1-0.1-0.1-0.2l-0.3-0.3c-0.1-0.1-0.1-0.1-0.2-0.2l-0.3-0.2l-0.7-0.8c-0.2-0.3-0.5-0.4-0.9-0.4c-0.1,0-0.2,0-0.3,0h-0.1
h-0.2h-0.2l-0.2-0.1c-0.1-0.1-0.3-0.1-0.5-0.1s-0.3,0-0.4,0.1l-0.7-0.3c-0.1-0.1-0.3-0.1-0.4-0.1l-0.7-0.1c-0.1-0.4-0.5-0.7-0.9-0.7
c0,0,0,0-0.1,0l-0.1-3.1c0-0.1,0-0.3-0.1-0.4l-0.1-0.3c0,0,0-0.1-0.1-0.1c0.1,0,0.1,0,0.2,0l0.9-0.2c0.2,0,0.3-0.1,0.5-0.2
c1.3-1,1.5-1.3,1.6-1.4c0.4-0.6,0.5-1.8,0.5-2.1c0-0.1,0.1-0.2,0.1-0.2c0.1-0.1,0.2-0.2,0.4-0.3c0.1-0.1,0.2-0.1,0.3-0.2
c0.3,0.4,0.7,0.6,1.2,0.6c0.3,0,0.6-0.1,0.9-0.2l1.4,0.1l0.1,0.7c0,0.2-0.1,0.6-0.1,0.9c-0.2,0.5-0.1,1,0.2,1.4
c0.3,0.4,0.8,0.7,1.4,0.7c0.4,0,0.8-0.1,1.1-0.3c0.1,0,0.2,0.1,0.4,0.1c0.1,0,0.2,0,0.4-0.1c0.4-0.1,0.7-0.5,0.8-0.9
c0-0.1,0.1-0.8,0-1.5c0-0.1,0.1-0.3,0.2-0.3c0.1-0.1,0.8-1.1,1.1-1.6c0.1,0,0.3-0.1,0.4-0.1c0.2,0,0.5-0.1,0.6-0.3
c0.1-0.1,0.3-0.3,0.5-0.5c0.1,0.1,0.2,0.2,0.3,0.3c0,0,0,0.1,0.1,0.1c0.1,0.1,0.2,0.5,0.5,0.7c0,0.5,0.3,1,0.8,1.2l0,0
c0,0,0.3,0.1,0.7,0.5c0.2,0.2,0.2,0.3,0.2,0.9c0,0.7,0,1.5,0.4,2.1c0.1,0.2,0.7,1.3,1.2,2.2l0.3,0.7l-0.5,0.5
c-0.1,0.1-0.1,0.2-0.2,0.3l-0.2,0.4l-0.2,0.3l-0.5,0.3l-2.4,1.1l-0.5,0.1l-1.8,0.3c-0.1,0-0.1,0-0.2,0.1l-1.3,0.4h-0.1
c-0.1,0-0.2-0.1-0.4-0.1c-0.4,0-0.7,0.2-1,0.5l-0.2,0.4l-1.1,1c-0.1,0.1-0.1,0.1-0.2,0.2l-0.6,0.9L262.9,625.1z`
  },
  {
    id: "AnGiang",
    name: "An Giang",
    d: `M176.9,630.9c-0.1-0.1-0.1-0.1-0.2-0.1l-4.2-2.5c-0.1-0.1-0.2-0.1-0.3-0.1l-5.7-1.4l-1.2-1.7
l-1.3-2.7c-0.1-0.1-0.1-0.3-0.3-0.3c0.9-0.1,2.1-0.1,2.4-0.2c0.2,0,0.5-0.1,0.7-0.3c0.7-0.6,1.8-1.7,2-2.7c0.1-0.5,1.2-1.9,1.6-2.3
c0.4-0.3,3-1.8,5.3-3.2c0.4-0.2,0.6-0.6,0.6-1c0-0.7-0.1-2.1-0.8-2.8c-0.3-0.3-0.7-1-0.8-1.3c0-0.2-0.1-0.8-0.9-2.4
c-0.1-0.2-0.1-0.4-0.1-0.6c0.2-0.3,0.4-0.5,0.5-0.7l0.7-0.5c0,0.2,0.1,0.5,0.3,0.6c0.2,0.3,0.5,0.4,0.9,0.4l1.3-0.1l0.3,0.1
c0,0.5,0.1,1.1,0.4,1.6c0.2,0.3,0.3,0.7,0.5,0.9c0.1,0.3,0.3,0.6,0.4,0.7c0.3,0.7,0.9,1.2,1.6,1.4c0,0.1,0.1,0.2,0.1,0.4
c0.1,0.5,0.4,0.9,0.7,1.1c-0.2,0.3-0.2,0.7-0.1,1c0.2,0.8,1,1.4,1.9,1.4c0.3,0,0.6-0.1,0.9-0.1c-0.1,0.8-0.1,2.1-0.1,2.2
c0,0.1,0,0.2,0.1,0.3c0,0,0.5,1.6,0.6,2c0.3,0.8,1.2,1.4,1.8,1.6c0.1,0.1,0.3,0.2,0.4,0.3l0.1,0.1c0.4,0.3,0.9,0.5,1.6,0.5
c0.2,0,0.3,0,0.4,0l1.3,0.5c0.1,0.1,0.3,0.1,0.4,0.1c0.2,0,0.4-0.1,0.6-0.2c0.2,0.1,0.4,0.1,0.5,0.1c0.3,0.1,1,0.8,1.2,1.1
c0.1,0.1,0.1,0.5,0.1,0.8c-0.2,0-0.4,0.1-0.6,0.2c-0.3,0.2-0.5,0.6-0.5,1v0.5l-0.4,0.3c-0.2,0.2-0.3,0.5-0.3,0.8l-0.1,0.8l-1,0.9
c-0.3,0.3-0.5,0.7-0.4,1l-1.5,0.3c-0.3,0.1-0.6,0.3-0.7,0.5s-0.2,0.6-0.1,0.9l0.1,0.2l-2.3,0.8l-0.4-0.6c-0.1-0.2-0.4-0.4-0.6-0.5
l-0.7-0.2c-0.1,0-0.2-0.1-0.4-0.1c-0.3,0-0.6,0.1-0.9,0.4l-0.8,0.9h-0.3c-0.3,0-0.6,0.2-0.8,0.5l-1.2,1.6L176.9,630.9z`
  },
  {
    id: "HoangSa",
    name: "Hoàng Sa",
    d: `M472.8,353.1c-0.2,0.1-0.7,0.1-0.9,0c-0.3,0-2.6-0.7-2.8-0.7c-0.5-0.1-0.4-1,0-1.3
c0.1-0.3,0.6-0.2,0.9-0.3c0.2-0.1,0.6,0,0.8,0c0.4,0.1,2,0.5,2.2,0.6s0.1,0.4,0.1,0.6C473.2,352.1,473.2,352.9,472.8,353.1z
M442,369.1c0,0,0.1-0.7,0-1.1c0-0.2-0.3-0.3-0.4-0.3c-0.2-0.1,0.1-0.2,0.2-0.4c0.3-0.6-0.3-1-0.7-1.3c-0.3-0.1-0.5-1.3-0.5-1.7
c0-0.4-0.4-0.8-0.9-0.7c-0.2,0.1-0.4-0.3-0.5-0.4c-0.2-0.2-0.7-0.1-0.9,0c-0.2,0.1-1,0.4-1.3,0.7c-0.1,0.1-0.5,0.3-0.6,0.4
c-0.2,0.2-1.9,0.7-2.3,0.8c-0.1,0.2-1.3,0-1.4,0.6c-0.1,0.2-0.7,0.6-0.8,1.2c0,0.3,0,0.3,0.3,0.4c0.2,0.1,1,0.7,1.2,0.7
s0.6-0.2,0.7-0.4c0,0.3,0,3.2,0,2.6c-0.1,0.7,1,1,1.4,0.5c0,0.8,1.1,0.8,1.6,0.7c0.5,0,1.7-0.4,2.2-0.4c0.1-0.2,0.9-0.6,1.1-0.6
C440.5,370.2,442.5,369.2,442,369.1z M436.3,379.1c0-0.3-0.3-0.3-0.6-0.4c-1-0.2-2.6,1.1-3,1.3c-0.2,0.2-0.8,0.6-0.9,0.8
c0.9,2.4,3.8-0.2,3.9-0.1c0.5,0,0.7-0.2,0.7-0.6C436.3,379.8,436.3,379.4,436.3,379.1z M438.3,380.7c-0.3,0.3-0.2,1.8,0.2,2.2
c0.2,0.2,1.1,0.3,1.4,0.3c0.5,0.1,2.5,0,2.9-0.3c0.4-0.4,1.1-0.9,1-1.5C443.8,381,438.6,380,438.3,380.7z M485.8,358.1
c-0.1-0.4-0.7-0.7-0.7-0.8c-0.1-0.7-1.2-0.8-1.6-0.7c-0.5,0.2-1.2,1.1-0.9,1.6c0.3,0.5,1,0.9,1.2,1.1s0.5,0.1,0.8,0.1
c0.8,0.5,0.9-0.7,1-0.8C485.8,358.5,485.9,358.3,485.8,358.1z M478.3,365.6c0-0.3-0.4-1.1-0.5-1.2c-0.5,0-0.8-0.3-1.2-0.3
c-0.3,0-0.5,0.3-0.7,0.4c-0.3,0.4-0.8,0.9-0.9,1.1c-0.8,1.7,2.7,3.2,2.8,3c0.1-0.1,0.3-1.1,0.4-1.4
C478.3,366.8,478.2,366.1,478.3,365.6z M477.5,373.7c-0.1-0.1-0.3-1.1-1-1.3c-0.5-0.2-1.6,0.9-1.8,1c-0.2,0.2-0.1,1-0.2,1.4
c0,0.3,1,1.7,1,1.8c0.5,0.3,0.9,0.5,1.5,0.5c0-0.2,0.1-0.5,0.2-0.7c0.3-0.1,0.5-0.3,0.7-0.6C478.2,375.2,478,374.1,477.5,373.7z
M475.4,387.7c-1-1.2-2.3-0.5-2.6-0.2c-0.2,0.1-0.5,0.8-0.6,1.2c-0.1,0.5,0.5,1.2,0.7,1.4c0.2,0.3,0.8,0.4,1.2,0.4
c0-0.1,0.8,0.1,1.1-0.1c0.2-0.2,1.1-1.7,1.1-2.1C476.3,387.9,475.8,387.3,475.4,387.7z M483.4,387.1c0-0.2,0-0.4-0.1-0.5
s-0.4-0.2-0.6-0.1c-0.1,0.2,0.2-0.7,0-0.9c-0.3-0.2-5.5,1.7-5.8,2.1c-0.1,0.3-0.1,1.1,0.1,1.3c0.2,0.2,3,1.3,3.2,1.3
c0.3,0,1.5,0.3,1.2-0.5c0.3,0.1,0.5,0.2,0.8,0.1c0-0.1,0.3-0.9,0.3-1c0.3,0,0.7-0.3,0.7-0.6C483.4,387.9,483.4,387.5,483.4,387.1z`
  },
  {
    id: "TruongSa",
    name: "Trường Sa",
    d: `M508.9,572.8c0.5,0.9-1.6,1.3-2.3,1.2c-0.4,0-1.5,0.1-2.1,0c-0.1,0-1.4-0.5-1.6-0.7
s-1-1.4-0.9-1.8c0-0.3,1.1-1.4,1.3-1.5c0.4-0.1,0.5,0.3,0.6,0.5c-0.1-0.4-0.2-0.9,0.2-1.1c0.4-0.2,0.7-2.3,0.9-1.9
c0-0.5,1.7,0.8,2.2,0.9c0.5,0.1-0.5,2.2-1,2.5c0.3-0.1,2.2-1.3,2.3-0.9C508.7,570.4,509.3,572.5,508.9,572.8z M548.3,576
c-0.2-0.2-0.9-1.1-1.4-1.2c-0.8-0.2-1.6,0.6-1.8,0.7c0,0,0,0-0.1,0c0,0-0.1,0.1-0.1,0.2c-0.3,0.5-0.8,1.5-0.6,1.8s4.2,2.2,4.6,1.9
c0.6-0.4,0.7-1,0.8-1.5C549.8,577.6,548.5,576.2,548.3,576z M559.8,588c0-0.3,0.6-1.4,0.3-1.5c-0.2-0.1-0.5,0-0.6,0.2
c-0.2-1-1.7-0.9-2.1-1c-0.2,0-0.7-0.1-0.9,0c-0.3,0.1-1,0-1.1,0.4c-0.5,0.4-0.5,1.5,0.1,1.6c0.2,0,0.5,0,0.7,0
c-0.1,0.7,2.3,0.8,2.7,0.8c0.2,0,0.9,0.1,1.1-0.1C560.4,588.2,560,588,559.8,588z M522.6,608.4c0,0,0.1-1.3,0-1.8
c0-0.2-0.5-2.1-1.1-2.4c-0.3-0.1-0.6,0-0.8,0.2c0,0.1-0.1,0.2-0.1,0.3c0-0.1,0-0.2,0.1-0.3c0.2-0.6,0.2-1.9,0.2-2.2
c0-0.6-1.5-1.2-1.7-1.3c-0.2-0.2-0.9-0.2-1.1,0c-0.3,0.1-0.4,0.5-0.4,0.8c0.1,0.1-1.8,0.5-2,0.6c-0.3,0.3-0.4,0.8,0,1.1
c-3.2,2.3-1.5,7.4-1.1,6.7c0,1,1.3,0.9,2,0.9c0.6,0,2-0.5,2.6-0.5c0.1-0.3,1.1-0.7,1.4-0.7C520.6,609.2,523.1,608.4,522.6,608.4z
M524.8,622.9c0.1,0.7-0.8,1.3-1.3,1.8c-0.4,0.4-2.9,0.5-3.5,0.4c-0.3,0-1.4-0.1-1.7-0.3c-0.6-0.5-0.3-1.3-0.5-1.4
c-0.4-0.3,1.4-2.4,1.7-2.8 M494.4,650.6c-0.3-0.1-0.3-0.9-0.4-1.4c-0.3,0-1.3-1.4-1.3-1.9c-0.8,0-2.4,0.6-2.7,1.2
c-0.3,0.6-0.6,1.1-0.6,1.7c0,0.4,0.7,1,0.9,1.3c0.2,0.3,1.4,1.1,1.7,1.1c0.3,0.1,1,0.1,1.2-0.1c0.2-0.2,0.6-0.7,0.9-1
C494.4,651.3,494.4,651,494.4,650.6z M574.9,593.6c-0.1-0.8-0.8-0.1-1.2-0.2c0-0.5-0.2-0.8-0.8-0.6s-1.4,1.4-1.1,1.9
c0.3,0.6,1.2,1.1,1.5,1.4c0.2,0.2,1.4-0.8,1.7-0.7c0.1-0.1,0.3,0,0.5-0.1s0.3-0.4,0.2-0.6C575.7,594.1,574.9,593.8,574.9,593.6z
M565.8,602.8c0.5-0.2,0.9,0.4,0.8,0.8c0,0.6,0.1,1.4-0.1,2c-0.1,0.4-0.7,0.6-0.5,0.8c0.1,0.2,0.2,0.8,0.1,0.9
c-0.2,0.3-1.1,0-1.4-0.1c-0.3-0.1-0.6,0.1-0.6,0.4c-0.5,0-3.2-1.5-1.8-1.7c-0.5-0.5,0.2-1.8,0.4-2.2c0.2-0.3,0.7-0.9,1.1-1.4
c0.2-0.2,0.5-0.5,0.9-0.5c0.5,0,0.9,0.3,1.5,0.4c0.2,0.4-0.1,0.9-0.2,1.3 M560,630.3c-0.3,0.2-0.4,0.4-0.3,0.7
c0,0.2-0.3,0.4-0.4,0.7c-0.2,0.6,0.6,1.4,0.8,1.7c0.3,0.4,1,0.5,1.5,0.5c0-0.2,1,0.1,1.3-0.1c0.3-0.2,1.3-2,1.3-2.6
C564.2,630.8,560.4,630,560,630.3z M572.8,631.4c0.1-0.6,0.1-1.1,0.1-1.6c0-0.2,0.1-0.4-0.1-0.6c-0.2-0.2-0.5-1-0.8-1.2
c-0.4-0.3-0.9,0-0.9,0.4c-0.1-0.2-1.2-0.1-1.5,0s-0.9,0.1-1.3,0.4c-0.5,0.3-1,0.7-1.1,0.6c-0.3,0-1.9,0.7-2.2,1.3
c-0.2,0.3-0.1,1.3,0.1,1.6c0.2,0.3,0.5,0.2,0.6,0.4c0.2,0.2,1.5,1.2,2,1.1c0.2,0.1,0.4,0.2,0.6,0.2c0.3,0,0.4-0.1,0.6-0.1
c0.4,0,2.2-0.3,2.5-0.4C571.9,633.4,573.5,632.5,572.8,631.4z`
  },
  // {
  //   id: "ChienDich",
  //   name: "Chiến Dịch",
  //   d: ``
  // },


];

function MapVietNam({ className }) {
  const [tooltip, setTooltip] = useState({ visible: false, name: '', x: 0, y: 0 });

  // const handleMouseOver = (event, provinceName) => {
  //   const { pageX, pageY } = event;
  //   setTooltip({
  //     visible: true,
  //     name: provinceName,
  //     x: pageX,
  //     y: pageY,
  //   });
  // };

  // const handleMouseOut = () => {
  //   setTooltip({ visible: false, name: '', x: 0, y: 0 });
  // };

  const [selectedProvince, setSelectedProvince] = useState(null);
  const navigate = useNavigate();

  const handleProvinceClick = (provinceName) => {
    setSelectedProvince(provinceName);
    navigate(`/ChienDich?province=${provinceName}`);
  };

  return (
    <div className={className}>
      <svg viewBox="0 0 700 800" className={cx('mapVietNam')}>
        {provinces.map((province) => (
          <path
            key={province.id}
            id={province.id}
            d={province.d}
            // fill="#EEEEEE"
            // onMouseOver={(e) => handleMouseOver(e, province.name)}
            // onMouseOut={handleMouseOut}
            className={cx('province', { selected: selectedProvince === province.id })} 
            // fill={province.fill}
            // stroke={province.stroke}
            // stroke-width={province.r}
            onClick={() => handleProvinceClick(province.name)} 
          >
            <title>{province.name}</title>
          </path>
        ))}
        {/* <circle cx="186.7" cy="638.6" r="3" fill="#00F536"/>
        <circle cx="194" cy="169.9" r="3" fill="#00F536"/>
        <circle cx="184" cy="179.9" r="3" fill="#00F536"/>
        <circle cx="124" cy="149.9" r="3" fill="#00F536"/>
        <circle cx="144" cy="159.9" r="3" fill="#00F536"/>
        <circle cx="220.3" cy="614.7" r="3" fill="#00F536"/>
        <circle cx="225.3" cy="584.7" r="3" fill="#00F536"/>
        <circle cx="320.3" cy="514.7" r="3" fill="#00F536"/>
        <circle cx="310.3" cy="494.7" r="3" fill="#00F536"/>
        <circle cx="300.3" cy="454.7" r="3" fill="#00F536"/>
        <circle cx="280.2" cy="388.2" r="3" fill="#00F536"/>
        <circle cx="280.2" cy="388.2" r="3" fill="#00F536"/>
        <circle cx="200.2" cy="308.2" r="3" fill="#00F536"/>
        <circle cx="200.2" cy="308.2" r="3" fill="#00F536"/>
        <circle cx="200.2" cy="288.2" r="3" fill="#00F536"/> */}

      </svg>
      {/* {tooltip.visible && (
        <div
          id="tooltip"
          className={cx('tooltip')}
          style={{ left: tooltip.x + 10, top: tooltip.y + 10 }}
        >
          {tooltip.name}
        </div>
      )} */}
    </div>
  );
}

export default MapVietNam;